import React from 'react';
import './ComingSoon.css';
import { RxCross2 } from "react-icons/rx";
import { FaRegNewspaper } from "react-icons/fa";

const ComingSoon = ({ handleCancel }) => {
    return (
        <div className="coming-soon-container">
            <div className="coming-soon">
                <p><span>Co</span>ming Soon!</p>
            </div>
            <div className="coming-soon-cancel" onClick={handleCancel}>
                <RxCross2 />
            </div>
            <div className="suggestions">
                <div className="chip">
                    <p>Summarize TCS Q4 Results</p>
                </div>
                <div className="chip">
                    <p>ICICI vs HDFC Bank</p>
                </div>
                <div className="chip">
                    <FaRegNewspaper />
                    <p>Top 10 Market News</p>
                </div>
                <div className="chip">
                    <p>Why did XYZ Fall -8% Today ?</p>
                </div>
                <div style={{ paddingLeft: '5px', paddingBlock: '5px' }} className="chip">
                    <img src="https://asset.brandfetch.io/idEql8nEWn/idlV5-ekSw.svg" alt="" />
                    <p>Average Order Value on Zomato</p>
                </div>
                <div className="chip">
                    <p>ELI5 : What is PE Ratio?</p>
                </div>
                <div className="chip">
                    <p>Reliance Industries</p>
                </div>
                <div className="chip">
                    <p>Is the Market Expensive Now ?</p>
                </div>


            </div>
        </div>
    )
}

export default ComingSoon;