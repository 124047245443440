var intervals = ['1D', '5D', '1M', '6M', 'YTD', '1Y', '3Y', '5Y', 'MAX'];



const data1 = [
	{ time: '2018-12-22', value: 32.51 },
	{ time: '2018-12-23', value: 31.11 },
	{ time: '2018-12-24', value: 27.02 },
	{ time: '2018-12-25', value: 27.32 },
	{ time: '2018-12-26', value: 25.17 },
	{ time: '2018-12-27', value: 28.89 },
	{ time: '2018-12-28', value: 25.46 },
	{ time: '2018-12-29', value: 23.92 },
	{ time: '2018-12-30', value: 22.68 },
	{ time: '2018-12-31', value: 22.67 },
];

const data2 = [
	{ time: '2018-12-22', value: 28.15 },
	{ time: '2018-12-23', value: 29.72 },
	{ time: '2018-12-24', value: 24.45 },
	{ time: '2018-12-25', value: 26.68 },
	{ time: '2018-12-26', value: 27.11 },
	{ time: '2018-12-27', value: 25.36 },
	{ time: '2018-12-28', value: 23.84 },
	{ time: '2018-12-29', value: 21.97 },
	{ time: '2018-12-30', value: 20.73 },
	{ time: '2018-12-31', value: 19.62 },
];

const data3 = [
	{ time: '2018-12-22', value: 35.45 },
	{ time: '2018-12-23', value: 32.89 },
	{ time: '2018-12-24', value: 30.34 },
	{ time: '2018-12-25', value: 28.76 },
	{ time: '2018-12-26', value: 26.28 },
	{ time: '2018-12-27', value: 29.93 },
	{ time: '2018-12-28', value: 31.57 },
	{ time: '2018-12-29', value: 33.12 },
	{ time: '2018-12-30', value: 34.69 },
	{ time: '2018-12-31', value: 36.14 },
];

const data4 = [
	{ time: '2018-12-22', value: 22.92 },
	{ time: '2018-12-23', value: 23.48 },
	{ time: '2018-12-24', value: 21.15 },
	{ time: '2018-12-25', value: 20.76 },
	{ time: '2018-12-26', value: 19.28 },
	{ time: '2018-12-27', value: 18.93 },
	{ time: '2018-12-28', value: 20.57 },
	{ time: '2018-12-29', value: 22.12 },
	{ time: '2018-12-30', value: 24.69 },
	{ time: '2018-12-31', value: 26.14 },
];

const patterns = [
	{
		name: 'Promoters',
		color: '#8FF377',
		svg: 'promoter.svg'
	},
	{
		name: 'FII',
		color: '#FF94E6',
		svg: 'FII.svg'
	},
	{
		name: 'DII',
		color: '#29B6F6',
		svg: 'DII.svg'
	},
	{
		name: 'Public',
		color: '#BFC262',
		svg: 'public.svg'
	},
]

export { intervals, data1, data2, data3, data4, patterns };