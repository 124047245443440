import React, { useEffect } from 'react'
import AboutContainer from '../components/AboutPage/AboutContainer';
import AboutFooter from '../components/AboutPage/AboutFooter';
import './../styles/About.css';

const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    return (
        <div className='about-page-container'>
            <AboutContainer />
            <AboutFooter />
        </div>
    )
}

export default About;