export const findPrevClose = (interval, EODData) => {
    if (interval > EODData?.length) {
        return {
            time: EODData[0][0],
            value: Number(EODData[0][5])
        };
    }

    EODData?.sort((a, b) => new Date(a[0]).getTime() - new Date(b[0]).getTime());

    let currentTime = new Date().getTime();
    let intervalDate = currentTime - interval * 24 * 60 * 60 * 1000;
    let low = 0;
    let high = EODData?.length - 1;
    let prev;

    while (low <= high) {
        const mid = Math.floor((low + high) / 2);
        const midDate = new Date(EODData[mid][0]).getTime();

        if (midDate <= intervalDate) {
            prev = {
                time: midDate,
                value: Number(EODData[mid][5])
            };
            low = mid + 1;
        } else {
            high = mid - 1;
        }
    }
    return prev;
}
