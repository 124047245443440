import React from 'react'
import { RxCross2 } from "react-icons/rx";
import { MdDragIndicator } from "react-icons/md";
import { stockActions } from '../../../redux/features/stock/stockSlice';
import { useDispatch } from 'react-redux';

const EditField = ({ value, index, onCheckboxChange }) => {
    const dispatch = useDispatch();
    const handleDelete = () => {
        dispatch(stockActions.deleteSelectedFields(index));
        onCheckboxChange(value);
    };
    return (
        <div className='field-btn-editing'>
            <span><MdDragIndicator color='#7D7D7D' style={{ scale: '1.5', cursor: 'pointer' }} />
                {value.name}</span>
            <RxCross2 style={{ scale: '1.2', fontWeight: 'bold', cursor: 'pointer' }} onClick={handleDelete} />
        </div>
    )
}

export default EditField;