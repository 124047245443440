import { useNavigate } from "react-router-dom";

const MarketrackLogo = (props) => {
    const navigate = useNavigate();

    return (
        <svg style={{ cursor: 'pointer' }} onClick={() => { navigate('/app'); }} width={props.width} height={props.height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 379.5 75.192">
            <g id="Logo_White_Text_Final_W_O_Tagline" data-name="Logo White Text Final W/O Tagline" transform="translate(-41.142 -82)">
                <g id="Underline" transform="translate(45.131 154.747)">
                    <line id="Green" x2="174.126" transform="translate(181.379 0.445)" fill="none" stroke="#6f0" strokeWidth="4" />
                    <line id="Red" x2="173" transform="translate(-0.088 0.253)" fill="none" stroke="#ff2e2e" strokeWidth="4.1" />
                </g>
                <text id="MARKETRACK" transform="translate(41.642 135.952)" fill="#fff" fontSize="46" fontFamily="Cantarell-Bold, Cantarell" fontWeight="700"><tspan x="0" y="0">MARKETRACK</tspan></text>
                <text id="TM" transform="translate(401.042 96)" fill="#fff" fontSize="13" fontFamily="Cantarell-Regular, Cantarell"><tspan x="0" y="0">TM</tspan></text>
            </g>
        </svg>
    );
};

export default MarketrackLogo;