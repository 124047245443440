import React, { useState } from 'react';
import './Forensic.css';
import Radar from './Radar/Radar';
import Checklist from './Checklist/Checklist';
import Disclaimer from './Disclaimer/Disclaimer';
import { BiSolidError } from "react-icons/bi";

const Forensic = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <section id='skeptic' className='skeptic-container'>
            <div className="skeptic-header">
                <img src="/assets/svgs/Forensics/title.svg" alt="" />
                <h1>Skeptic's Radar</h1>
            </div>
            <div className="radar-container">
                <Radar />
            </div>
            <div className="checklist-container">
                <h2>Skeptic's Checklist</h2>
                <div className="checklist-group">
                    <Checklist />
                    <div className='horz-line' />
                    <Checklist />
                    <div className='horz-line' />
                    <Checklist />
                </div>
            </div>
            <div className="disclaimer-container">
                <Disclaimer open={open} handleClose={handleClose} />
                <div className="disclaimer" onClick={handleOpen}>
                    <p><BiSolidError /> Disclaimer</p>
                </div>
            </div>
        </section>
    )
}

export default Forensic;