import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);

export const options = {
    indexAxis: "y",
    aspectRatio: 1,
    elements: {
        bar: {
            borderRadius: 4,
            backgroundColor: "#000",
        },
    },
    responsive: true,
    plugins: {
        title: {
            display: true,
            text: "Bar Scale: 1x",
            font: {
                size: 12,
                weight: 400,
                family: "Nunito-Italic"
            },
            color: "#BBBBBB"
        },
        legend: false,
        tooltip: false,
    },
    scales: {
        x: {
            display: false,
            min: -70,
            max: 100,
        },
        y: {
            border: {
                display: false,
            },
            grid: {
                display: false,
            },
            ticks: {
                font: {
                    size: 15,
                    weight: 500,
                    family: "Raleway-Medium"
                },
                color: "#BABABA",
                padding: 10,
            },
        },
    },
};

const labels = ["Share Price", "EPS", "FCF", "PE Ratio", "OPM"];

const dataset = [1.17, 23.23, 3.55, -3.87, 1]; // Corrected dataset

const colorize = () => {
    return (ctx) => {
        const value = ctx.dataset.data[ctx.dataIndex];
        const color = value < 0 ? "#FF4F4F" : "#6BFF4F";
        return color;
    };
};

const data = {
    labels,
    datasets: [
        {
            barPercentage: 0.8,
            grouped: false,
            label: "Dataset 1",
            data: dataset,
            backgroundColor: colorize(),
        },
    ],
};

export function BarChart() {
    return <>
        <Bar id="bar" options={options} data={data} />
    </>
}
