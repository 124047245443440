import React, { useState } from 'react';
import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    RedditIcon,
    RedditShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from 'react-share';
import { FiCopy } from "react-icons/fi";
import { FaCheck } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";

const SocialShare = ({ handleShareDialogClose }) => {
    const [copyButtonText, setCopyButtonText] = useState('Copy Link');
    const url = window.location.href;

    const handleCopy = () => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(url)
                .then(() => {
                    setCopyButtonText('Copied!');
                    setTimeout(() => {
                        setCopyButtonText('Copy Link');
                    }, 5000);
                })
                .catch(err => {
                    console.error('Failed to copy: ', err);
                    fallbackCopyTextToClipboard(url);
                });
        } else {
            fallbackCopyTextToClipboard(url);
        }
    };

    const fallbackCopyTextToClipboard = (text) => {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        textArea.style.position = 'fixed';
        textArea.style.top = '0';
        textArea.style.left = '0';
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = '0';
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            const successful = document.execCommand('copy');
            const msg = successful ? 'Copied!' : 'Failed to copy';
            setCopyButtonText(msg);
            setTimeout(() => {
                setCopyButtonText('Copy Link');
            }, 5000);
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
            setCopyButtonText('Failed to copy');
        }

        document.body.removeChild(textArea);
    };

    return (
        <div className="social-share-dialog">
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <span>Share</span>
                <img className='social-share-icon' src="/assets/svgs/share.svg" alt="Share icon" />
            </div>
            <div className="social-share-buttons">
                <WhatsappShareButton url={url}>
                    <WhatsappIcon size={60} round />
                    <span className='icon-title'>WhatsApp</span>
                </WhatsappShareButton>
                <TwitterShareButton url={url}>
                    <TwitterIcon size={60} round />
                    <span className='icon-title'>Twitter</span>
                </TwitterShareButton>
                <LinkedinShareButton url={url}>
                    <LinkedinIcon size={60} round />
                    <span className='icon-title'>LinkedIn</span>
                </LinkedinShareButton>
                <RedditShareButton url={url}>
                    <RedditIcon size={60} round />
                    <span className='icon-title'>Reddit</span>
                </RedditShareButton>
                <FacebookShareButton url={url}>
                    <FacebookIcon size={60} round />
                    <span className='icon-title'>Facebook</span>
                </FacebookShareButton>
            </div>
            <div className='social-share-separator'>
                <div className="horz-line" />
                <span>Or</span>
                <div className="horz-line" />
            </div>
            <div className="url-box">
                <input id="share-url" disabled type="text" value={url} aria-label="Share URL" />
                <button style={(copyButtonText === 'Copied!') ? { backgroundColor: '#11bb2c' } : {}} onClick={handleCopy}>{copyButtonText} {(copyButtonText === 'Copied!') ? <FaCheck /> : <FiCopy />}</button>
            </div>
            <button className="share-cancel-button" onClick={handleShareDialogClose}>
                <IoClose style={{ scale: "1.1" }} />
            </button>
        </div>
    );
};

export default SocialShare;