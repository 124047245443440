import React from 'react';
import { IoClose } from "react-icons/io5";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import './Disclaimer.css';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "60%",
    boxShadow: 24,
};

const Disclaimer = ({ open, handleClose }) => {
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
                disableAutoFocus={true}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <div className='disclaimer-popup'>
                            <div className="disclaimer-close">
                                <button onClick={handleClose}><IoClose style={{ scale: "1.4" }} /></button>
                            </div>
                            <div className='disclaimer-content'>
                                <h1>Disclaimer for Skeptic’s Radar</h1>
                                <div className='disclaimer-desc'>
                                    <p style={{ fontWeight: "500" }}>The Skeptic's Radar section is designed to provide general information and analytical insights based on publicly available data and our proprietary algorithms and models. The information contained herein is not exhaustive and may not cover all potential risks or opportunities.</p>
                                    <p><em>Please note the following:</em></p>
                                    <ul>
                                        <li><p><u>Not Financial Advice:</u> The information provided in this section is not intended as financial, investment, or professional advice. Always consult with a qualified financial advisor before making any investment decisions.</p></li>
                                        <li><p><u>Data Accuracy:</u> While we strive to ensure the accuracy and reliability of the data and analyses presented, we cannot guarantee that all information is complete, accurate, or up-to-date. The Skeptic's Radar aims to provide valuable insights; however, it is essential to consider the inherent volatility and limitations of real-time data analysis and the fast-paced nature of financial markets. In the financial markets, data, conditions, and company-specific circumstances can change extremely rapidly, often within milliseconds or seconds. This rapid pace can significantly impact the relevance and applicability of the information presented in the Skeptic's Radar. As a result, an analysis or insight that is valid at one moment may become outdated or irrelevant almost immediately after being processed. Therefore, we strongly emphasize the importance of using our analyses as one of many tools in your decision-making process and not as the sole basis for any financial action.</p></li>
                                        <li><p><u>Algorithmic Analysis:</u> The checks and analyses performed are based on our proprietary algorithms and models. These algorithms are designed to highlight potential areas of concern but are not infallible and may not capture all risks.</p></li>
                                        <li><p><u>No Guarantees:</u> The presence of potential risks or concerns identified by the Skeptic's Radar does not necessarily indicate poor investment quality, nor does a clean report guarantee investment success.</p></li>
                                        <li><p><u>Limitations of the Radar:</u> The Skeptic's Radar is a tool to assist in identifying potential risks and should not be used as the sole basis for investment decisions. Additional research and due diligence are recommended.</p></li>
                                        <li><p><u>Liability:</u> Marketrack assumes no responsibility for any losses or damages incurred from the use of the information provided in this section.</p></li>
                                        <li><p><u>Functionality and Performance:</u> The functionality and performance of the Skeptic's Radar may vary based on the availability and quality of data sources. Periodic updates and enhancements may be made to improve its accuracy and usefulness.</p></li>
                                        <li><p><u>Inherent Risks:</u> Users should be aware of the inherent risks in financial markets and invest accordingly, considering their risk tolerance and investment objectives.</p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}

export default Disclaimer;