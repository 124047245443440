import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Lottie from 'lottie-react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';

import './App.css';
import maintenanceLottie from './lotties/SiteUnderConstructionLottie.json';

import LandingPage from './pages/LandingPage';
import Team from './pages/Team';
import ProductPage from './pages/ProductPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Login from './pages/Login';
import Register from './pages/Register';
import Home from './pages/Home';
import About from './pages/About';

import Navbar from './components/Navbar/Navbar';
import Navbar2 from './components/Navbar/Navbar2';
// import ProductNavbar from './components/Navbar/ProductNavbar';
import Marquee from './components/Marquee/Marquee';
import Footer from './components/Footer/Footer';
import { invalidateTL } from './components/Features/FeaturesSection';
import BlogPage from './components/AboutPage/BlogPage';
// import PageRoutes from './routes/PageRoutes';


gsap.registerPlugin(ScrollTrigger);

const App = () => {
  const location = useLocation();
  const path = location.pathname;
  const handleResize = React.useCallback(() => {
    console.log('resize called');
    invalidateTL();
    ScrollTrigger.refresh();
  }, []);

  useEffect(() => {
    if (path === '/') {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [path, handleResize]);

  return (
    <>
      <div style={{ userSelect: "none" }}>
        {path !== '/' && <Marquee />}
        {path === '/' ? <Navbar /> : <Navbar2 />}

        <Routes>
          <Route exact path='/' element={<LandingPage />} />
          <Route path='/team' element={<Team />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/about' element={<About />} />
          <Route path='/about/blog/:id' element={<BlogPage />} />
          <Route exact path='/app' element={<Home />} />
          <Route path='/app/stock/:id' element={<ProductPage />} />
          <Route path='/privacyPolicy' element={<PrivacyPolicy />}></Route>
        </Routes>
        {path !== '/' && <div><Footer /></div>}
      </div>
      <div className="maintenance">
        {(path === '/') && <Lottie
          animationData={JSON.parse(JSON.stringify(maintenanceLottie))}
          autoplay
          loop
        />}
      </div>
      {/* <PageRoutes /> */}
    </>
  );
};

export default App;