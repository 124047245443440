import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authReducer from '../features/auth/authSlice';
import watchlistReducer from '../features/watchlist/watchlistSlice';
import stockReducer from '../features/stock/stockSlice';
import storage from "redux-persist/lib/storage";
import { persistReducer, PERSIST } from "redux-persist";

const persistConfigAuth = {
  key: 'auth',
  storage
};
const persistConfigStock = {
  key: 'stock',
  storage
};
const rootReducer = combineReducers({
  auth: persistReducer(persistConfigAuth, authReducer),
  stock: persistReducer(persistConfigStock, stockReducer),
  watchlist: watchlistReducer
});


const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [PERSIST]
      }
    })
});

export default store;