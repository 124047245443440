import React, { useEffect, useState } from 'react';
import './Radar.css';
import { IoSearch } from "react-icons/io5";

const Radar = () => {
    const [dots1, setDots] = useState([1, 2, 3, 4]);
    const [startRadar, setStartRadar] = useState(false);
    const [showScanButton, setShowScanButton] = useState(true);
    const [showDots, setShowDots] = useState(false);

    const handleScanButtonClick = () => {
        setStartRadar(true);
        setShowScanButton(false);
        setTimeout(() => {
            setStartRadar(false);
            setShowDots(true);
        }, 5000);
    }
    function getRandomTopLeftCoordinatesInQuadrant(rectWidth, rectHeight, n, q, minDistance) {
        const coordinates = [];
        const radiusX = (rectWidth) / 2;
        const radiusY = (rectHeight) / 2;

        for (let i = 0; i < n; i++) {
            let xFromCenter, yFromCenter;
            let isTooClose;

            do {
                isTooClose = false;

                // Generate random coordinates in the given quadrant
                switch (q) {
                    case 1:
                        xFromCenter = getRandomNumber(0.2, 0.8) * radiusX; // Positive X
                        yFromCenter = -(getRandomNumber(0.2, 0.8) * radiusY); // Negative Y
                        break;
                    case 2:
                        xFromCenter = -(getRandomNumber(0.2, 0.8) * radiusX); // Negative X
                        yFromCenter = -(getRandomNumber(0.2, 0.8) * radiusY); // Negative Y
                        break;
                    case 3:
                        xFromCenter = -(getRandomNumber(0.2, 0.8) * radiusX); // Negative X
                        yFromCenter = getRandomNumber(0.2, 0.8) * radiusY; // Positive Y
                        break;
                    case 4:
                        xFromCenter = getRandomNumber(0.2, 0.8) * radiusX; // Positive X
                        yFromCenter = getRandomNumber(0.2, 0.8) * radiusY; // Positive Y
                        break;
                    default:
                        throw new Error("Quadrant must be between 1 and 4.");
                }

                // Check if the new point is too close to any existing points
                for (let coord of coordinates) {
                    const dx = coord.left - (rectWidth / 2 + xFromCenter);
                    const dy = coord.top - (rectHeight / 2 + yFromCenter);
                    const distance = Math.sqrt(dx * dx + dy * dy);

                    if (distance < minDistance) {
                        isTooClose = true;
                        break;
                    }
                    if ((xFromCenter * xFromCenter + yFromCenter * yFromCenter) >= (radiusX - 10) * (radiusX - 10)) {
                        isTooClose = true;
                        break;
                    }
                }

            } while (isTooClose);

            // Convert the center-relative coordinates to top-left coordinates
            const topLeftCoords = convertToTopLeftCoordinates(rectWidth, rectHeight, xFromCenter, yFromCenter);
            coordinates.push(topLeftCoords);
        }
        console.log(q, coordinates);

        return coordinates;
    }
    function convertToTopLeftCoordinates(rectWidth, rectHeight, xFromCenter, yFromCenter) {
        const centerX = rectWidth / 2;
        const centerY = rectHeight / 2;
        const left = centerX + xFromCenter;
        const top = centerY + yFromCenter;
        return { top: top, left: left };
    }
    function getRandomNumber(min, max) {
        return Math.random() * (max - min) + min;
    }

    return (
        <div>
            <div className="radar">
                <div className="radar-sector-titles">
                    <p style={{ top: "30px", left: "-100px" }}>Industry</p>
                    <p style={{ top: "30px", right: "-100px" }}>Valuations</p>
                    <p style={{ bottom: "30px", left: "-100px" }}>Financials</p>
                    <p style={{ bottom: "30px", right: "-100px" }}>Corporate<br />Governance</p>
                </div>
                <div className="radar-bg">
                    <div className="radar-circle-1"></div>
                    <div className="radar-circle-2"></div>
                    <div className="radar-circle-3"></div>
                    <div className="axis-horz"></div>
                    <div className="axis-vert"></div>
                </div>
                {startRadar && <div className="radar-scanner"></div>}
                <div className="radar-overlay">
                    <div className='quarter-group'>
                        <div className="quarter quarter-1"></div>
                        <div className="quarter quarter-2"></div>
                        <div className="quarter quarter-3"></div>
                        <div className="quarter quarter-4"></div>
                    </div>
                    {showDots && <div className='dots-group'>
                        {dots1.map((q, index) => {
                            let coordinates = getRandomTopLeftCoordinatesInQuadrant(400, 400, 4, q, 40);
                            return <>
                                {coordinates?.map((item, i) => (
                                    <div key={i} className="radar-dot" style={{ top: item.top, left: item.left }}></div>
                                ))}
                            </>;
                        })}
                    </div>}
                </div>
                {showScanButton && <div className="radar-overlay-button">
                    <button onClick={handleScanButtonClick}><IoSearch style={{ scale: "1.3" }} /> <span><b>Scan</b> for <b>67</b> potential risks</span></button>
                </div>}
            </div>
        </div>
    );
};

export default Radar;
