import './Overview.css';
import { MdEdit } from "react-icons/md";
import React, { useState } from 'react';
import { useStateContext } from '../../../context/StateContext';
import OverviewSection from './OverviewSection';
import SelectFields from './SelectFields';
import { useSelector } from 'react-redux';

const Overview = () => {
    const { setEditing } = useStateContext();
    const savedFields = useSelector(state => state.stock.savedFields);
    let r = ((savedFields?.length + 4) / 3);
    const [rows, setRows] = useState(r);
    const handleAddRows = () => {
        setRows((prev) => prev + 1);
    };

    return (
        <section id='overview' className="overview-container">
            <div className="overview-header">
                <h1>Overview</h1>
                <button onClick={() => { setEditing(true); }}>Edit Fields <MdEdit color='#00AFFF' /></button>
            </div>
            <OverviewSection rows={rows} setEditing={setEditing} />
            <SelectFields rows={rows} handleAddRows={handleAddRows} />
        </section>
    );
};

export default Overview;