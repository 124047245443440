import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    stocks: {},
};

const watchlistSlice = createSlice({
    name: 'watchlist',
    initialState: initialState,
    reducers: {
        addToWatchlist(state, action) {
            state.stocks[action.payload.nseCode] = action.payload.fields;
        },
        removeFromWatchlist(state, action) {
            delete state.stocks[action.payload.nseCode];
        },
        updateStockFields(state, action) {
            const { nseCode, fields } = action.payload;
            if (state.stocks.hasOwnProperty(nseCode)) {
                state.stocks[nseCode] = fields;
            }
        }
    },
});

export default watchlistSlice.reducer;
export const watchlistActions = watchlistSlice.actions;