import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    stockCode: null,
    stockName: null,
    stockLogo: null,
    stockPrice: null,
    stockWebsite: null,
    stockChange: null,
    stockChangePercent: null,
    stockCurrChange: null,
    stockCurrChangePercent: null,
    stockLastUpdatedTime: null,
    selectedFields: [],
    savedFields: [],
};

const stockSlice = createSlice({
    name: 'stock',
    initialState: initialState,
    reducers: {
        setStock(state, action) {
            state.stockCode = action.payload.code;
            state.stockName = action.payload.stockName || null;
            state.stockLogo = action.payload.stockLogo || null;
            state.stockWebsite = action.payload.stockWebsite || null;
        },
        setStockDetails(state, action) {
            state.stockPrice = action.payload.stockPrice || null;
            state.stockChange = action.payload.stockChange || null;
            state.stockChangePercent = action.payload.stockChangePercent || null;
            state.stockLastUpdatedTime = action.payload.stockLastUpdatedTime || null;
        },
        setStockCurrDetails(state, action) {
            state.stockCurrChange = action.payload.stockCurrChange || null;
            state.stockCurrChangePercent = action.payload.stockCurrChangePercent || null;
        },
        addSelectedField(state, action) {
            const item = action.payload;
            const isItemInArray = state.selectedFields.some(selectedItem => selectedItem.name === item.name);

            if (isItemInArray) {
                state.selectedFields = state.selectedFields.filter(selectedItem => selectedItem.name !== item.name);
            } else {
                state.selectedFields.push(item);
            }
        },
        deleteSelectedFields(state, action) {
            const index = action.payload;
            state.selectedFields = state.selectedFields.filter((item, i) => i !== index);
        },
        saveFields(state, action) {
            state.savedFields = action.payload;
        }
    },
});

export default stockSlice.reducer;
export const stockActions = stockSlice.actions;