import './FeaturesSection.css';
import News from './News/News';
import Concalls from './Concalls/Concalls';
import CorporateFillings from './CorporateFillings/CorporateFillings';
import HeroForm from './../Banner/EmailForm/HeroForm';
import Forensic from './Forensic/Forensic';
import { useStateContext } from '../../context/StateContext';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useLayoutEffect } from 'react';
import Footer from '../Footer/Footer';
import offsetGen from '../../utils/randomAnimate';
import Skeleton from '../ui/Skeleton';

gsap.registerPlugin(ScrollTrigger);
const tl1 = gsap.timeline();

const invalidateTL = () => {
    console.log("Invalidate called");
    tl1.invalidate();
};

const FeaturesSection = () => {
    const { isChecked } = useStateContext();
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const scaleRatio1 = (windowHeight / 730) * (1440 / windowWidth);
    const scaleRatio2 = (Math.pow(windowHeight, 0.99) / 730) * (1440 / windowWidth);
    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            console.log(scaleRatio1);
            console.log(scaleRatio2);
            tl1
                .addLabel("start")
                .fromTo('.news-lottie', {
                    scale: 1
                }, {
                    scale: 0,
                })
                .fromTo('.forensic-video', {
                    scale: 1
                }, {
                    scale: 0,
                }, "<")
                .fromTo('.corporate-lottie', {
                    scale: 1
                }, {
                    scale: 0,
                }, "<")
                .fromTo('.corporate-ui-element', {
                    opacity: 1
                }, {
                    opacity: 0,
                }, "<")
                .to('.concall-body', {
                    scale: 1
                }, {
                    scale: 0,
                }, "<")
                .fromTo('.news-section', {
                    width: '43.4vw',
                    height: '34.7vw',
                    borderRadius: '10px',
                }, {
                    height: "38.19vw",
                    width: "43.4vw",
                    borderRadius: '50px',
                }, "<")
                .fromTo('.concall-section', {
                    width: '43.4vw',
                    height: isChecked ? '56.6vw' : '42.29vw',
                    borderRadius: '10px',
                }, {
                    height: "38.19vw",
                    width: "43.4vw",
                    borderRadius: '50px',
                }, "<")
                .fromTo('.corporate-section', {
                    width: '43.4vw',
                    height: '45vw',
                    borderRadius: '10px',
                    top: isChecked ? '-22vw' : '-7.5vw',
                }, {
                    width: "43.4vw",
                    height: "38.19vw",
                    borderRadius: '50px',
                    top: 0,
                }, "<")
                .fromTo('.forensic-section', {
                    width: "43.4vw",
                    height: "41.7vw",
                    borderRadius: '10px',
                }, {
                    height: "38.19vw",
                    width: "43.4vw",
                    borderRadius: '50px',
                    top: 0
                }, "<")
                .fromTo('.grid-item', {
                    opacity: 0
                }, {
                    opacity: 1,
                }, "<")
                .fromTo('.hide-lottie', {
                    opacity: 1,
                }, {
                    opacity: 0,
                }, "<")
                .fromTo('.hide-everything', {
                    opacity: 0,
                }, {
                    fontSize: '6.25vw',
                    opacity: 1,
                }, ">")
                .to('.grid-container', {
                    scale: 0.26 * scaleRatio2,
                    gap: '2.78vw',
                }, "<")
                .set('.footer', {
                    zIndex: 1,
                })
                .addLabel("scaled")
                .to('.grid-container', {
                    scale: 0.11 * scaleRatio1,
                    gap: '4.17vw',
                }, ">")
                .to('.hide-everything', {
                    opacity: 0,
                }, "<50%")
                .add(handleBlinkingEnd)
                .to('.leave-complexity-container', {
                    opacity: 1,
                }, ">")
                .add(handleBlinkingStart)
                .fromTo('.grid-email', {
                    opacity: 0,
                }, {
                    opacity: 1,
                }, ">")
                .addLabel('end');

            ScrollTrigger.create({
                trigger: ".forensic-section",
                start: 'bottom+=50 bottom-=50',
                end: `bottom-=${isChecked ? 270 : 150} bottom`,
                endTrigger: ".footer",
                scrub: true,
                animation: tl1,
                pin: ".grid-container",
                pinSpacing: true,
                snap: {
                    snapTo: "labelsDirectional",
                    duration: 2,
                },
                markers: false,
                invalidateOnRefresh: true,
            });
        });

        return () => ctx.revert();
    }, [scaleRatio1, scaleRatio2, isChecked]);

    const handleBlinkingStart = () => {
        let gridItems = document.querySelectorAll(".grid-item");
        console.log("Blinking");
        const offset = offsetGen(10, 30, 2, 3, 1, 2, 0.7);
        gridItems.forEach((item, index) => {
            let r = Math.floor(index / 30);
            let c = index % 30;
            if (r === 8) c += 2;
            if (r === 9) c += 4;
            if (offset[r][c] > 0) {
                item.classList.add("blinking");
                item.style.animationDelay = `${offset[r][c] * 1000}ms`;
            }
        });
    };

    const handleBlinkingEnd = () => {
        let gridItems = document.querySelectorAll(".grid-item");
        gridItems.forEach((item) => {
            item.classList.remove("blinking");
        });
    };

    return (
        <>
            <div className="outer-container">
                <div className="grid-container">
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item">
                        <span className='hide-everything'>Valuation</span>
                    </div>
                    <div className="grid-item">
                        <div className='flex-col alignItems-center hide-everything'>
                            <span>Mutual</span>
                            <span>Funds</span>
                        </div>
                    </div>
                    <div className="grid-item">
                        <div className='flex-col alignItems-center hide-everything'>
                            <span>Supply</span>
                            <span>Chains</span>
                        </div>
                    </div>
                    <div className="grid-item"><span className='hide-everything'>Forex</span></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><span className='hide-everything'>Trade</span></div>
                    <div className="grid-item">
                        <div className='flex-col alignItems-center hide-everything'>
                            <span>Govt.</span>
                            <span>Data</span>
                        </div>
                    </div>
                    <div className="grid-item">
                        <div className='flex-col alignItems-center hide-everything'>
                            <span>Alternate</span>
                            <span>Data</span>
                        </div>
                    </div>
                    <div className="grid-item"><span className=' hide-everything'>KPIs</span></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="news-section"><News /></div>
                    <div className="concall-section" style={{ height: isChecked ? '56.6vw' : '42.29vw' }}><Concalls /></div>
                    <div className="grid-item">
                        <div className='flex-col alignItems-center hide-everything'>
                            <span>Annual</span>
                            <span>Report</span>
                        </div>
                    </div>
                    <div className="grid-item">
                        <div className='flex-col alignItems-center hide-everything'>
                            <span>Raw</span>
                            <span>Materials</span>
                        </div>
                    </div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="corporate-section" style={{ top: isChecked ? '-22vw' : '-7.5vw' }}><CorporateFillings /></div>
                    <div className="forensic-section"><Forensic /></div>
                    <div className="grid-item triggerClass"><span className='hide-everything'>Employees</span></div>
                    <div className="grid-item"><span className='hide-everything'>Sentiment</span></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                    <div className="grid-item"><Skeleton /></div>
                </div>
            </div>
            <div className='footer'>
                <div className="leave-complexity-container">
                    <div className="leave-complexity">
                        <span>Leave Complexity Behind.</span>
                        <div className='grid-email'>
                            <HeroForm fontSize={'1.56vw'} textColor={'#A5FF95'} animationTime={'2.5s'} />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export { FeaturesSection, invalidateTL };
