import React from 'react';
import { useStateContext } from '../../context/StateContext';

const CheckboxComponent = ({ pattern }) => {
    const { selectedPattern, setSelectedPattern } = useStateContext();
    const isChecked = selectedPattern.some(item => item === pattern.name);

    const handleCheckboxChange = () => {
        setSelectedPattern((prevValue) => {
            const patternIndex = prevValue.findIndex(item => item === pattern.name);
            if (patternIndex !== -1) {
                return [...prevValue.slice(0, patternIndex), ...prevValue.slice(patternIndex + 1)];
            } else {
                return [...prevValue, pattern.name];
            }
        });
    }

    return (
        <div className='custom-check' style={{ '--color': pattern.color }}>
            <input id={pattern.name} className="checkbox-custom" name="checkbox-1" type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
            <label htmlFor={pattern.name} className="checkbox-custom-label" style={{ color: pattern.color, fontFamily: "Inter-Regular" }}>
                {pattern.name}
                <img src={`/assets/svgs/${pattern.svg}`} alt="" />
            </label>
        </div>
    );
};

export default CheckboxComponent;
