import React from 'react'
import { useNavigate } from 'react-router-dom';

const BlogCard = ({ index }) => {
    const navigate = useNavigate();
    return (
        <div className="blog-card" onClick={() => navigate(`blog/${index}`)}>
            <div>
                <p className="blog-date">JUN 9, 2024</p>
                <h2 className='blog-card-title'>Announcing Marketrack :<br /> Revolutionizing Retail Investing</h2>
                <p className='blog-card-desc'>Marketrack is designed to simplify investment management, making it accessible, automated & personalized for everyone. With just one click, you can transform how you invest.</p>
            </div>
            <div className="blog-card-img"></div>
        </div>
    )
}

export default BlogCard;