import React from 'react';
import ContentLoader from "react-content-loader";

const TextLoader = (props) => {
    return (
        <div>
            <ContentLoader
                speed={2}
                width={props.width}
                height={props.height}
                viewBox="0 0 100% 100%"
                backgroundColor="#333333"
                foregroundColor="#4D4D4D"
            >
                <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
            </ContentLoader>
        </div>
    )
}

export default TextLoader;