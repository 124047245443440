import React, { useState } from 'react';
import './ShareholdingTable.css';
import { HiUserCircle } from "react-icons/hi2";

const quarterFormat = (item) => {
    let parts = item.split(" ");
    return `${parts[0]?.slice(0, 3)} ${parts[1]}`;
};

const RenderRows = ({ data, color, label, imgSrc, p, profilePhoto }) => {
    const [toggleState, setToggleState] = useState(false);

    // Create a reversed copy of the data.sum array
    const reversedSum = [...(data?.sum || [])].reverse();

    return (
        <>
            <tr key={label}>
                <td className='row-title' style={{ color, paddingRight: `${p + 20}px` }}>
                    {label} {imgSrc && <img src={imgSrc} alt="" />}
                    <span
                        onClick={() => setToggleState(prev => !prev)}
                        style={{ color: '#29B6F6', fontSize: '18px', cursor: 'pointer' }}
                    >
                        {toggleState ? '-' : '+'}
                    </span>
                </td>
                {reversedSum.map((val, i) => (
                    <td key={i}>
                        {val[Object.keys(val)[0]] ? (val[Object.keys(val)[0]][1]).toFixed(2) : 0}
                    </td>
                ))}
            </tr>
            {toggleState && data && Object.keys(data)?.map((item, index) => {
                if (item !== 'sum') {
                    if (typeof data[item] === 'object' && !Array.isArray(data[item])) {
                        return <RenderRows key={item} data={data[item]} label={item} imgSrc={""} color={'#AAAAAA'} p={10} profilePhoto={profilePhoto} />;
                    }
                    else {
                        let reversedData = [...(data[item] || [])].reverse();
                        return (
                            <tr key={index}>
                                <td className='row-title' style={{ paddingRight: `${p}px` }}>
                                    {(profilePhoto && profilePhoto[item])
                                        ? <img style={{ borderRadius: '20px', scale: "1.2" }} src={profilePhoto[item]} alt="" />
                                        : <HiUserCircle style={{ fontSize: "28px" }} />}
                                    <span>{item}</span>
                                </td>
                                {reversedData.map((val, i) => (
                                    <td key={i}>
                                        {val[Object.keys(val)[0]] ? val[Object.keys(val)[0]][1]?.toFixed(2) : 0}
                                    </td>
                                ))}
                            </tr>
                        );
                    }
                }
                return null;
            })}
        </>
    );
};


const ShareholdingTable = ({ qtrs, tableData, profilePhoto }) => {
    // console.log(tableData);
    let quarters = [...qtrs || []].reverse();

    return (
        <div className="shareholding-table">
            <p>
                All Numbers in Percentages %
            </p>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th style={{ position: 'sticky', left: "-1px", width: '150px', backgroundColor: '#1F1F1F' }}></th>
                            {quarters?.map((quarter, i) => (
                                <th key={i}>{quarterFormat(quarter?.qtr)}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <RenderRows data={tableData?.promoters} color={'#8FF377'} label={'Promoter'} imgSrc={'/assets/svgs/promoter.svg'} p={20} profilePhoto={profilePhoto} />
                        <RenderRows data={tableData?.fii} color={'#FF94E6'} label={'FII'} imgSrc={'/assets/svgs/FII.svg'} p={20} />
                        <RenderRows data={tableData?.dii} color={'#29B6F6'} label={'DII'} imgSrc={'/assets/svgs/DII.svg'} p={20} />
                        <RenderRows data={tableData?.retail} color={'#BFC262'} label={'Public'} imgSrc={'/assets/svgs/public.svg'} p={20} />
                        <RenderRows data={tableData?.government} color={'#FFA572'} label={'Govt'} imgSrc={'/assets/svgs/govt.svg'} p={20} />
                        <RenderRows data={tableData?.others} color={'#AAAAAA'} label={'Others'} imgSrc={'/assets/svgs/others.svg'} p={20} />
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ShareholdingTable;
