import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate, Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import './Navbar2.css';
import { authActions } from '../../redux/features/auth/authSlice';
import { IoMdNotifications } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import { FaSignOutAlt } from "react-icons/fa";
import NavSearch from './NavSearch';
import { googleLogout } from '@react-oauth/google';
import { toast } from 'react-toastify';
import gsap from 'gsap';

const Navbar2 = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userData = useSelector(state => state.auth.user);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const [showNotification, setShowNotification] = useState(false);
    const [showProfile, setShowProfile] = useState(false);
    const location = useLocation();
    const path = location.pathname;

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const sidebarRef = useRef(null);

    const handleLogout = () => {
        googleLogout();
        dispatch(authActions.logout());
        toast.success("Logged out successfully!");
    }

    const [progress, setProgress] = useState(0);

    const handleScroll = () => {
        const blogPage = document.querySelector('.blog-page');
        if (blogPage) {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const blogPageTop = blogPage.offsetTop;
            const blogPageHeight = blogPage.scrollHeight;
            const scrollHeight = blogPageHeight - window.innerHeight;
            let scrollProgress = ((scrollTop - blogPageTop) / scrollHeight) * 100;

            // Clamp the scrollProgress value between 0 and 100
            scrollProgress = Math.max(0, Math.min(scrollProgress, 100));

            setProgress(scrollProgress);
        }
    };

    useEffect(() => {
        if (path.startsWith('/about/blog'))
            gsap.to('.progress-bar', {
                width: `${progress}%`,
                ease: 'power1.out',
                duration: 0.2,
            });
    }, [progress, path]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }, 200);

        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                setSidebarOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [sidebarRef]);

    const handleSidebarToggle = () => {
        setSidebarOpen(prev => !prev);
    };

    return (
        <div className='nav2-box'>
            <div className='nav-container' style={(path.startsWith('/app/stock/')) ? { background: '#252525', boxShadow: '#000 2px 4px 8px' } : {}}>
                <div style={{ flex: 1, userSelect: "none" }} ><img src="/assets/svgs/MarketrackNavLogo.svg" alt="" onClick={() => { navigate('/app'); }} /></div>
                {path.startsWith('/app/stock/') ?
                    <NavSearch />
                    :
                    <div className="nav-items-group">
                        <ul>
                            <li><NavLink to={'/app'} activeclassname="active-nav-link" className="nav-links">Home</NavLink></li>
                            <li><Link to={'/'} className="nav-links">Product</Link></li>
                            <li><Link to={'#'} className="nav-links">Pricing</Link></li>
                            <li><NavLink to={'/about'} activeclassname="active-nav-link" className="nav-links">About</NavLink></li>
                            <li><Link to={'#'} className="nav-links">Contact</Link></li>
                        </ul>
                    </div>
                }
                <div style={{ flex: '1', display: 'flex', justifyContent: 'flex-end', paddingRight: '1vw' }}>
                    <div className="flex-row" style={{ gap: '1vw', alignItems: 'center' }}>
                        {isAuthenticated ?
                            <>
                                <div className="notification">
                                    <div
                                        className="notification-icon"
                                        onClick={() => {
                                            setShowNotification((prevValue) => !prevValue);
                                            setShowProfile(false);
                                        }}
                                    >
                                        <IoMdNotifications style={{ fontSize: "30px" }} />
                                        <span>Notifications</span>
                                    </div>
                                    <div className="notification-dd" style={{ scale: showNotification ? '1' : '0' }}>
                                        <ul>
                                            <li>
                                                <img src="/assets/images/profile.png" alt="" />
                                                <span>Some Notification</span>
                                            </li>
                                            <li>
                                                <img src="/assets/images/profile.png" alt="" />
                                                <span>Some Notification</span>
                                            </li>
                                            <li>
                                                <img src="/assets/images/profile.png" alt="" />
                                                <span>Some Notification</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="profile">
                                    <div
                                        className="profile-icon"
                                        onClick={() => {
                                            setShowProfile((prevValue) => !prevValue);
                                            setShowNotification(false);
                                        }}
                                    >
                                        <img src={userData.picture} alt="" />
                                        <span>{userData.given_name}</span>
                                        <IoIosArrowDown />
                                    </div>
                                    <div className="profile-dd" style={{ scale: showProfile ? '1' : '0' }}>
                                        <ul>
                                            <li><FaUser /> Profile</li>
                                            <li><IoMdSettings /> Settings</li>
                                            <li onClick={handleLogout}><FaSignOutAlt /> Logout</li>
                                        </ul>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <button disabled={path === '/register'} className={`${(path === '/register') ? 'register-button-disabled' : "register-button"}`} onClick={() => {
                                    if (path !== '/login') dispatch(authActions.setLastLocation(path));
                                    navigate('/register');
                                }}>Register</button>
                                <button disabled={path === '/login'} className={`${(path === '/login') ? 'login-button-disabled' : "login-button"}`} onClick={() => {
                                    if (path !== '/register') dispatch(authActions.setLastLocation(path));
                                    navigate('/login');
                                }}>Login</button>
                            </>
                        }
                    </div>
                </div>
            </div>
            {/* Navbar for mobile */}
            <div className='nav2-mobile' ref={sidebarRef}>
                <div className="nav2-mobile-header">
                    <div className="nav2-mobile-header-box">
                        <img src="/assets/svgs/MarketrackNavLogo.svg" alt="" onClick={() => { navigate('/app'); }} />
                    </div>
                    <input type="checkbox" className="openSidebarMenu" id="openSidebarMenu" checked={sidebarOpen} onChange={handleSidebarToggle} />
                    <label htmlFor="openSidebarMenu" className="sidebarIconToggle">
                        <div className="spinner diagonal part-1"></div>
                        <div className="spinner horizontal"></div>
                        <div className="spinner diagonal part-2"></div>
                    </label>
                    <div id="sidebarMenu">
                        <ul className="sidebarMenuInner">
                            <li><NavLink to={'/app'} activeclassname="active-nav-link" className="nav-links">Home</NavLink></li>
                            <li><Link to={'/'} className="nav-links">Product</Link></li>
                            <li><Link to={'#'} className="nav-links">Pricing</Link></li>
                            <li><NavLink to={'/about'} activeclassname="active-nav-link" className="nav-links">About</NavLink></li>
                            <li><Link to={'#'} className="nav-links">Contact</Link></li>
                        </ul>
                        <div className="horz-line"></div>
                        <div className="nav-mobile-btn-grp">
                            <button disabled={path === '/register'} className={`${(path === '/register') ? 'register-button-disabled' : "register-button"}`} onClick={() => {
                                if (path !== '/login') dispatch(authActions.setLastLocation(path));
                                navigate('/register');
                            }}>Register</button>
                            <button disabled={path === '/login'} className={`${(path === '/login') ? 'login-button-disabled' : "login-button"}`} onClick={() => {
                                if (path !== '/register') dispatch(authActions.setLastLocation(path));
                                navigate('/login');
                            }}>Login</button>
                        </div>
                    </div>
                </div>
            </div>
            {(path.startsWith('/about/blog')) && <div className="page-progress-container">
                <div className="progress-bar"></div>
            </div>}
        </div>
    )
}

export default Navbar2;