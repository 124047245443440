import React, { useState } from 'react';
import './Financials.css';
import QuarterSelector from '../Shareholding/QuarterSelector';
import IncomeGraph from './IncomeGraph/IncomeGraph';
import IncomeStatementTable from './IncomeStatementTable/IncomeStatementTable';

import { GoFile } from "react-icons/go";
import { IoEyeOutline } from "react-icons/io5";
import { BsTable } from "react-icons/bs";
import { HiTrendingUp } from "react-icons/hi";

export const TabButton = ({ handleClick, isSelected, value, svg }) => {
    return <button className={`financials-button ${isSelected ? "active" : ""}`} onClick={handleClick}>{value} {svg}</button>
}

const Financials = () => {
    const [tab, setTab] = useState("income-statement");
    const [type, setType] = useState('consolidated');
    const [frequency, setFrequency] = useState('quarterly');
    const [view, setView] = useState('graph');
    return (
        <section id='financial' className='financials-section'>
            <h1>Financials</h1>
            <div className="buttons-tab">
                <TabButton
                    value={"Income Statement"}
                    isSelected={(tab === 'income-statement')}
                    svg={<img src={`/assets/svgs/Financial/${tab === 'income-statement' ? "incmstmt-selected.svg" : "incmstmt.svg"}`} alt="" />}
                    handleClick={() => { setTab('income-statement'); }}
                />
                <TabButton
                    value={"Balance Sheet"}
                    isSelected={(tab === 'balance-sheet')}
                    handleClick={() => { setTab('balance-sheet'); }}
                    svg={<img src={`/assets/svgs/Financial/${tab === 'balance-sheet' ? "balance-sheet-selected.svg" : "balance-sheet.svg"}`} alt="" />}
                />
                <TabButton
                    value={"Cashflow Statement"}
                    isSelected={(tab === 'cashflow-statement')}
                    handleClick={() => { setTab('cashflow-statement'); }}
                    svg={<img src={`/assets/svgs/Financial/${tab === 'cashflow-statement' ? "cashflow-selected.svg" : "cashflow.svg"}`} alt="" />}
                />
            </div>
            <div className="modifiers-group-box">
                <div className='modifiers-group modifiers-group-custom'>
                    <div className='modifiers'>
                        <p>Type<GoFile /></p>
                        <div className="buttons-tab">
                            <TabButton
                                value={"Consolidated"}
                                isSelected={(type === 'consolidated')}
                                handleClick={() => { setType('consolidated'); }}
                                svg={<img src={`/assets/svgs/Financial/${(type === 'consolidated') ? "consolidated-selected.svg" : "consolidated.svg"}`} alt="" />}
                            />
                            <TabButton
                                value={"Standalone"}
                                isSelected={(type === 'standalone')}
                                handleClick={() => { setType('standalone'); }}
                                svg={<img src={`/assets/svgs/Financial/${(type === 'standalone') ? "standalone-selected.svg" : "standalone.svg"}`} alt="" />}
                            />
                        </div>
                    </div>
                    <div className="modifiers">
                        <p>Frequency</p>
                        <div className="buttons-tab">
                            <TabButton
                                value={"Quarterly"}
                                isSelected={(frequency === 'quarterly')}
                                handleClick={() => { setFrequency('quarterly') }}
                                svg={<img src={`/assets/svgs/Shareholding/${frequency === 'quarterly' ? "quarterly-selected.svg" : "quarterly.svg"}`} alt="" />}
                            />
                            <TabButton
                                value={"Annual"}
                                isSelected={(frequency === 'annual')}
                                handleClick={() => { setFrequency('annual') }}
                                svg={<img src={`/assets/svgs/Shareholding/${frequency === 'annual' ? "annual-selected.svg" : "annual.svg"}`} alt="" />}
                            />
                        </div>
                    </div>
                    <div className="modifiers">
                        <p>View <IoEyeOutline /></p>
                        <div className="buttons-tab">
                            <TabButton
                                value={"Graph"}
                                isSelected={(view === 'graph')}
                                handleClick={() => { setView('graph') }}
                                svg={<HiTrendingUp style={{ scale: '1.2' }} />}
                            />
                            <TabButton
                                value={"Table"}
                                isSelected={(view === 'table')}
                                handleClick={() => { setView('table') }}
                                svg={<BsTable />}
                            />
                        </div>
                    </div>
                </div>
                <QuarterSelector />
            </div>
            {(view === 'graph') && <IncomeGraph />}
            {(view === 'table') && <IncomeStatementTable />}
        </section>
    )
}

export default Financials;