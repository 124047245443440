import React from 'react';
// import Field from './Field';
import { FaCheck } from "react-icons/fa6";
import { FaXmark } from "react-icons/fa6";
import { FaSearch } from "react-icons/fa";
import { useStateContext } from '../../../context/StateContext';
import EditField from './EditField';
import { useDispatch, useSelector } from 'react-redux';
import { watchlistActions } from '../../../redux/features/watchlist/watchlistSlice';
import { Checkbox, FormControlLabel } from '@mui/material';
import { stockActions } from '../../../redux/features/stock/stockSlice';
import Grid from '@mui/material/Grid';

const SelectFields = ({ rows, handleAddRows }) => {
    const { editing, setEditing } = useStateContext();
    const dispatch = useDispatch();
    const selectedFields = useSelector(state => state.stock.selectedFields);
    const stock = useSelector(state => state.stock.stockName);

    let profitLoss = [
        {
            name: "P/E Ratio",
            data: 700
        }, {
            name: "P/B Ratio",
            data: 600
        }, {
            name: "ROCE",
            data: 500
        }, {
            name: "ROE",
            data: 400
        }, {
            name: "Div Yield",
            data: 300
        }, {
            name: "ICR",
            data: 200
        }, {
            name: "Div Payout Ratio",
            data: 2.5
        }, {
            name: "PEG",
            data: 100
        }, {
            name: "Debt Ratio",
            data: 5
        },
    ];

    const handleFieldAdd = (item) => {
        dispatch(stockActions.addSelectedField(item));
    };

    const handleCheckboxChange = (item) => {
        let e = document.getElementById(`${item.name}`);
        e.checked = false;
    };

    const handleSave = () => {
        if (selectedFields.length > (rows * 3 - 4)) {
            handleAddRows();
        }
        dispatch(stockActions.saveFields(selectedFields));
        dispatch(watchlistActions.updateStockFields({ nseCode: stock, fields: selectedFields }));
        setEditing(false);
    };

    // const handleAddNewRow = ()=>{
    //     let ele = document.getElementsByClassName('selected-field-items');
    //     ele[0].style.height = (parseFloat(ele[0].style.height) + 3) +'vw';
    // }

    return (
        <div className='select-fields-section' style={{ scale: editing ? '1' : '0', opacity: editing ? '1' : '0' }}>
            <div className="editable-section">
                <p>Hold and Drag to Reorder Fields</p>
                <div className="selected-field-items">
                    {selectedFields.map((item, index) => {
                        return (
                            <EditField
                                value={item}
                                index={index}
                                key={index}
                                onCheckboxChange={handleCheckboxChange}
                            />
                        );
                    })}
                </div>
                {/* <span className='add-new' onClick={handleAddRows}>Add New Row</span> */}
                <div className="changes-btn">
                    <button className='save-btn' onClick={handleSave}>Save Changes <FaCheck color='#29B6F6' style={{ scale: '1.3' }} /></button>
                    <button className='cancel-btn' onClick={() => { setEditing(false) }}>Cancel <FaXmark style={{ scale: '1.3' }} /></button>
                </div>
            </div>
            <div className="fields-select-section">
                <div className="search-fields">
                    <input type="text" name="" id="" placeholder='Search for KPIs, Ratios..' />
                    <FaSearch style={{ cursor: 'pointer' }} />
                </div>
                {/* <div className="fields-grid">
                    {profitLoss.map((item, index) => {
                        return (
                            <FormControlLabel key={index} id={item.name} className={'parameter-checkbox'} control={<Checkbox checked={selectedFields.some(selectedItem => selectedItem.name === item.name)} onChange={() => handleFieldAdd(item)} sx={{
                                color: 'white',
                                '&.Mui-checked': {
                                    color: '#29B6F6',
                                },
                            }} />} label={item.name} />
                        );
                    })}
                </div> */}
                <Grid container spacing={{ xs: 1, md: 3 }} columns={{ xs: 4, sm: 8, md: 16 }}>
                    {profitLoss.map((item, index) => (
                        <Grid item xs={2} sm={4} md={4} key={index}>
                            <FormControlLabel key={index} id={item.name} className={'parameter-checkbox'} control={<Checkbox checked={selectedFields.some(selectedItem => selectedItem.name === item.name)} onChange={() => handleFieldAdd(item)} sx={{
                                color: 'white',
                                '&.Mui-checked': {
                                    color: '#29B6F6',
                                },
                            }} />} label={item.name} />
                            {/* <div key={index} className='custom-check' style={{ '--color': '#29B6F6' }}>
                                <input id={item.name} className="checkbox-custom" type="checkbox" checked={selectedFields.some(selectedItem => selectedItem.name === item.name)} onChange={() => handleFieldAdd(item)} />
                                <label htmlFor={item.name} className="checkbox-custom-label" style={{ color: "#29B6F6", fontFamily: "Inter-Regular" }}>{item.name}</label>
                            </div> */}
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    )
}

export default SelectFields;