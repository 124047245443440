import React, { useEffect, useLayoutEffect, useRef } from 'react';
import './News.css';
import { useStateContext } from '../../../context/StateContext';
import NewsAnimation from './../../../lotties/NewsAnimation.json';
import { FaNewspaper } from 'react-icons/fa';
import Lottie from "lottie-react";
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/all';

gsap.registerPlugin(ScrollTrigger);
const tl = gsap.timeline();

const News = () => {
    const { isChecked } = useStateContext();
    const newsRef = useRef(null);
    useEffect(()=>{
        if (!isChecked && tl.progress()===1) {
            newsRef.current.setSpeed(0.7);
            newsRef.current.playSegments([1, 200], true);
        }
        if (isChecked && tl.progress()===1) {
            newsRef.current.setSpeed(1);
            newsRef.current.playSegments([200, 700], true);
        }
    }, [isChecked]);
    const playLottie = () => {
        if (!isChecked) {
            newsRef.current.setSpeed(0.8);
            newsRef.current.playSegments([1, 200], true);
        }
    };
    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            tl
                .to('.news-lottie', {
                    opacity: 1,
                })
                .set('.corporate-lottie', {
                    scale: 0,
                });
            ScrollTrigger.create({
                animation: tl,
                trigger: '.news',
                start: 'top 50%',
                end: 'top 50%',
                scrub: true,
                markers: false,
                invalidateOnRefresh: true,
                onLeave: playLottie,
            });
        });
        return () => ctx.revert();
    });

    return (
        <>
            <div className='news hide-lottie'>
                <div className='news-heading'>
                    <h1 className='news-heading-text'>
                        <span>News</span>
                        <FaNewspaper style={{ paddingLeft: '12px' }} />
                    </h1>
                </div>
                <div className="news-lottie">
                    <Lottie
                        lottieRef={newsRef}
                        animationData={JSON.parse(JSON.stringify(NewsAnimation))}
                        autoplay={false}
                        loop={false}
                    />
                </div>
            </div>
            <div className="skeleton-text hide-everything">
                <div className="flex-col alignItems-center">
                    <span>News</span>
                    <FaNewspaper />
                </div>
            </div>
        </>
    )
}

export default News;