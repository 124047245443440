import React from 'react';
import './IncomeStatementTable.css';


const FinancialTable = () => {
    const data = {
        headers: ['Sep', 'Dec', 'Mar', 'Jun', 'Sep', 'Dec', 'Mar'],
        totalIncome: [40652, 40652, 40652, 40652, 40652, 40652, 40652],
        revenueFromOperations: [37923, 37923, 37923, 37923, 37923, 37923, 37923],
        otherIncome: [2729, 2729, 2729, 2729, 2729, 2729, 2729],
        totalExpenses: [40652, 40652, 40652, 40652, 40652, 40652, 40652],
        employeeBenefitExpenses: [20393, 20393, 20393, 20393, 20393, 20393, 20393],
        technicalSubContractors: [2729, 2729, 2729, 2729, 2729, 2729, 2729],
        travelExpenses: [471, 471, 471, 471, 471, 471, 471],
        softwarePackages: [3687, 3687, 3687, 3687, 3687, 3687, 3687],
        communicationExpenses: [147, 147, 147, 147, 147, 147, 147],
        consultancyCharges: [489, 489, 489, 489, 489, 489, 489],
        depreciationExpenses: [1163, 1163, 1163, 1163, 1163, 1163, 1163],
        financeCost: [110, 110, 110, 110, 110, 110, 110],
        otherExpenses: [985, 985, 985, 985, 985, 985, 985],
        profitBeforeTax: [2729, 2729, 2729, 2729, 2729, 2729, 2729],
        totalTaxExpense: [40652, 40652, 40652, 40652, 40652, 40652, 40652],
        currentTax: [37923, 37923, 37923, 37923, 37923, 37923, 37923],
        deferredTax: [2729, 2729, 2729, 2729, 2729, 2729, 2729],
        profitForPeriod: [2729, 2729, 2729, 2729, 2729, 2729, 2729],
        earningsPerShare: [6, 7, 6, 15, 13, 12, 11]
    };

    return (
        <table className="financial-table">
            <thead className='table-header'>
                <tr>
                    <td></td>
                    <td colSpan={7} style={{ borderBottom: "#777777 2px solid" }}>Quarter Ended</td>
                </tr>
                <tr>
                    <td className='header-title'>Particulars</td>
                    <td className='year-cell' colSpan={2}>2022</td>
                    <td colSpan={4} className='year-cell'>2023</td>
                    <td colSpan={1} className='year-cell'>2024</td>
                </tr>
                <tr>
                    <td></td>
                    <td className='month-cell'>Sep</td>
                    <td className='month-cell'>Dec</td>
                    <td className='month-cell'>Mar</td>
                    <td className='month-cell'>Jun</td>
                    <td className='month-cell'>Sep</td>
                    <td className='month-cell'>Dec</td>
                    <td className='month-cell'>Mar</td>
                </tr>
            </thead>
            <tbody>
                <tr className='main-row'>
                    <td className='main-row-title'>Total Income</td>
                    <td className="main-row-cell">40,652</td>
                    <td className="main-row-cell">40,652</td>
                    <td className="main-row-cell">40,652</td>
                    <td className="main-row-cell">40,652</td>
                    <td className="main-row-cell">40,652</td>
                    <td className="main-row-cell">40,652</td>
                    <td className="main-row-cell">40,652</td>
                </tr>
                <tr className='sub-row'>
                    <td className='sub-row-title'>Revenue From Operations</td>
                    <td className='sub-row-cell'>37,923</td>
                    <td className='sub-row-cell'>37,923</td>
                    <td className='sub-row-cell'>37,923</td>
                    <td className='sub-row-cell'>37,923</td>
                    <td className='sub-row-cell'>37,923</td>
                    <td className='sub-row-cell'>37,923</td>
                    <td className='sub-row-cell'>37,923</td>
                </tr>
            </tbody>
        </table>
    );
};

const IncomeStatementTable = () => {
    return (
        <div className='income-table-container'>
            <div className="income-table-header">
                <h1>Consolidated Statement of Operations</h1>
                <p>( figures in ₹ crores, except per equity share data )</p>
            </div>
            <div>
                <FinancialTable />
            </div>
        </div>
    )
}

export default IncomeStatementTable;