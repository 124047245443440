import React, { useEffect, useRef, useState } from 'react';
import '../styles/Home.css';
import Fuse from 'fuse.js';
import companiesData from '../data/companies.json';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { stockActions } from '../redux/features/stock/stockSlice';
import { IoMdSearch } from "react-icons/io";
import stockDetailsData from "../data/bse_stocks_website_urls.json";
import ComingSoon from '../components/Home/ComingSoon/ComingSoon';

const fuse = new Fuse(companiesData, {
    includeScore: true,
    threshold: 0.4,
    keys: ['companyName', 'bseScripCode', 'nseCode', 'meta']
});

const Home = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const searchRef = useRef();
    const [searchResults, setSearchResults] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [searchedCompany, setSearchedCompany] = useState(null);
    const [comingsoon, setComingsoon] = useState(true);

    useEffect(() => {
        if (inputValue) {
            searchRef.current.classList.remove('stock-search-glow');
            // setComingsoon(false);
        } else {
            searchRef.current.classList.add('stock-search-glow');
            // setComingsoon(true);
        }
    }, [inputValue]);

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    useEffect(() => {
        if (searchResults?.length === 0) {
            const searchBox = document.querySelector('.stock-search input');
            searchBox.style.borderRadius = '30px';
            searchBox.style.borderBottom = 'none';
            const searchResultsContainer = document.querySelector('.search-results');
            searchResultsContainer.style.display = 'none';
            const searchContainer = document.querySelector('.stock-search');
            searchContainer.style.borderRadius = '30px';
        }
    }, [searchResults]);

    const handleSearch = (event) => {
        const query = event.target.value;
        setInputValue(query);
        const results = query ? fuse.search(query).map((result) => result.item) : [];
        setSearchResults(results);
        const searchContainer = document.querySelector('.stock-search');
        const searchBox = document.querySelector('.stock-search input');
        const searchResultsContainer = document.querySelector('.search-results');
        if (query) {
            searchBox.style.borderRadius = '30px 30px 0px 0px';
            searchContainer.style.borderRadius = '30px 30px 0px 0px';
            searchResultsContainer.style.display = 'flex';
            searchBox.style.borderBottom = '#777 1px solid';
        } else {
            searchBox.style.borderRadius = '30px';
            searchContainer.style.borderRadius = '30px';
            searchResultsContainer.style.display = 'none';
            searchBox.style.borderBottom = 'none';
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Tab' && searchResults.length > 0) {
            e.preventDefault();
            setInputValue(searchResults[0].companyName);
            setSearchedCompany(searchResults[0]);
            setSearchResults([]);
            const searchBox = document.querySelector('.stock-search input');
            searchBox.style.borderRadius = '30px';
            searchBox.style.borderBottom = 'none';
            const searchResultsContainer = document.querySelector('.search-results');
            searchResultsContainer.style.display = 'none';
            const searchContainer = document.querySelector('.stock-search');
            searchContainer.style.borderRadius = '30px';
        } else if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
            e.preventDefault();
            const topFiveResults = searchResults.slice(0, 5); // Get the top five search results
            const currentIndex = topFiveResults.findIndex(result => result.companyName === inputValue);
            let newIndex;
            const totalResults = topFiveResults.length;
            if (e.key === 'ArrowDown') {
                newIndex = currentIndex === totalResults - 1 ? 0 : currentIndex + 1;
            } else {
                newIndex = currentIndex === 0 ? totalResults - 1 : currentIndex - 1;
            }
            setInputValue(topFiveResults[newIndex].companyName);
            setSearchedCompany(topFiveResults[newIndex]);
            const results = document.querySelectorAll('.search-result');
            results.forEach((item, i) => {
                item.classList[i === newIndex ? 'add' : 'remove']('search-result-active');
            });
        } else if (e.key === 'Enter' && searchedCompany) {
            const stockDetails = stockDetailsData[searchedCompany.nseCode];
            dispatch(stockActions.setStock({ code: stockDetails.scrip_code, stockName: searchedCompany.nseCode, stockLogo: stockDetails.logo, stockWebsite: stockDetails.website_url }));
            navigate(`/app/stock/${searchedCompany.nseCode}`);
        } else if (e.key === 'Escape') {
            e.preventDefault();
            setSearchResults([]);
        }
    };

    const handleResultClick = (e) => {
        const companyName = e.target.innerHTML;
        const clickedCompany = searchResults.find(result => result.companyName === companyName);
        setInputValue(companyName);
        setSearchedCompany(clickedCompany);
        const stockDetails = stockDetailsData[clickedCompany.nseCode];
        dispatch(stockActions.setStock({ code: stockDetails.scrip_code, stockName: clickedCompany.nseCode, stockLogo: stockDetails.logo, stockWebsite: stockDetails.website_url }));
        navigate(`/app/stock/${clickedCompany.nseCode}`);
    };

    const handleCancel = () => {
        setComingsoon(false);
    }

    return (
        <div className='home-page-section'>
            <div className='home-container'>
                <div className='home-brand-logo'>
                    <img src="/assets/svgs/MarketrackHomeLogo.svg" alt="" />
                </div>
                <div className="stock-search-container">
                    <div className="glow-anim-box"></div>
                    <div ref={searchRef} className="stock-search stock-search-glow">
                        <div className='search-icon'>
                            <IoMdSearch />
                        </div>
                        <input
                            type="search"
                            value={inputValue}
                            placeholder='Search Sensex 30 Stocks'
                            onChange={handleSearch}
                            onKeyDown={handleKeyDown}
                            autoComplete='off'
                        />
                    </div>
                    <div className='search-results'>
                        {searchResults.slice(0, 5).map((result, index) => (
                            <div className='search-result' key={index}>
                                <div className='search-result-title' onClick={(e) => handleResultClick(e)}>
                                    {result.companyName}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {comingsoon && <ComingSoon handleCancel={handleCancel} />}
            </div>
        </div>
    );
};

export default Home;