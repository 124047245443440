import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import stockLogos from './../../../data/stockLogos.json';
import TextLoader from '../../ui/TextLoader';

const StockBar = () => {
    const { stockName, stockPrice, stockChange, stockChangePercent } = useSelector(state => state.stock);

    const [showSidebar, setshowSidebar] = useState(false);

    const color = stockChange > 0 ? "#66FF00" : stockChange < 0 ? "#FF2E2E" : "#AAAAAA";

    const handleShowSidebar = () => {
        const element = document.querySelector('.side-navbar');
        if (showSidebar) {
            element.style.height = '62px';
            element.style.border = 'none';
        }
        else {
            element.style.height = '520px';
            element.style.border = 'solid 1px #444444';
            element.style.borderTop = 'none';
        }
        setshowSidebar(prev => !prev);
    }

    return (
        <div className='stock-bar'>
            <div onClick={handleShowSidebar} className="hamburger-menu">
                <img src="/assets/svgs/hamburger.svg" alt="" />
            </div>
            <img className='stockbar-logo' src={stockLogos[stockName]?.logo.url} alt="" />
            <div className='stock-info' style={{ color: color }}>
                <div className='flex-col' style={{ gap: "10px" }}>
                    <span>{stockName}</span>
                    <span>{stockPrice || <TextLoader width={'70px'} height={'16px'} />}</span>
                </div>
                <div className='flex-col' style={{ alignItems: "flex-end", gap: "10px" }}>
                    {
                        stockChangePercent === null ?
                            <TextLoader width={'60px'} height={'16px'} />
                            :
                            <span><span style={{ marginRight: '3px' }}>{stockChangePercent > 0 ? '+' : ''}</span>{stockChangePercent?.toFixed(2)}%</span>
                    }
                    {
                        stockChange === null ?
                            <TextLoader width={'70px'} height={'16px'} />
                            :
                            <span><span style={{ marginRight: '3px' }}>{stockChangePercent > 0 ? '+' : ''}</span>{stockChange?.toFixed(2)}<span style={{ opacity: 0 }}>%</span></span>
                    }
                </div>
            </div>
        </div>
    );
};

export default StockBar;