import React, { useState, useEffect } from 'react';
import './BlogPage.css';
import AboutFooter from './AboutFooter';
import { IoIosArrowDown } from "react-icons/io";
import { Tooltip } from 'react-tooltip';
import SocialShare from './SocialShare';
import { MdOutlineIosShare } from "react-icons/md";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { useNavigate } from 'react-router-dom';
import ImageModal from './ImageModal';

const BlogPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();

    const [accOpen, setAccOpen] = useState();
    const handleAccToggle = (index) => {
        setAccOpen(prevVal => (index === prevVal ? null : index));
    }

    const [shareDialog, setShareDialog] = useState(false);

    const handleBlogClose = () => {
        navigate('/about');
    }

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <ImageModal open={open} handleClose={handleClose} imgUrl={"/assets/images/blog-image.png"} />
            <div className='blog-page-container'>
                <div className="blog-page">
                    <button className="blog-close-button" onClick={handleBlogClose}>
                        <HiOutlineArrowLeft />
                    </button>
                    <div className="blog-header">
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <h1 className="blog-title">Announcing Marketrack</h1>
                            <h1 className="blog-title">Revolutionizing Retail Investing</h1>
                        </div>
                        <div className='blog-header-detail'>
                            <span>June 9, 2024 • 3 Min Read • </span>
                            <span style={{ position: 'relative' }}>
                                <span className='share-button' onClick={() => setShareDialog(true)}>Share <MdOutlineIosShare style={{ marginBottom: '5px' }} /></span>
                                {shareDialog === true && <SocialShare handleShareDialogClose={() => setShareDialog(false)} />}
                            </span>
                        </div>
                        <div className="team-container">
                            <img style={{ zIndex: 8 }} src="/assets/images/team-images/Shreyas.png" alt="" data-tooltip-id='team-img-tooltip' data-tooltip-place="top" data-tooltip-html='<p>Shreyas</p><p>Co-Founder</p>' />
                            <img style={{ zIndex: 7 }} src="/assets/images/team-images/Prabal.png" alt="" data-tooltip-id='team-img-tooltip' data-tooltip-place="top" data-tooltip-html='<p>Prabal</p><p>Co-Founder</p>' />
                            {/* <img style={{ zIndex: 6 }} src="/assets/images/team-images/Jeetesh.png" alt="" data-tooltip-id='team-img-tooltip' data-tooltip-place="top" data-tooltip-html='<p>Jeetesh</p><p>DevOps Head</p>' /> */}
                            <img style={{ zIndex: 5 }} src="/assets/images/team-images/Pradyumna.png" alt="" data-tooltip-id='team-img-tooltip' data-tooltip-place="top" data-tooltip-html='<p>Pradyumna</p><p>CFO</p>' />
                            <img style={{ zIndex: 4 }} src="/assets/images/team-images/Mohit.png" alt="" data-tooltip-id='team-img-tooltip' data-tooltip-place="top" data-tooltip-html='<p>Mohit</p><p>FrontEnd Head</p>' />
                            <img style={{ zIndex: 3 }} src="/assets/images/team-images/Saketh.png" alt="" data-tooltip-id='team-img-tooltip' data-tooltip-place="top" data-tooltip-html='<p>Saketh</p><p>SDE</p>' />
                            <img style={{ zIndex: 2 }} src="/assets/images/team-images/Krishna.png" alt="" data-tooltip-id='team-img-tooltip' data-tooltip-place="top" data-tooltip-html='<p>Krishna</p><p>SDE</p>' />
                            <img style={{ zIndex: 1 }} src="/assets/images/team-images/Tanuj.png" alt="" data-tooltip-id='team-img-tooltip' data-tooltip-place="top" data-tooltip-html='<p>Tanuj</p><p>Mobile Head</p>' />
                        </div>
                        <Tooltip id="team-img-tooltip" />
                    </div>
                    <div className="blog-main-img" />
                    {/* <img src="/assets/gifs/blog-animation.gif" alt="" /> */}
                    <div className="blog-content">
                        <h2>Today, we are thrilled to announce Marketrack, a revolutionary, first-of-its-kind platform designed to transform the world of retail investing!</h2>
                        <p>We all aspire and want to invest for our better financial future, don't we? However, navigating the world of investments can be quite the challenge, with its complexities, constant monitoring, and baffling financial jargon. There's a constant feeling of being left behind as you wonder how others—both individuals and institutions—are seemingly making money effortlessly, leaving you frustrated.</p>
                        <p><em>Picture this: your weekdays are consumed by work, and your weekends are dedicated to decoding financial reports, performing valuation and forensic analyses, and carefully reading through voluminous annual reports and conference calls instead of enjoying quality time with family.</em></p>
                        <p>Sounds exhausting and unappealing, right? Unfortunately, that's the reality for many retail investors who strive to keep up with the demands of investment management. For too long, retail investors have been at a disadvantage, lacking the resources and expertise of their professional and institutional counterparts. </p>
                        <p><b>You might be wondering, isn't this why mutual funds exist?</b> The truth is, while mutual funds offer some advantages, they too are plagued by the same fundamental problems (lack of time and sheer complexity) and fall short. Just like individual stock investments, investors still need to constantly track and monitor their funds for fund managers' choices, portfolio changes, and decipher them.</p>
                        <p>With thousands of funds out there, choosing the right one isn't simple and is a challenge in and of itself. This often leads to choice paralysis, or picking those with hidden/higher fees, and a lack of confidence in the investment decisions made. And lastly, the fees eat into a significant portion of your returns over time (Compounding!).</p>
                        <p><b>Trust is crucial in finance, but can we always trust and depend on the fund managers?</b></p>
                        <p>Our own experiences led us to realize these challenges and to build a solution that tracks the markets for you, rather than the other way around. Our goal is to build an autopilot/copilot for the capital markets, empowering retail investors and leveling the playing field between those equipped with time, tools, resources, and expertise, and those without; to build a product that simplifies the complexities of investing and makes it accessible to everyone to benefit from the markets.</p>
                        <img src="/assets/images/blog-image.png" alt="" onClick={handleOpen} />
                        <p>Now let&apos;s talk about how we intend to achieve this goal:</p>
                        <div className='blog-sub-content'>
                            <h1>Marketrack: Tracks the market for you!</h1>
                            <p>Marketrack is designed to simplify investment management, making it accessible, automated, and personalized for everyone. With just one click, you can transform how you invest.</p>
                            <h2>How It Would Work:</h2>
                            <ul>
                                <li style={{ marginLeft: '30px' }}><h2>Add Your Preferred Stocks or Mutual Funds</h2></li>
                                <li style={{ marginLeft: '50px' }}><p>You choose the stocks or mutual funds you're interested in (or discover those that suit your risk-return profile ,preferences via specially crafted screeners or build your own easily) and our system takes over.</p></li>
                            </ul>
                            <div className="special-features">
                                <h2>Magic Under the hood <img src="/assets/svgs/wand.svg" alt="" /></h2>
                                <div className="blog-subcontent-acc">
                                    <div className='acc-trigger'>
                                        <p><b>1. Data Engine :</b> Works 24x7 and Gathers Data Endlessly from Various Sources</p>
                                        <IoIosArrowDown style={{ transform: (accOpen !== 1) ? '' : 'rotate(180deg)' }} />
                                        <input type="checkbox" name="" value="" onChange={() => handleAccToggle(1)} />
                                    </div>
                                    <div className={`blog-subcontent-acc-point ${accOpen === 1 && 'active'}`} >
                                        <ul style={{ fontFamily: 'AlbertSans-Italic', color: '#8E8E8E', paddingTop: '20px' }}>
                                            <li>Company Data - KPIs, Annual/Quarterly Reports,MGT-7, Corporate Filings, Concalls...</li>
                                            <li>Mutual Fund Factsheets</li>
                                            <li>Various Govt Data :  Air Traffic, GST Collections, RBI, SEBI..</li>
                                            <li>Alternate Data: AMFI, Cll, Intl Raw Materials & End Products Prices, Trade & Forex Data, News, Social Media, Interviews & Sentiment
                                                and so on</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="blog-subcontent-acc">
                                    <div className='acc-trigger'>
                                        <p><b>2. Monitoring & Analysis Engine :</b> Our AI & Algorithms digest & analyze all the data</p>
                                        <IoIosArrowDown style={{ transform: (accOpen !== 2) ? '' : 'rotate(180deg)' }} />
                                        <input type="checkbox" name="" value="" onChange={() => handleAccToggle(2)} />
                                    </div>
                                    <div className={`blog-subcontent-acc-point ${accOpen === 2 && 'active'}`} >
                                        <ul style={{ listStyleType: 'lower-alpha', fontFamily: 'AlbertSans-Regular', color: '#AAA', paddingTop: '20px' }}>
                                            <li>Runs Specific Algorithms for Checks <em>(Some Examples below)</em> : </li>
                                            <ul>
                                                <li style={{ paddingTop: '20px' }}>Forensic Accounting & Red Flags Scrutiny such as:</li>
                                                <ul style={{ color: '#8E8E8E', fontFamily: 'AlbertSans-Italic' }}>
                                                    <li>Auditor/CFO Resignations</li>
                                                    <li>Changes in Accounting Policy or Unusual Revenue Recognition Practices</li>
                                                </ul>
                                                <li style={{ paddingTop: '20px' }}>Corporate Governance Analysis such as:</li>
                                                <ul style={{ color: '#8E8E8E', fontFamily: 'AlbertSans-Italic' }}>
                                                    <li>Pending lawsuits, environmental violations,</li>
                                                    <li>Criminal cases against promoters/CXOs</li>
                                                    <li>Compliance with all the SEBI LODR requirements & other regulations</li>
                                                    <li>Delayed Financial Reporting</li>
                                                </ul>
                                                <li style={{ paddingTop: '20px' }}>Valuation Analysis such as</li>
                                                <ul style={{ color: '#8E8E8E', fontFamily: 'AlbertSans-Italic' }}>
                                                    <li>Scenario Analysis</li>
                                                    <li>DCF</li>
                                                </ul>
                                                <li style={{ paddingTop: '20px' }}>Risk Assessment & Management</li>
                                                <li style={{ paddingTop: '20px' }}>Market Trends & Sentiment Analysis</li>
                                                <li style={{ paddingTop: '20px' }}>KPI Monitoring and so on</li>
                                            </ul>
                                            <li style={{ paddingTop: '20px' }}>Our Algorithms digest & analyze all the data from the data engine</li>
                                            <li style={{ paddingTop: '20px' }}>Simplified for everyone: Insights presented in 3 modes in addition to Grade Sheet Checklists for results of the above algorithms</li>
                                            <ul style={{ listStyleType: 'decimal', paddingTop: '20px' }}>
                                                <li>ELI5 - <em>(Explain Like I'm Five)</em> — For those who crave simplicity</li>
                                                <li>Balanced - For a comprehensive yet straightforward view</li>
                                                <li>Professional - for nerds :P</li>
                                            </ul>
                                        </ul>
                                    </div>
                                </div>
                                <div className="blog-subcontent-acc">
                                    <div className='acc-trigger'>
                                        <p><b>3. Notification & Awareness Engine : </b>
                                            Determines When & How to Inform you Based on Engine's Inputs & your Preferences.
                                        </p>
                                        <IoIosArrowDown style={{ transform: (accOpen !== 3) ? '' : 'rotate(180deg)' }} />
                                        <input type="checkbox" name="" value="" onChange={() => handleAccToggle(3)} />
                                    </div>
                                    <div className={`blog-subcontent-acc-point ${accOpen === 3 && 'active'}`} >
                                        <ul style={{ listStyleType: 'disc', fontFamily: 'AlbertSans-Regular', color: '#AAA', paddingTop: '20px' }}>
                                            <li>Notifies you only when required thereby reducing information overload.</li>
                                            <li>All notifications and insights are tailored to the your chosen mode (EL15/Balanced/Pro) and are delivered via your preferred channels (Mobile App, WhatsApp, SMS, Email, Web) and in alignment with your timing preferences.</li>
                                            <ul style={{ listStyleType: 'lower-roman', fontFamily: 'AlbertSans-Regular', color: '#AAA', paddingTop: '20px' }}>
                                                <li>Real Time for Important & Materially Impactful News During Market Hours such as:</li>
                                                <ul style={{ listStyleType: 'disc', fontFamily: 'AlbertSans-Italic', color: '#AAA', paddingTop: '20px' }}>
                                                    <li>Legal Proceedings Against Key Personnel</li>
                                                    <li>Product Recalls or Quality Issues</li>
                                                    <li>Govt Policy Changes</li>
                                                    <li>Earnings / M&A Announcements</li>
                                                    <li>Leadership Changes or Resignations</li>
                                                    <li>Cybersecurity Breaches</li>
                                                    <li>Regulatory Investigations or Violations.</li>
                                                </ul>
                                                <li style={{ paddingTop: '20px' }}>End of Day/Week Updates for the rest</li>
                                                <ul style={{ listStyleType: 'disc', fontFamily: 'AlbertSans-Italic', color: '#AAA', paddingTop: '20px' }}>
                                                    <li>(Non Market Hours / Non Material News During Market Hours)</li>
                                                </ul>
                                            </ul>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p><b>Marketrack</b>, our brainchild, represents the next evolution of retail investing—smarter, more accessible, and tailored to your needs. </p>
                        <p>As we embark on this journey, we invite all who share our vision for a more accessible financial landscape to join us. </p>
                        <p>In the words of <em>Steve Jobs, 'The people who are crazy enough to think they can change the world are the ones who do,' and Jeff Bezos, 'What's dangerous is not to evolve.</em>'</p>
                        <p>If you would like to contribute towards building this product, please do so, either as an investor or as a builder. </p>
                        <p>We are bootstrapped and building this project right out of college with a very small team—all except one are our dorm mates. While we may not have the financial resources to offer traditional compensation, we would be more than happy to discuss other ways of making it worth your while. </p>
                        <p>Please reach out to us via email at <a href="mailto:social@marketrack.in">social@marketrack.in</a> and let's shape the future of investing together.</p>
                    </div>
                </div>
                <AboutFooter />
            </div>
        </>
    )
}

export default BlogPage;