import React, { useEffect, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from '../redux/features/auth/authSlice';
import { userLogin } from '../redux/features/auth/authService';
import '../styles/Register.css';
import { FcGoogle } from "react-icons/fc";
// import { FaApple } from "react-icons/fa";
import { FiLock } from "react-icons/fi";
import { toast } from 'react-toastify';

const Register = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    const [formData, setFormData] = useState({
        email: "",
        password: '',
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const lastLocation = useSelector(state => state.auth.lastLocation);
    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            dispatch(authActions.setLoading());
            dispatch(userLogin(codeResponse.access_token));
            navigate(lastLocation);
            toast.success('Successfully registered');
        },
        onError: (error) => {
            dispatch(authActions.setError(error));
            toast.error(error.message);
        }
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.id]: e.target.value });
    }
    const handleFormSubmit = () => {

    }

    return (
        <div className='register-page'>
            <div className='register-container'>
                <p className='register-heading'>Create your account</p>
                <form onSubmit={handleFormSubmit} className="register-form">
                    <div className="mobile-input">
                        <label htmlFor='email'>Email</label>
                        <input id='email' type="email" autoComplete="true" required onChange={handleChange} value={formData.email} />
                    </div>
                    <div className="mobile-input">
                        <label htmlFor='password'>Password</label>
                        <input id='password' type="password" required onChange={handleChange} value={formData.password} />
                    </div>
                    <button type="submit" className='generate-otp-button'>Create Account</button>
                </form>
                <div className='have-account'>
                    <span style={{ opacity: '70%' }}>Already have an account?</span>
                    <Link to={'/login'} style={{ textDecoration: 'none', fontFamily: 'Montserrat-Medium', color: '#29B6F6' }}><span>Login</span></Link>
                </div>
                <div className='or-register-with'>
                    <hr />
                    <span>Or</span>
                    <hr />
                </div>
                <div className='auth-buttons-group'>
                    <button className='google-button social-auth' onClick={login}><FcGoogle size={'20px'} />Register & Continue with Google</button>
                    {/* <button className='apple-button social-auth'><FaApple size={'21px'} />Register & Login with Apple</button> */}
                </div>
                {/* <GoogleLogin onSuccess={responseMessage} onError={errorMessage} theme='filled_black' width={'250px'} text='continue_with'/> */}
                <div className='privacy-message'>
                    <span><FiLock size={'25px'} />Your privacy, 100% protected.</span>
                    <span><img src="/assets/images/noSpam.png" alt="" />No spam, ever. Our promise.</span>
                </div>
            </div>
        </div>
    )
}

export default Register;