import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div style={{ padding: '5vw', paddingTop: '2vw' }}>
            <h1>Privacy Policy</h1>
            <p>Last updated January 22, 2024</p>
            <p>This privacy notice for Dellarex Technologies Private Limited ('we', 'us', or 'our'), describes how and why we might collect, store, use, and/or share ('process') your information when you use our services ('Services'), such as when you:</p>
            <li>Visit our website at <a href="https://www.marketrack.in" target='blank'>https://www.marketrack.in</a>, or any website of ours that links to this privacy notice</li>
            <li>Download and use our mobile application (Marketrack), or any other application of ours that links to this privacy notice</li>
            <li>Engage with us in other related ways, including any sales, marketing, or events Questions or concerns? Reading this privacy notice will help you understand your privacy choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at support@marketrack.in.</li>
            <h2>Summary Of Key Points</h2>
            <p>This summary offers key insights from our privacy policy.</p>
            <h4>1. What Personal Information Do We Process?</h4>
            <p>- When you visit, use, or navigate our Services, we process personal information based on
your interactions, choices, and product or feature usage. Learn more about the personal
information you disclose to us.</p>
            <h4>2. Do We Process Sensitive Personal Information?</h4>
            <p>- Sensitive personal information may be processed when necessary with your consent or
as permitted by applicable law. Explore more about the handling of sensitive information.</p>
<h4>3. Information from Third Parties</h4>
<p>- We do not receive any information from third parties.</p>
<h4>4. How Do We Process Your Information?</h4>
<p>- Your information is processed for providing, improving, and administering our Services,
communicating with you, ensuring security and fraud prevention, and complying with the law.
Learn more about the detailed process of how we handle your information.</p>
<h4>5. Situations and Parties for Sharing Personal Information</h4>
<p>- We may share information in specific situations and with specific third parties. Gain
insights into when and with whom we share your personal information.</p>
<h4>6. How Do We Keep Your Information Safe?</h4>
<p>- Organizational and technical processes and procedures are in place to protect your personal information. While we strive for security, we cannot guarantee 100% protection against unauthorized access. Learn more about our information security measures.</p>
<h4>7. Exercising Your Rights</h4>
<p>- The easiest way to exercise your rights is by submitting a data subject access request or by contacting us. Requests will be considered and acted upon in accordance with applicable data protection laws.</p>
<p>Feel free to review the complete privacy notice for a comprehensive understanding of what we do with the information we collect.</p>








Privacy Policy
Summary Of Key Points
Personal Information Disclosure
Personal Information Provided by You:
Information Automatically Collected
How We Process Your Information
Legal Bases For Processing Your Information
Sharing Your Personal Information: When And With Whom
Our Stance On Third-Party Websites
How Do We Handle Your Social Logins?
How Long Do We Keep Your Information
How We Keep Your Information Safe
Collection Of Information From Minors
Withdrawal Of Consent
Opting Out Of Marketing Communications:
Account Information:
Controls For Do-Not-Track Features
Do We Make Updates To This Notice?
How Can You Contact Us About This Notice?
How Can You Review, Update, Or Delete The Data We Collect From You?

Personal Information Disclosure
In short: Collecting personal information provided by you.
We collect personal information that you voluntarily provide to us when you register on the
Services, express an interest in obtaining information about us or our products and services,
participate in activities on the Services, or contact us in any other manner.
Personal Information Provided by You:
The personal information we collect depends on the context of your interactions with us and
the Services, the choices you make, and the products and features you use. This may
include, but is not limited to, the following:
- Names
- Email addresses
- Phone numbers
- Mailing addresses
- Usernames
- Passwords
Sensitive Information:
When necessary, with your consent or as otherwise permitted by applicable law, we may
process the following categories of sensitive information:
- Financial data
Social Media Login Data:
If you choose to register with us using your existing social media account details, such as
Facebook, Twitter, or other social media accounts, we will collect the information described
in the section titled 'HOW DO WE HANDLE YOUR SOCIAL LOGINS?' below.
Application Data:
If you use our application(s), we may also collect the following information if you choose to
provide us with access or permission:
- Geolocation Information: We may request access to track location-based information from
your mobile device for certain location-based services.
- Mobile Device Access: We may request access to certain features from your mobile device,
including contacts, camera, calendar, reminders, SMS messages, and other features.
- Mobile Device Data: We automatically collect device information, operating system details,
application identification numbers, browser type and version, hardware model, Internet
service provider, mobile carrier, and IP address.
Push Notifications:
We may request to send push notifications regarding your account or certain features of the
application(s). If you wish to opt out, you may do so in your device's settings.
This information is primarily needed to maintain the security and operation of our
application(s), for troubleshooting, and for internal analytics and reporting purposes.
All personal information provided must be true, complete, and accurate, and any changes to
such information must be promptly notified to us.
Information Automatically Collected
In short: We automatically collect certain information, such as your Internet Protocol (IP)
address and/or browser and device characteristics, when you visit our Services.
We automatically gather specific information during your visit, use, or navigation of the
Services. While this information does not disclose your specific identity, such as your name
or contact details, it encompasses device and usage data crucial for maintaining the security
and operation of our Services and for internal analytics and reporting purposes.
The information we collect includes:
1. Log and Usage Data:
- Service-related, diagnostic, usage, and performance information automatically collected
by our servers when you access or use our Services. This log data may include your IP
address, device information, browser type and settings, activity timestamps, pages and files
viewed, searches, and other actions taken within the Services. It also encompasses device
event information, such as system activity, error reports (sometimes referred to as 'crash
dumps'), and hardware settings.
2. Device Data:
- Information about your computer, phone, tablet, or other device used to access the
Services. Depending on the device, this data may include your IP address (or proxy server),
device and application identification numbers, location, browser type, hardware model,
Internet service provider and/or mobile carrier, operating system, and system configuration
information.
3. Location Data:
- Information about your device's location, which can be either precise or imprecise. The
amount of information collected depends on the type and settings of the device. For
instance, we may use GPS and other technologies for geolocation data based on your IP
address . You can opt out of this information collection by either refusing access or disabling
the Location setting on your device. However, opting out may limit your ability to use certain
aspects of the Services.
This information is instrumental for the functionality and security of our Services, as well as
for internal analytical and reporting purposes.
How We Process Your Information
In short: We process your information to provide, improve, and administer our Services,
communicate with you, ensure security and fraud prevention, and comply with the law.
Additionally, we may process your information for other purposes with your consent.
We process your personal information for various reasons, depending on your interactions
with our Services. This includes:
1. Facilitate Account Creation and Authentication:
- Process your information to enable account creation, authentication, and overall
management of user accounts to ensure smooth functioning.
2. Deliver and Facilitate Services:
- Process your information to deliver and facilitate the requested services, ensuring a
seamless user experience.
3. Respond to User Inquiries/Provide Support:
- Process your information to address user inquiries and offer support, resolving any
potential issues related to the requested services.
4. Send Administrative Information:
- Process your information to send details about our products and services, updates to our
terms and policies, and other relevant administrative information.
5. Enable User-to-User Communications:
- Process your information if you choose to use features allowing communication with
other users.
6. Request Feedback:
- Process your information as necessary to request feedback and contact you about your
use of our Services.
7. Send Marketing and Promotional Communications:
- Process personal information for marketing purposes in accordance with your
preferences. You can opt out of marketing emails anytime
8. Deliver Targeted Advertising:
- Process your information to develop and display personalized content and advertising
tailored to your interests, location, and more.
9. Protect Our Services:
- Process your information as part of efforts to maintain the safety and security of our
Services, including fraud monitoring and prevention.
10. Identify Usage Trends:
- Process information about how you use our Services to gain insights into usage trends,
aiding in continuous improvement.
11. Save or Protect an Individual's Vital Interest:
- Process your information when necessary to save or protect an individual's vital interest,
such as preventing harm.
Legal Bases For Processing Your Information
In short: We process your personal information based on necessary functions, consent,
contractual obligations, protection of your rights, fulfillment of business interests, or
compliance with legal obligations.
The legal bases for processing your personal information are outlined below:
1. Consent:
- We process your information with your explicit consent for specific purposes. You can
withdraw your consent at any time.
2. Performance of a Contract:
- Your information is processed when necessary to fulfill our contractual obligations,
including providing requested services or fulfilling your requests before entering into a
contract.
3. Legitimate Interests:
- Information may be processed to achieve our legitimate business interests, provided they
do not override your interests, rights, and freedoms. For instance:
- Sending information about special offers and discounts
- Developing personalized advertising content
- Analyzing service usage for improvements
- Diagnosing problems and preventing fraudulent activities
- Understanding user behavior for product and service enhancement
4. Legal Obligations:
- Information is processed when necessary to comply with legal obligations, such as
cooperating with law enforcement, regulatory agencies, or fulfilling our legal responsibilities.
5. Vital Interests:
- Processing may occur to protect your vital interests or those of a third party, particularly in
situations involving potential threats to safety.
Withdrawal of Consent:
You can withdraw your consent at any time. However, there are exceptional cases where
processing may occur without consent as permitted by applicable law, such as:
- Clear interests of an individual and timely consent cannot be obtained
- Investigations and fraud prevention
- Business transactions under specified conditions
- Identifying and communicating with next of kin
- Cases where consent withdrawal would compromise data accuracy or is unreasonable for
investigative or legal purposes
Sharing Your Personal Information: When And With Whom
In short: We may share your information in specific situations outlined in this section and
with the following third parties.
We may share your personal information in the following scenarios:
1. Business Transfers:
- Your information may be shared or transferred in connection with mergers, sale of
company assets, financing, or acquisition of our business by another company.
2. Other Users:
- When you share personal information (e.g., posting comments or contributions) in public
areas of the Services, it may be viewed by all users and publicly available outside the
Services indefinitely. If you register through a social network, your contacts on that network
may see your name, profile photo, and activity descriptions.
3. Offer Wall:
- Our application(s) might display a third-party hosted 'offer wall,' allowing advertisers to
offer virtual currency, gifts, or other items in exchange for completing an advertisement offer.
Clicking on an offer wall will take you to an external website, and a unique identifier (like your
user ID) will be shared with the offer wall provider for fraud prevention and proper reward
crediting.
These sharing practices are aligned with our commitment to transparency and safeguarding
your privacy.
Our Stance On Third-Party Websites
In short: We are not responsible for the safety of any information you share with third parties
linked to or advertising on our Services but not affiliated with us.
The Services, including our offer wall, may feature links to third-party websites, online
services, or mobile applications, and/or display advertisements from third parties not
affiliated with us. These third parties may, in turn, link to other websites, services, or
applications. We do not provide any guarantee regarding these third parties, and we are not
liable for any loss or damage resulting from the use of such third-party websites, services, or
applications.
The inclusion of a link to a third-party website, service, or application does not imply our
endorsement. We cannot ensure the safety and privacy of data you share with third parties.
Data collected by third parties is not covered by this privacy notice. We bear no responsibility
for the content, privacy, and security practices of any third parties, including other websites,
services, or applications linked to or from the Services. For information about the policies of
such third parties, and to address any questions, please review their policies and contact
them directly.
How Do We Handle Your Social Logins?
*In Short:* If you choose to register or log in to our Services using a social media account,
we may have access to certain information about you.
Our Services provide the option to register and log in using your third-party social media
account details (such as Facebook or Twitter logins). By opting for this method, we will
receive specific profile information from your social media provider. The received information
may include your name, email address, friends list, profile picture, and other details you
choose to make public on the respective social media platform.
The information obtained will only be used for the purposes described in this privacy notice
or those explicitly communicated to you on the relevant Services. It's important to note that
we do not control or take responsibility for additional uses of your personal information by
your third-party social media provider. We recommend reviewing their privacy notice to
understand how they collect, use, and share your personal information, as well as to
manage your privacy preferences on their sites and apps.
How Long Do We Keep Your Information
In short: We retain your information for the necessary duration to fulfill the purposes outlined
in this privacy notice, unless mandated by law.
We will retain your personal information only for as long as required to achieve the purposes
specified in this privacy notice, unless a more extended retention period is necessary or
permitted by law, such as for tax, accounting, or legal requirements. No purpose in this
notice warrants retaining your personal information beyond twelve (12) months from the
commencement of the user account's idle period.
Once there is no ongoing legitimate business need to process your personal information, we
will take the following actions:
- Delete or anonymize the information.
- If deletion or anonymization is not feasible (e.g., data stored in backup archives), securely
store and isolate your personal information from further processing until deletion becomes
possible.
Our commitment is to safeguard your data and ensure its appropriate handling throughout its
lifecycle.
How We Keep Your Information Safe
In short: We employ organizational and technical security measures to safeguard your
personal information.
We have implemented reasonable and appropriate technical and organizational security
measures to ensure the protection of the personal information we process. Despite our
diligent efforts, it's crucial to note that no electronic transmission over the Internet or storage
technology can be guaranteed 100% secure. Therefore, we cannot assure or guarantee that
hackers, cybercriminals, or unauthorized third parties won't potentially breach our security,
gaining improper access to, stealing, or modifying your information.
While we strive to provide the utmost protection for your personal information, the
transmission of such information to and from our Services carries inherent risks. It is
important to acknowledge that accessing the Services is at your own risk. For enhanced
security, we recommend accessing the Services within a secure environment.
Our commitment is to continually improve and adapt our security measures to address
evolving threats and ensure the confidentiality and integrity of your information.
Collection Of Information From Minors
In short: We do not knowingly collect or market to children under 18 years of age.
We do not intentionally seek data from or market to individuals under 18 years of age. By
using the Services, you confirm that you are at least 18 years old or that you are the parent
or guardian of a minor, and you consent to the minor's use of the Services. If we discover
that personal information has been collected from users under 18 years of age, we will
deactivate the account and take reasonable steps to promptly delete such data from our
records. If you are aware of any data we may have collected from children under the age of
18, please contact us at support@marketrack.in.
Withdrawal Of Consent
If we are relying on your consent to process your personal information, whether express or
implied, depending on applicable law, you have the right to withdraw your consent at any
time. Withdraw your consent by contacting us through the details provided in the section
'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below or by updating your
preferences.
Please note that withdrawing consent will not affect the lawfulness of processing before
withdrawal. When applicable law allows, it will not impact processing based on lawful
grounds other than consent.
Opting Out Of Marketing Communications:
You can unsubscribe from our marketing and promotional communications anytime. Click the
unsubscribe link in our emails, go to Account/Profile Settings, click unsubscribe, or contact
us through the details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS
NOTICE?' below. While you will be removed from marketing lists, we may still contact you for
service-related messages necessary for account administration, responding to service
requests, or other non-marketing purposes.
Account Information:
If you wish to review, modify, or terminate your account:
- Log in to your account settings to update your user account.
- Contact us using the provided contact information.
Upon requesting account termination, we will deactivate or delete your account and
information from our active databases. Some data may be retained to prevent fraud,
troubleshoot problems, assist in investigations, enforce legal terms, or comply with
applicable legal requirements.
For questions or comments regarding your privacy rights, email us at
support@marketrack.in.
Controls For Do-Not-Track Features
Many web browsers, as well as certain mobile operating systems and mobile applications,
provide a Do-Not-Track ('DNT') feature or setting. Activating this feature signals your
preference for privacy, indicating that you do not wish to have data about your online
browsing activities monitored and collected. It's important to note that there is currently no
uniform technology standard for recognizing and implementing DNT signals, and no final
consensus has been reached.
At present, we do not respond to DNT browser signals or any other mechanism that
automatically conveys your choice not to be tracked online. Should a standard for online
tracking be adopted in the future that we are obligated to follow, we will notify you of this
practice in a revised version of this privacy notice.
Do We Make Updates To This Notice?
In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.
We may update this privacy notice periodically. The updated version will be marked with a
revised date and becomes effective as soon as it is accessible. In the event of material
changes, we may notify you by prominently posting a notice or sending you a direct
notification. We encourage you to review this privacy notice regularly to stay informed about
how we protect your information.
How Can You Contact Us About This Notice?
If you have questions or comments about this notice, you may email us at
support@marketrack.in or contact us by post at:
*Dellarex Technologies Private Limited*
D.NO 2 ,ANTHAPUR COLONY LAST ROAD ,NEAR MMTC COLONY
SATYANARAYANAPET BELLARY KA 583101 IN
How Can You Review, Update, Or Delete The Data We Collect
From You?
Depending on the applicable laws of your country, you may have the right to request access
to the personal information we collect from you, make changes to that information, or delete
it. To request a review, update, or deletion of your personal information, please fill out and
submit a data subject access request.
        
        </div>
    );
};

export default PrivacyPolicy;