import React from 'react';
import { IoIosArrowForward } from "react-icons/io";


const ChecklistItem = () => {
    return (
        <div className="checklist-items-check">
            <div className="check-desc">
                <p>31. Does the company recognize revenue only after it is realized?</p>
                <ul>
                    <li>There is evidence that the company has been recognizing revenue from unfulfilled contracts.</li>
                </ul>
            </div>
            <div className="check-more">
                <div className="check-status">Fail</div>
                <button>View Details <IoIosArrowForward style={{scale: '1.1'}}/></button>
            </div>
        </div>
    )
}

export default ChecklistItem;