import React, { useEffect, useLayoutEffect } from 'react'
import Overview from '../components/ProductPage/Overview/Overview';
import Chart from '../components/ProductPage/Charts/Chart';
import Shareholding from '../components/ProductPage/Shareholding/Shareholding';
import SideBar from '../components/ProductPage/SideBar/SideBar';
import Financials from '../components/ProductPage/Financials/Financials';
// import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { stockActions } from '../redux/features/stock/stockSlice';
// import brandFetchData from '../data/bse_stocks_brandfetch.json';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/all';
import Forensic from '../components/ProductPage/Forensic/Forensic';
import Documents from '../components/ProductPage/Documents/Documents';

gsap.registerPlugin(ScrollTrigger);
const tl = gsap.timeline();

const ProductPage = () => {
    // const { id } = useParams();
    // console.log(brandFetchData[id].brandfetch?.logos);
    // const currData = brandFetchData[id].brandfetch.logos;
    // const logo = currData.filter(item => item.type === 'icon');
    // console.log(logo);
    // const dispatch = useDispatch();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const width = window.innerWidth;

    const { hash } = useLocation();

    useEffect(() => {
        if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) {
                const yOffset = -140; // Adjust this value to leave 140px space at the top
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });
            }
        }
    }, [hash]);

    useLayoutEffect(() => {
        if (width < 744) {
            const ctx = gsap.context(() => {
                tl
                    .to('.nav2-mobile', {
                        opacity: 0,
                    }, "<")
                    .to('.nav2-mobile', {
                        height: '0px',
                    }, ">")
                    .to('.sidebar-bg-mask', {
                        top: "40px",
                        minHeight: '80px',
                    }, "<")
                    .set('.side-navbar', {
                        zIndex: 14
                    })
                    .to('.side-navbar', {
                        top: "50px",
                    }, "<");

                ScrollTrigger.create({
                    animation: tl,
                    trigger: '.overview-container',
                    start: 'top top',
                    end: 'top top',
                    scrub: true,
                    markers: false,
                    invalidateOnRefresh: true,
                });
            });
            return () => ctx.revert();
        }
    });


    return (
        <div className='product-page' style={{ backgroundColor: '#151515' }}>
            <SideBar />
            <div className='product-container'>
                <Overview />
                <Chart />
                <Financials />
                <Shareholding />
                <Forensic />
                <Documents />
            </div>
        </div>
    )
}

export default ProductPage;