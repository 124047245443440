import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: null,
    isAuthenticated: false,
    token: null,
    status: {
        isLoading: false,
        isError: false,
        errorMessage: null,
    },
    lastLocation: '/app',
};

const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload.user;
            state.token = action.payload.token;
            state.isAuthenticated = true;
            state.status.isLoading = false;
            state.status.isError = false;
            state.status.errorMessage = null;
        },
        setLoading: (state) => {
            state.status.isLoading = true;
            state.status.isError = false;
            state.status.errorMessage = null;
        },
        setError: (state, action) => {
            state.status.isError = true;
            state.status.errorMessage = action.payload;
            state.status.isLoading = false;
        },
        setLastLocation: (state, action) => {
            state.lastLocation = action.payload;
        },
        logout: (state) => {
            state.user = null;
            state.isAuthenticated = false;
            state.status.isLoading = false;
            state.status.isError = false;
            state.status.errorMessage = null;
        },
    }
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
