import React, { createContext, useContext, useState } from 'react';
const Context = createContext();

export const StateContext = ({ children }) => {
    const [isChecked, setChecked] = useState(false);
    const [isBannerVisible, setBannerVisible] = useState(false);
    const [isCardChecked, setCardChecked] = useState(false);
    const [isLottiePlay, setLottiePlay] = useState(false);
    const [editing, setEditing] = useState(false);
    const [selectedPattern, setSelectedPattern] = useState(['Promoters', 'FII', 'DII', 'Public']);

    return (
        <Context.Provider value={{
            isChecked, isBannerVisible, isCardChecked, isLottiePlay, setLottiePlay, setCardChecked, setChecked, setBannerVisible, editing, setEditing, selectedPattern, setSelectedPattern
        }}>
            {children}
        </Context.Provider>
    )
}

export const useStateContext = () => useContext(Context);
