import './Shareholding.css';
import { PiArrowLineLeftBold } from "react-icons/pi";
import { MdHistory } from "react-icons/md";
import { useEffect, useState } from 'react';
import CurrentShareholding from './CurrentShareholding/CurrentShareholding';
import HistoricalPattern from './HistoricalPattern';
import { useSelector } from 'react-redux';
import axios from 'axios';

const Shareholding = () => {
    const [tab, setTab] = useState('lastQuarter');
    const [qtrs, setQtrs] = useState([]);
    const [tableData, setTableData] = useState(null);
    const { stockCode, stockName } = useSelector(state => state.stock);
    const [shareholderProfile, setShareholderProfile] = useState(null);

    const formatData = (data) => {
        const quarter = data.quarterId;
        setQtrs(quarter);

        const categories = ["promoters", "fii", "dii", "retail", "government", "others"];
        const formattedData = {};

        categories.forEach(category => {
            formattedData[category] = {};

            Object.keys(data[category]).forEach(subCategory => {
                if (typeof data[category][subCategory] === 'object' && !Array.isArray(data[category][subCategory])) {
                    formattedData[category][subCategory] = {};
                    Object.keys(data[category][subCategory]).forEach(person => {
                        formattedData[category][subCategory][person] = quarter.map((qtr, i) => ({
                            [qtr.qtr]: (data[category][subCategory][person]?.length > i ? data[category][subCategory][person][i] : null)
                        }));
                    });
                } else {
                    formattedData[category][subCategory] = quarter.map((qtr, i) => ({
                        [qtr.qtr]: data[category][subCategory]?.[i] || null
                    }));
                }
            });
        });
        setTableData(formattedData);
    };

    const mergeData = (json1, json2) => {
        const qtrLength = json1.quarterId?.length || 0;
        for (let key in json2) {
            if (key === "quarterId") {
                json1.quarterId = [...(json1.quarterId || []), ...json2.quarterId];
            } else {
                json1[key] = json1[key] || {};
                for (let family in json2[key]) {
                    if (Array.isArray(json2[key][family])) {
                        json1[key][family] = [...(json1[key][family] || Array(qtrLength).fill(null)), ...json2[key][family]];
                    } else {
                        json1[key][family] = json1[key][family] || {};
                        for (let individual in json2[key][family]) {
                            json1[key][family][individual] = [...(json1[key][family][individual] || Array(qtrLength).fill(null)), ...json2[key][family][individual]];
                        }
                    }
                }
            }
        }
        return json1;
    };

    useEffect(() => {
        const getData = async () => {
            try {
                const urls = [
                    `https://api.marketrack.in/getShareHoldings?ticker=${stockName}&scripCode=${stockCode}&page=1`,
                    `https://api.marketrack.in/getShareHoldings?ticker=${stockName}&scripCode=${stockCode}&page=2`,
                    `https://api.marketrack.in/getShareHoldings?ticker=${stockName}&scripCode=${stockCode}&page=3`,
                    `https://api.marketrack.in/getShareHoldings?ticker=${stockName}&scripCode=${stockCode}&page=4`,
                    `https://api.marketrack.in/getShareHoldings?ticker=${stockName}&scripCode=${stockCode}&page=5`,
                    `https://api.marketrack.in/getShareHoldings?ticker=${stockName}&scripCode=${stockCode}&page=6`
                ];
                const responses = await Promise.all(urls.map(url => fetch(url)));
                const data = await Promise.all(responses.map(res => res.json()));

                const mergedData = data.reduce((acc, cur) => mergeData(acc, cur), {});
                // console.log(mergedData);
                formatData(mergedData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        getData();
    }, [stockCode, stockName]);

    useEffect(() => {
        axios.get("https://api.marketrack.in/assets")
            .then((response) => {
                setShareholderProfile(response.data);
            })
            .catch((error) => console.log(error));
    }, []);

    return (
        <section id='shareholding' className="shareholding-container">
            <div className="shareholding-header">
                <h1>Shareholding %</h1>
                <div className="shareholding-type">
                    <button
                        style={tab === 'lastQuarter' ? { color: '#29B6F6', backgroundColor: "#444444", WebkitTextStroke: "0.4px #29B6F6" } : {}}
                        className='last-quarter'
                        onClick={() => setTab('lastQuarter')}
                    >
                        Last Quarter <PiArrowLineLeftBold style={{ scale: '1.1' }} />
                    </button>
                    <button
                        style={tab === 'historicalPattern' ? { color: '#29B6F6', backgroundColor: "#444444", WebkitTextStroke: "0.4px #29B6F6" } : {}}
                        className='historical-pattern'
                        onClick={() => setTab('historicalPattern')}
                    >
                        Historical Pattern <MdHistory style={{ scale: '1.2' }} />
                    </button>
                </div>
            </div>
            <div style={{ width: '100%' }}>
                {tab === 'lastQuarter' && <CurrentShareholding qtrId={qtrs[0]?.qtr} data={tableData} prevQtrId={qtrs[1]?.qtr} />}
                {tab === 'historicalPattern' && <HistoricalPattern qtrs={qtrs} tableData={tableData} profilePhoto={shareholderProfile} />}
            </div>
        </section>
    );
}

export default Shareholding;
