export const filterData = (prevClose, data, item) => {
    const previousClose = prevClose[item]?.time / 1000;
    let result;
    switch (item) {
        case "1D":
        case "5D":
        case "1M":
        case "MAX":
            return data;
        case "6M":
        case "YTD":
        case "1Y":
        case "3Y":
        case "5Y":
            let low = 0;
            let high = data?.length - 1;
            while (low <= high) {
                const mid = Math.floor((low + high) / 2);
                if (data[mid].time <= previousClose) {
                    low = mid + 1;
                } else {
                    high = mid - 1;
                }
            }
            if (low < data?.length) {
                result = data.slice(low, data?.length);
            } else {
                result = [];
            }
            // console.log(result);
            break;
        default:
            return [];
    }
    return result;
}