import React from 'react'

const AboutFooter = () => {
    return (
        <div className='about-form-container'>
            <div className="about-form">
                <h1 style={{ color: '#777' }}><span className='mt-gradient'>Marketrack</span>tivity :</h1>
                <h1 className='mt-gradient'>Get active in the market with our latest news</h1>
                <p>Stay active & up-to-date on Marketrack's evolution - product updates today, market insights tomorrow</p>
                <form className='about-email'>
                    <input type="email" placeholder='Email address' />
                    <button type='submit'>Subscribe</button>
                </form>
            </div>
        </div>
    )
}

export default AboutFooter;