import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';
import { getDateForTooltip } from '../../../helpers/DateFormatter';

const ChartComponent = (props) => {
    const { data, prevClose, name, interval } = props;
    const chartContainerRef = useRef();
    const toolTip = useRef();
    const toolTipWidth = 100;
    const toolTipHeight = 100;
    const toolTipMargin = 5;

    useEffect(() => {
        const handleResize = () => {
            chart.applyOptions({ width: chartContainerRef.current.clientWidth });
        };

        const chart = createChart(chartContainerRef.current, {
            width: chartContainerRef.current.clientWidth,
            height: chartContainerRef.current.clientHeight,
            layout: {
                background: {
                    type: 'solid',
                    color: 'transparent',
                },
                textColor: '#d1d4dc',
                fontFamily: 'Inter-Light'
            },
            grid: {
                vertLines: {
                    visible: false,
                },
                horzLines: {
                    visible: false,
                },
            },
            rightPriceScale: {
                borderVisible: false,
                visible: false,
                autoScale: false,
            },
            timeScale: {
                borderVisible: false,
                visible: false,
                timeVisible: true,
            },
            localization: {
                dateFormat: '',
            },
            handleScroll: {
                mouseWheel: false,
            }
            , handleScale: {
                mouseWheel: false,
            }
        });
        chart.subscribeCrosshairMove(param => {
            if (
                param.point === undefined ||
                !param.time ||
                param.point.x < 0 ||
                param.point.x > chartContainerRef.current.clientWidth ||
                param.point.y < 0 ||
                param.point.y > chartContainerRef.current.clientHeight
            ) {
                toolTip.current.style.display = 'none';
            } else {
                // time will be in the same format that we supplied to setData.
                // thus it will be YYYY-MM-DD
                const dateStr = param.time;
                const date = new Date((dateStr - 19800) * 1000);
                toolTip.current.style.display = 'block';
                toolTip.current.style.width = '100px';
                toolTip.current.style.height = '100px';
                const data = param.seriesData.get(newSeries);
                const price = data?.value !== undefined ? data?.value : data?.close;
                toolTip.current.innerHTML = `<div style="color: ${'rgba( 38, 166, 154, 1)'}">${name}</div><div style="font-size: 24px; margin: 4px 0px; color: ${'#DDD'}">
                    ${Math.round(100 * price) / 100}
                    </div><div style="color: ${'#DDD'}">
                    ${getDateForTooltip(date)}
                    </div>`;

                const y = param.point.y;
                let left = param.point.x + toolTipMargin;
                if (left > chartContainerRef.current.clientWidth - toolTipWidth) {
                    left = param.point.x - toolTipMargin - toolTipWidth;
                }

                let top = y + toolTipMargin;
                if (top > chartContainerRef.current.clientHeight - toolTipHeight) {
                    top = y - toolTipHeight - toolTipMargin;
                }
                toolTip.current.style.left = left + 'px';
                toolTip.current.style.top = top + 'px';
            }
        });

        const newSeries = chart.addBaselineSeries({
            baseValue: { type: 'price', price: prevClose },
            topLineColor: '#87FF37',
            bottomLineColor: '#FF2E2E',
            topFillColor1: '#253324',
            topFillColor2: '#253324',
            bottomFillColor1: '#332424',
            bottomFillColor2: '#332424',
            lineWidth: 1.5,
        });

        newSeries.setData(data);
        newSeries.applyOptions({
            priceLineVisible: false,
        })

        // let avgPrice = (minPrice + maxPrice) / 2;

        // const maxPriceLine = {
        //     price: maxPrice,
        //     title: 'Max Price',
        //     color: "#87FF37",
        // }
        // const minPriceLine = {
        //     price: minPrice,
        //     title: 'Min Price',
        //     color: '#FF2E2E',
        // }
        const baseLine = {
            price: prevClose,
            title: '',
            color: '#787878',
            lineWidth: 2,
            lineStyle: 3,
        };

        // newSeries.createPriceLine(maxPriceLine);
        // newSeries.createPriceLine(minPriceLine);
        newSeries.createPriceLine(baseLine);
        let minPrice = data[0]?.value;
        let maxPrice = minPrice;
        let timeForMax = 0;
        let timeForMin = 0;
        for (let i = 1; i < data?.length; i++) {
            const price = data[i]?.value;
            if (price > maxPrice) {
                maxPrice = price;
                timeForMax = data[i]?.time;
            }
            if (price < minPrice) {
                minPrice = price;
                timeForMin = data[i]?.time;
            };
        }
        const markers = [
            {
                time: timeForMax,
                position: 'aboveBar',
                color: "#87FF37",
                shape: "arrowUp",
                text: (interval === 'MAX') ? `Max \u20B9${maxPrice}` : `${interval} High \u20B9${maxPrice}`,
                size: 0.8,
            },
            {
                time: timeForMin,
                position: 'belowBar',
                color: "#FF2E2E",
                shape: "arrowDown",
                text: (interval === 'MAX') ? `Min \u20B9${minPrice}` : `${interval} Low \u20B9${minPrice}`,
                size: 0.8
            }
        ];
        markers.sort((a, b) => a.time - b.time);

        newSeries.setMarkers(markers);

        chart.timeScale().fitContent();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            chart.remove();
        };
    });

    return (
        <>
            <div ref={chartContainerRef} style={{ position: 'relative', height: "100%" }}>
                <div className="tooltip" ref={toolTip}></div>
            </div>
        </>
    );
};

export default ChartComponent;