import './Chart.css';
import React, { useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import ChartComponent from './ChartComponent';
import { intervals } from '../../../data/data';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { findPrevClose } from '../../../helpers/PrevClose';
import { filterData } from '../../../helpers/FilterData';
import { stockActions } from '../../../redux/features/stock/stockSlice';
import { getDateForOverview } from '../../../helpers/DateFormatter';
import { BarChart } from './BarChart';
import { MdCurrencyRupee } from "react-icons/md";
import { IoTriangle } from "react-icons/io5";
import TextLoader from './../../ui/TextLoader';

const Chart = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [activeItem, setActiveItem] = useState('1D');
    const stock = useSelector(state => state.stock.stockName);
    const { stockPrice, stockCurrChange, stockCurrChangePercent } = useSelector(state => state.stock);
    const [prevClose, setPrevClose] = useState({});
    const [EODData, setEODData] = useState([]);
    const [currInterval, setCurrInterval] = useState('Today');
    const [barView, setBarView] = useState('Absolute');

    useEffect(() => {
        axios.get(`https://api.marketrack.in/getPrices?ticker=${stock}&intervals=1d,1m,30m,5m`)
            .then((response) => {
                setData(response.data);
                setEODData(response.data[stock + "_1d"]);
                // console.log(response.data);
            })
            .catch((error) => console.log(error));
    }, [stock]);


    useEffect(() => {
        if (data) {
            getPrevClose();
            getData("1m", "1D");
            setActiveItem("1D");
            const try2 = data[stock + "_1m"]?.slice(-2);
            if (try2?.length === 2) {
                const change = try2[1][5] - try2[0][5];
                const changePercent = (try2[1][5] - try2[0][5]) * 100 / try2[0][5];
                const lastDate = new Date(try2[1][0]);
                const lastUpdatedTime = getDateForOverview(lastDate);
                dispatch(stockActions.setStockDetails({ stockPrice: Number(try2[1][5]), stockChange: change, stockChangePercent: changePercent, stockLastUpdatedTime: lastUpdatedTime }));
            } else {
                console.log("Insufficient data in stock_1m to calculate change and changePercent");
            }
        }
    }, [data]);

    useEffect(() => {
        if (prevClose && Object.keys(prevClose).length > 0) {
            dispatch(stockActions.setStockCurrDetails({ stockCurrChange: stockPrice - prevClose[activeItem]?.value, stockCurrChangePercent: (stockPrice - prevClose[activeItem]?.value) / prevClose[activeItem]?.value }));
        }
    }, [activeItem, prevClose, stockPrice, dispatch]);

    const getPrevClose = () => {
        setPrevClose((prevValue) => {
            return {
                ...prevValue,
                "1D": {
                    time: new Date(data?.previousClose["1d"]?.time).getTime(),
                    value: Number(data?.previousClose["1d"]?.value)
                },
                "5D": findPrevClose(7, EODData),
                "1M": findPrevClose(30, EODData),
                "6M": findPrevClose(180, EODData),
                "YTD": {
                    time: new Date(data?.previousClose["ytd"]?.time).getTime(),
                    value: Number(data?.previousClose["ytd"]?.value)
                },
                "1Y": findPrevClose(365, EODData),
                "3Y": findPrevClose(1095, EODData),
                "5Y": findPrevClose(1825, EODData),
                "MAX": {
                    time: new Date(EODData[0][0]).getTime(),
                    value: Number(EODData[0][5])
                }
            }
        });
    }

    const getLatestValues = (dataArray) => {
        if(dataArray===null) return null;
        const latestValuesMap = new Map();

        dataArray?.forEach(item => {
            latestValuesMap.set(item.time, item.value);
        });

        return Array.from(latestValuesMap, ([time, value]) => ({ time, value }));
    };

    const getData = (interval, item) => {
        if (data && data[stock + "_" + interval]?.length > 0) {
            const jsonData = data[stock + "_" + interval];
            let newData = jsonData?.slice(1)?.map(element => ({
                time: new Date(element[0]).getTime() / 1000 + 19800,
                value: Number(element[5])
            }));
            if (newData?.length === 0)
                newData = null;
            // console.log(newData);
            const latestData = getLatestValues(newData);
            // console.log(latestData);
            const finalData = filterData(prevClose, latestData, item);
            setChartData(finalData);
        } else {
            console.log(`Data for ${stock}_${interval} is not available.`);
        }
    };

    const handleCurrInterval = (item) => {
        const intervals = {
            '1D': 'Today',
            '5D': 'Past 1W',
            '1M': 'Past 1M',
            '6M': 'Past 6M',
            'YTD': 'YTD',
            '1Y': 'Past 1Y',
            '3Y': 'Past 3Y',
            '5Y': 'Past 5Y',
            'MAX': 'Max'
        };
        setCurrInterval(intervals[item]);
    }

    const handleClick = (item) => {
        if (item === activeItem) return;
        setActiveItem(item);
        handleCurrInterval(item);
        switch (item) {
            case '1D':
                getData("1m", item);
                break;
            case '5D':
                getData('5m', item);
                break;
            case '1M':
                getData("30m", item);
                break;
            default:
                getData("1d", item);
                break;
        }
    };

    return (
        <section id='priceChart' className="chart-section">
            <div className="chart-header">
                <h1>Price Chart</h1>
                <div className='chart-header-line' />
                {
                    stockPrice && stockCurrChange && stockCurrChangePercent ?
                        <div className='chart-header-details'>
                            <p><MdCurrencyRupee /> {stockPrice}</p>
                            <p>
                                <span style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "2px" }}>
                                    (<span style={{ display: 'flex', alignItems: 'center', gap: '5px', color: "#6BFF4F", fontFamily: "InterTight-Regular" }}>
                                        {stockCurrChange?.toFixed(2)} , {stockCurrChangePercent?.toFixed(2)}% <IoTriangle color='#87FF37' />
                                    </span>)
                                </span>{currInterval}
                            </p>
                        </div>
                        :
                        <TextLoader width={'180px'} height={'20px'} />
                }
            </div>
            <div className="charts-container">
                <div className='line-price-chart'>
                    {chartData ?
                        <div className="chart">
                            <ChartComponent data={chartData} prevClose={Number(prevClose[activeItem]?.value) || 0} name={stock} interval={activeItem} />
                        </div>
                        :
                        <ContentLoader viewBox="0 0 100% 400" height={400} width={"100%"} backgroundColor='#333333' foregroundColor='#4D4D4D'>
                            <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
                        </ContentLoader>
                    }
                    <div className="switcher">
                        {intervals.map((item, index) => (
                            <button
                                key={index}
                                className={`switcher-item ${item === activeItem ? "switcher-active-item" : ''}`}
                                onClick={() => handleClick(item)}
                                style={(item === '1D') ? { borderRadius: '5px 0px 0px 5px' } : (item === "MAX") ? { borderRadius: '0px 5px 5px 0px' } : {}}
                            >
                                {item}
                            </button>
                        ))}
                    </div>
                </div>
                <div className="bar-chart">
                    <h1>Changes For the period</h1>
                    <div className="bar-btn-group">
                        <button style={(barView === 'Absolute') ? { backgroundColor: '#444', color: '#29B6F6', WebkitTextStroke: '#29B6F6 0.2px' } : {}} onClick={() => { setBarView('Absolute'); }}>Absolute View</button>
                        <button style={(barView === 'CAGR') ? { backgroundColor: '#444', color: '#29B6F6', WebkitTextStroke: '#29B6F6 0.2px' } : {}} onClick={() => { setBarView('CAGR'); }}>CAGR View</button>
                    </div>
                    <BarChart />
                    <div className="values">
                        <p className="share-price">+1.17%</p>
                        <p className="eps">+23.23%</p>
                        <p className="fcf">+3.55%</p>
                        <p className="pe-ratio">-3.87%</p>
                        <p className="opm">+1%</p>
                    </div>
                    <button className='show-detail-btn'>Show Details</button>
                </div>
            </div>
        </section>
    );
};

export default Chart;