import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import './CorporateFillings.css';
import { useStateContext } from '../../../context/StateContext';
import Lottie from "lottie-react";
import CorporateAnimation from './../../../lotties/CorporateAnimation.json';
import CorporateUI from './CorporateUI';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/all';

gsap.registerPlugin(ScrollTrigger);
const tl = gsap.timeline();

const CorporateFillings = () => {
    const { isChecked } = useStateContext();
    const [showUIElement, setShowUIElement] = useState(false);
    const corporateRef = useRef(null);

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            tl
                .to('.corporate-lottie', {
                    scale: 1.1,
                    duration: 1,
                })
                .to('#ui-element', {
                    opacity: 1,
                });

            ScrollTrigger.create({
                animation: tl,
                trigger: '.corporate',
                start: 'top 50%',
                end: 'top 50%',
                scrub: true,
                markers: false,
                invalidateOnRefresh: true,
            });
        });
        return () => ctx.revert();
    });

    useEffect(() => {
        let uiTimeout;
        if (isChecked) {
            corporateRef.current.setDirection(1);
            corporateRef.current.setSpeed(1);
            corporateRef.current.goToAndPlay(0, false);
            let duration = corporateRef.current.getDuration(false);
            uiTimeout = setTimeout(() => {
                setShowUIElement(true);
            }, (duration * 1000 + 100));
        }
        else if (isChecked === false) {
            setShowUIElement(false);
            clearTimeout(uiTimeout);
        }
        return () => {
            clearTimeout(uiTimeout);
        };
    }, [isChecked]);
    return (
        <>
            <div className='corporate hide-lottie'>
                <div className='corporate-heading'>
                    <h1 className='corporate-heading-text'>Corporate Filings</h1>
                    <img src='/assets/images/NSE.png' alt="" />
                </div>
                {!showUIElement && <div className="corporate-lottie">
                    <Lottie
                        lottieRef={corporateRef}
                        animationData={JSON.parse(JSON.stringify(CorporateAnimation))}
                        autoplay={false}
                        loop={false}
                    />
                </div>}
                <div style={{ display: showUIElement ? '' : 'none' }} id='ui-element'>
                    <CorporateUI />
                </div>
            </div>
            <div className="skeleton-text hide-everything">
                <div className="flex-col alignItems-center">
                    <span>Corporate</span>
                    <span>Filings</span>
                </div>
            </div>
        </>
    )
}

export default CorporateFillings;