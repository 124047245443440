import { useLayoutEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/all';
import { BiSolidPointer } from 'react-icons/bi';
gsap.registerPlugin(ScrollTrigger);
const tl3 = gsap.timeline();

const TypingAnimMobile = () => {
    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            tl3
                .set('.box-4', {
                    opacity: 0,
                })
                .to('.box-4', {
                    opacity: 1,
                    duration: 0.2
                })
                .to('.typewriter-1', {
                    width: '100%',
                    duration: 0.8
                }, ">")
                .set('.typing-container', {
                    flexDirection: 'column',
                })
                .to('.typewriter-2', {
                    width: '100%',
                    duration: 0.7
                }, ">")
                .to(".pointer", {
                    scale: 0.6,
                    duration: 0.3,
                }, ">")
                .to('.typewriter-1', {
                    color: '#fff',
                }, ">")
                .to('.typewriter-2', {
                    color: '#fff',
                }, "<")
                .to(".pointer", {
                    scale: 1,
                    duration: 0.3,
                }, "<")

            ScrollTrigger.create({
                id: "typing_anim_mobile",
                trigger: "#typing-trigger-mobile",
                start: "top 90%",
                markers: false,
                invalidateOnRefresh: true,
                onEnter: () => {
                    tl3.progress(0);
                    tl3.play();
                },
                onLeaveBack: () => {
                    gsap.set('.box-4', {
                        opacity: 0
                    })
                },
                onLeave: ()=>{
                    ScrollTrigger.refresh();
                }
            })
        });
        return () => ctx.revert();
    }, []);
    return (
        <div className='box-4' id='typing-trigger-mobile' style={{ alignItems: 'center' }}>
            <div className="typing-container">
                <span className='pr-20 typewriter-1'>Leave Complexity Behind</span>
                <div className='flex-row' style={{ alignItems: 'center' }}>
                    <span className='typewriter-2'>with Just One Click</span>
                    <BiSolidPointer className='pointer' />
                </div>
            </div>
        </div>
    )
}

export default TypingAnimMobile;