import React from 'react';

const ArrowSvg = ({ color, styles }) => {
    return (
        <svg style={styles} xmlns="http://www.w3.org/2000/svg" width="22.667" height="16" viewBox="0 0 22.667 16">
            <path id="Polygon_3" data-name="Polygon 3" d="M11.333,0,22.667,16H0Z" fill={color} />
        </svg>
    );
};

export default ArrowSvg;