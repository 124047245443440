import React, { useEffect, useState } from 'react';
import { HiTrendingUp } from "react-icons/hi";
import { BsTable } from "react-icons/bs";
import HistoricalGraph from './HistoricalGraph';
import QuarterSelector from './QuarterSelector';
import { CiCalendar } from "react-icons/ci";
import { IoEyeOutline } from "react-icons/io5";
import ShareholdingTable from './SharehlodingTable/ShareholdingTable';

const HistoricalPattern = ({ qtrs, tableData, profilePhoto }) => {
    const [period, setPeriod] = useState('3Y');
    const [frequency, setFrequency] = useState('quarterly');
    const [view, setView] = useState('graph');

    const [filteredPeriodQuarters, setFilteredPeriodQuarters] = useState([]);
    const [filteredFrequencyQuarters, setFilteredFrequencyQuarters] = useState([]);
    const [filteredData, setFilteredData] = useState({});

    const [startQtr, setStartQtr] = useState("June 2012");
    const [endQtr, setEndQtr] = useState("March 2024");

    useEffect(() => {
        const currentYear = new Date().getFullYear();

        const getFilteredQuarters = (years) => {
            const startYear = currentYear - years;
            return qtrs.filter(qtr => {
                const quarterYear = parseInt(qtr.yr.split(' - ')[0]);
                return quarterYear >= startYear;
            });
        };

        const getCustomFilteredQuarters = (start, end) => {
            // const [startMonth, startYear] = start.split(' ');
            // const [endMonth, endYear] = end.split(' ');
            // console.log(start);
            // console.log(end);
            const startIndex = qtrs.findIndex(qtr => qtr.qtr === start);
            const endIndex = qtrs.findIndex(qtr => qtr.qtr === end);
            // console.log(startIndex);
            // console.log(endIndex);
            if (startIndex === -1 || endIndex === -1 || startIndex < endIndex) {
                return [];
            }

            return qtrs.slice(endIndex, startIndex + 1);
        };

        let filteredQuarters = [];
        switch (period) {
            case '3Y':
                filteredQuarters = getFilteredQuarters(3);
                break;
            case '5Y':
                filteredQuarters = getFilteredQuarters(5);
                break;
            case '7Y':
                filteredQuarters = getFilteredQuarters(7);
                break;
            case 'Max':
                filteredQuarters = qtrs; // Use all quarters for 'Max' period
                break;
            case 'Custom':
                // Handle custom period logic here
                filteredQuarters = getCustomFilteredQuarters(startQtr, endQtr);
                break;
            default:
                filteredQuarters = qtrs;
                break;
        }

        setFilteredPeriodQuarters(filteredQuarters);
    }, [period, qtrs, startQtr, endQtr]);

    useEffect(() => {
        if (frequency === 'annual') {
            const annualQuarters = filteredPeriodQuarters.filter(qtr => qtr.qtr.includes('Mar'));
            setFilteredFrequencyQuarters(annualQuarters);
        } else {
            setFilteredFrequencyQuarters(filteredPeriodQuarters);
        }
    }, [frequency, filteredPeriodQuarters]);

    useEffect(() => {
        const filteredData = filterTableData(filteredFrequencyQuarters, tableData);
        setFilteredData(filteredData);
    }, [filteredFrequencyQuarters, tableData]);

    const filterTableData = (filteredQuarters, data) => {
        const filteredData = {};
        for (let key in data) {
            filteredData[key] = {};
            for (let subKey in data[key]) {
                if (typeof data[key][subKey] === 'object' && !Array.isArray(data[key][subKey])) {
                    filteredData[key][subKey] = {};
                    for (let person in data[key][subKey]) {
                        filteredData[key][subKey][person] = data[key][subKey][person].filter(dataPoint => {
                            const quarter = Object.keys(dataPoint)[0];
                            return filteredQuarters.some(qtr => qtr.qtr === quarter);
                        });
                    }
                } else {
                    filteredData[key][subKey] = data[key][subKey].filter(dataPoint => {
                        const quarter = Object.keys(dataPoint)[0];
                        return filteredQuarters.some(qtr => qtr.qtr === quarter);
                    });
                }
            }
        }
        return filteredData;
    };

    return (
        <section className='historical-container'>
            <div className="modifiers-group-box">
                <div className={`${period === 'Custom' ? 'modifiers-group-custom' : ''}  modifiers-group`}>
                    <div className='modifiers'>
                        <p>Period<CiCalendar /></p>
                        <div className="modifiers-btn-grp period">
                            <button style={(period === '3Y') ? { backgroundColor: '#444444', color: '#29B6F6', WebkitTextStroke: '0.3px #29B6F6', borderRadius: '10px 0px 0px 10px' } : { borderRadius: '10px 0px 0px 10px' }} onClick={() => { setPeriod('3Y') }}>3Y</button>
                            <button style={(period === '5Y') ? { backgroundColor: '#444444', color: '#29B6F6', WebkitTextStroke: '0.3px #29B6F6' } : {}} onClick={() => { setPeriod('5Y') }}>5Y</button>
                            <button style={(period === '7Y') ? { backgroundColor: '#444444', color: '#29B6F6', WebkitTextStroke: '0.3px #29B6F6' } : {}} onClick={() => { setPeriod('7Y') }}>7Y</button>
                            <button style={(period === 'Max') ? { backgroundColor: '#444444', color: '#29B6F6', WebkitTextStroke: '0.3px #29B6F6' } : {}} onClick={() => { setPeriod('Max') }}>Max</button>
                            <button style={(period === 'Custom') ? { backgroundColor: '#444444', color: '#29B6F6', WebkitTextStroke: '0.3px #29B6F6', borderRadius: '0px 10px 10px 0px' } : { borderRadius: '0px 10px 10px 0px' }} onClick={() => { setPeriod('Custom') }}>Custom</button>
                        </div>
                    </div>
                    <div className="modifiers">
                        <p>Frequency</p>
                        <div className="modifiers-btn-grp freq">
                            <button style={(frequency === 'quarterly') ? { backgroundColor: '#444444', color: '#29B6F6', WebkitTextStroke: '0.3px #29B6F6' } : {}} onClick={() => { setFrequency('quarterly') }}>Quarterly <img src={`/assets/svgs/Shareholding/${frequency === 'quarterly' ? "quarterly-selected.svg" : "quarterly.svg"}`} alt="quarterly" /></button>
                            <button style={(frequency === 'annual') ? { backgroundColor: '#444444', color: '#29B6F6', WebkitTextStroke: '0.3px #29B6F6' } : {}} onClick={() => { setFrequency('annual') }}>Annual <img src={`/assets/svgs/Shareholding/${frequency === 'annual' ? "annual-selected.svg" : "annual.svg"}`} alt="annual" /></button>
                        </div>
                    </div>
                    <div className="modifiers">
                        <p>View <IoEyeOutline /></p>
                        <div className="modifiers-btn-grp view">
                            <button style={(view === 'graph') ? { backgroundColor: '#444444', color: '#29B6F6', WebkitTextStroke: '0.3px #29B6F6' } : {}} onClick={() => { setView('graph') }}>Graph <HiTrendingUp style={{ scale: '1.2' }} /></button>
                            <button style={(view === 'table') ? { backgroundColor: '#444444', color: '#29B6F6', WebkitTextStroke: '0.3px #29B6F6' } : {}} onClick={() => { setView('table') }}>Table <BsTable /></button>
                        </div>
                    </div>
                </div>
                {(period === 'Custom') &&
                    <QuarterSelector setEndQtr={setEndQtr} setStartQtr={setStartQtr} />
                }
            </div>
            {view === 'graph' && <HistoricalGraph qtrs={filteredFrequencyQuarters} tableData={filteredData} />}
            {view === 'table' && <ShareholdingTable qtrs={filteredFrequencyQuarters} tableData={filteredData} profilePhoto={profilePhoto} />}
        </section>
    )
}

export default HistoricalPattern;
