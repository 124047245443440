import React from 'react';
import './Documents.css';

const Documents = () => {
    return (
        <section id='documents' className='documents-section'>
            <h1><img src="/assets/svgs/Documents/logo.svg" alt="" /> Documents</h1>
            <div>
                <div className='documents-table-container'>
                    <table className='documents-table'>
                        <thead>
                            <tr>
                                <th className='row-title' style={{
                                    backgroundColor: "#1F1F1F",
                                    border: "none",
                                    boxShadow: "none"
                                }}></th>
                                <th>2024</th>
                                <th>2023</th>
                                <th>2022</th>
                            </tr>
                            <tr>
                                <th className='row-title' style={{
                                    backgroundColor: "#1F1F1F",
                                    border: "none",
                                    boxShadow: "none"
                                }}></th>
                                <th>
                                    <div className="quarters">
                                        <span>Q1</span>
                                        <span>Q2</span>
                                        <span>Q3</span>
                                        <span>Q4</span>
                                    </div>
                                </th>
                                <th>
                                    <div className="quarters">
                                        <span>Q1</span>
                                        <span>Q2</span>
                                        <span>Q3</span>
                                        <span>Q4</span>
                                    </div>
                                </th>
                                <th>
                                    <div className="quarters">
                                        <span>Q1</span>
                                        <span>Q2</span>
                                        <span>Q3</span>
                                        <span>Q4</span>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='row-title'>Financial Results</td>
                                <td>
                                    <div className='docs-group'>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                    </div>
                                </td>
                                <td>
                                    <div className='docs-group'>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                    </div>
                                </td>
                                <td>
                                    <div className='docs-group'>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='row-title'>Earnings Call Transcript</td>
                                <td>
                                    <div className='docs-group'>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                    </div>
                                </td>
                                <td>
                                    <div className='docs-group'>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                    </div>
                                </td>
                                <td>
                                    <div className='docs-group'>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='row-title'>Press Releases</td>
                                <td>
                                    <div className='docs-group'>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                    </div>
                                </td>
                                <td>
                                    <div className='docs-group'>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                    </div>
                                </td>
                                <td>
                                    <div className='docs-group'>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                        <div className="pdf-docs"><img src="/assets/svgs/Documents/pdf.svg" alt="" /></div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='row-title'>Webcast / Audio</td>
                                <td>
                                    <div className='docs-group'>
                                        <div className="audio-docs">
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                        </div>
                                        <div className="audio-docs">
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                        </div>
                                        <div className="audio-docs">
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                        </div>
                                        <div className="audio-docs">
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className='docs-group'>
                                        <div className="audio-docs">
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                        </div>
                                        <div className="audio-docs">
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                        </div>
                                        <div className="audio-docs">
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                        </div>
                                        <div className="audio-docs">
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className='docs-group'>
                                        <div className="audio-docs">
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                        </div>
                                        <div className="audio-docs">
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                        </div>
                                        <div className="audio-docs">
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                        </div>
                                        <div className="audio-docs">
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                            <div className="audio-icon"></div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    )
}

export default Documents;