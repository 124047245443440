import React from 'react';

const Skeleton = () => {
    return (
        <div className="skeleton-block hide-everything">
            <div className='skeleton-1'></div>
            <div className='skeleton-2'></div>
        </div>
    )
}

export default Skeleton;