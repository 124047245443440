import React, { useState } from 'react';
import { IoIosArrowDown } from "react-icons/io";
import ChecklistItem from './ChecklistItem';

const Checklist = () => {
    const [open, setOpen] = useState(false);
    const handleAccOpen = () => {
        setOpen(prev => !prev);
    }
    return (
        <div className="checklist-accordion">
            <div className="checklist-label">
                <p>A. Financials & Financial Statements Checks</p>
                <p>1 Risk Found <IoIosArrowDown style={{ transform: (!open) ? '' : 'rotate(180deg)', color: "#2EA8E5", transition: "all 0.2s ease", scale: "1.3" }} /></p>
                <input type="checkbox" name="" id="" onClick={() => handleAccOpen()} />
            </div>
            <div className={`checklist-items-group ${open ? 'active' : ""}`}>
                <ChecklistItem />
                <ChecklistItem />
                <ChecklistItem />
            </div>
        </div>
    )
}

export default Checklist;