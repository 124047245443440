import React from 'react';
import Header from '../components/Header/Header';
import HeroBanner from '../components/Banner/HeroBanner';
import Features from '../components/Features/Features';
import Footer from '../components/Footer/Footer';

const LandingPage = () => {
    const windowWidth = window.innerWidth;
    return <>
        <div className='bg-gradient'>
            <Header />
            <HeroBanner />
            <Features />
            {(windowWidth <= 600) && <Footer />}
        </div>
        <div className="scroll-to-view">
            <span>Scroll Down </span>
            <svg xmlns="http://www.w3.org/2000/svg" width="13.765" height="7.871" viewBox="0 0 13.765 7.871">
                <path id="Icon_ion-ios-arrow-down" data-name="Icon ion-ios-arrow-down" d="M13.073,16.744l5.2-5.209a.98.98,0,0,1,1.389,0,.992.992,0,0,1,0,1.393l-5.9,5.9a.982.982,0,0,1-1.357.029L6.474,12.933A.984.984,0,0,1,7.864,11.54Z" transform="translate(-6.188 -11.246)" fill="#fff" />
            </svg>
        </div>
    </>
}

export default LandingPage;