import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './Marquee.css';
import Marquee from "react-fast-marquee";
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { stockActions } from '../../redux/features/stock/stockSlice';
import ArrowSvg from '../ui/ArrowSvg';
import stockDetailsData from '../../data/bse_stocks_website_urls.json';
import { getDateForMarketClose } from '../../helpers/DateFormatter';

const MarketStatus = ({ marketStatus }) => (
    <div className="market-status" style={{ color: marketStatus === 'Open' ? '#66FF00' : '#AAAAAA' }}>
        <p>{marketStatus === 'Open' ? 'MARKETS OPEN' : 'MARKETS CLOSED'}</p>
        <div style={{ display: "flex" }}>
            <img src={`/assets/svgs/signal${marketStatus === 'Open' ? 'On' : 'Off'}.svg`} alt="" />
        </div>
        <p>:</p>
    </div>
);

const StatusMessage = ({ marketStatus, occasion, lastFetched }) => {
    const formattedDate = getDateForMarketClose(new Date(lastFetched));
    const timeDiff = Date.now() - new Date(lastFetched).getTime();
    const width = window.innerWidth;

    let hours = Math.floor(timeDiff / 3600000);
    let minutes = Math.floor((timeDiff % 3600000) / 60000);
    let seconds = Math.floor((timeDiff % 60000) / 1000);

    let timeString = '';

    if (hours > 0) {
        timeString += hours + 'h ';
    }

    if (minutes > 0) {
        timeString += minutes + 'm ';
    }

    timeString += seconds + 's';
    let displayString = timeString.trim() + ' ago';

    return (
        <div className="status-message">
            {occasion && <p>Occasion : {occasion}</p>}
            {marketStatus === 'Open' ? (
                <p>Prices Last Updated : <span>{displayString}</span></p>
            ) : (
                <p>Prices as of <span>{formattedDate}</span></p>
            )}
            <div className="message-arrow" style={(width > 600) ? { left: (marketStatus === 'Open') ? '197px' : '214px' } : { left: (marketStatus === 'Open') ? '145px' : '158px' }}></div>
        </div>
    );
};

const MarqueeItem = React.memo(({ item, onClick }) => (
    <div className='marquee-item' style={{ color: item.change > 0 ? '#66FF00' : (item.change < 0 ? '#FF2E4C' : '#BBBBBB'), cursor: 'pointer' }} onClick={onClick}>
        <span>{item.name}</span>
        <span>{item.current?.value?.toFixed(2)}</span>
        <span>{((item.current?.value - item.prev?.value) / item.prev?.value * 100).toFixed(2)}%</span>
        <span className='marquee-arrow'>{((item.current?.value - item.prev?.value) / item.prev?.value * 100) >= 0 ? <ArrowSvg color={((item.current?.value - item.prev?.value) / item.prev?.value * 100) > 0 ? "#66FF00" : "#BBBBBB"} /> : <ArrowSvg color={"#FF2E4C"} styles={{ transform: "rotate(180deg)" }} />}</span>
    </div>
));

const MarqueeComponent = () => {
    const [marqueeData, setMarqueeData] = useState([]);
    const [marketStatus, setMarketStatus] = useState(null);
    const [occasion, setOccasion] = useState(null);
    const [lastFetched, setLastFetched] = useState(null);
    const [isHovered, setIsHovered] = useState(false);
    const [showMarketStatus, setShowMarketStatus] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.marketrack.in/topPrices');
                const { topPrices, status, occasion } = response.data;
                setMarqueeData(topPrices);
                setMarketStatus(status);
                setOccasion(occasion);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (marqueeData) {
            setLastFetched(marqueeData[0]?.current?.time);
        }
    }, [marqueeData]);

    useEffect(() => {
        if (window.innerWidth < 600) {
            setTimeout(() => {
                setShowMarketStatus(false);
            }, 5000);
        }
    }, []);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleClick = useCallback((item) => {
        handleShowMarketStatus(true);
        setTimeout(() => {
            handleShowMarketStatus(false);
        }, 5000);
        dispatch(stockActions.setStock(item.name));
        const stockDetails = stockDetailsData[item.name];
        dispatch(stockActions.setStock({ code: stockDetails.scrip_code, stockName: item.name, stockLogo: stockDetails.logo, stockWebsite: stockDetails.website_url }));
        navigate(`/app/stock/${item.name}`);
    }, [dispatch, navigate]);

    const handleShowMarketStatus = (value) => {
        if (window.innerWidth < 600) {
            setShowMarketStatus(value);
        }
    };

    return (
        <section className="marquee-section">
            {showMarketStatus && <div className="market-status-box" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <MarketStatus marketStatus={marketStatus} />
                {isHovered && <StatusMessage marketStatus={marketStatus} occasion={occasion} lastFetched={lastFetched} />}
                <div className="status-underline"></div>
            </div>}
            <Marquee className='marquee-container' autoFill={true} speed={100} pauseOnHover>
                {marqueeData.map((item, index) => (
                    <MarqueeItem key={index} item={item} onClick={() => handleClick(item)} />
                ))}
            </Marquee>
        </section>
    );
};

export default MarqueeComponent;
