import React, { useLayoutEffect, useRef } from 'react';
import { BiSolidPointer } from 'react-icons/bi';
import './Features.css';
import { useStateContext } from '../../context/StateContext';
import { FeaturesSection } from './FeaturesSection';
import ArrowSvg from './ArrowSvg';
import ToggleButton from './ToggleButton';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/all';
import FeaturesSectionMobile from './FeaturesSectionMobile';
import TypingAnimMobile from './TypingAnimMobile';
import MarketrackLogo from './../ui/MarketrackLogo';

gsap.registerPlugin(ScrollTrigger);
const tl2 = gsap.timeline();

const Features = () => {
    const windowWidth = window.innerWidth;
    const { isBannerVisible, setLottiePlay } = useStateContext();
    const tl = useRef();
    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            tl.current = gsap.timeline({
                scrollTrigger: {
                    id: "line_anim",
                    trigger: ".features-body",
                    scrub: true,
                    start: "5% 90%",
                    end: "start 50%",
                    endTrigger: '.box3',
                    markers: false,
                    invalidateOnRefresh: true,
                    onEnter: () => {
                        gsap.set('.box-4', {
                            opacity: 0,
                        });
                    },
                    onLeave: () => {
                        setLottiePlay(false);
                    },
                    onLeaveBack: () => {
                        setLottiePlay(true);
                    }
                }
            })
                .to(".box1", {
                    opacity: 1,
                    ease: 'power2.in',
                })
                .to(".vertical-line", {
                    height: (windowWidth <= 600) ? '13.33vw' : "9.3vw",
                    ease: 'power1.in'
                })
                .to(".box2", {
                    opacity: 1,
                    ease: 'power1.in',
                })
                .to('.box3', {
                    opacity: 1,
                    ease: 'power2.in',
                })
        });
        return () => ctx.revert();
    }, [windowWidth, setLottiePlay]);

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            tl2
                .set('.box-4', {
                    opacity: 0,
                })
                .to('.box-4', {
                    opacity: 1,
                    duration: 0.3
                })
                .to('.typewriter', {
                    width: '100%',
                    duration: 1.5
                }, ">")
                .to(".pointer", {
                    scale: 0.6,
                    duration: 0.3,
                }, ">")
                .to('.typewriter', {
                    color: '#fff',
                }, ">")
                .to(".pointer", {
                    scale: 1,
                    duration: 0.3,
                }, "<")

            ScrollTrigger.create({
                id: "typing_anim",
                trigger: "#typing-trigger",
                start: "top 90%",
                markers: false,
                invalidateOnRefresh: true,
                onEnter: () => {
                    tl2.progress(0);
                    tl2.play();
                },
                onLeaveBack: () => {
                    gsap.set('.box-4', {
                        opacity: 0
                    })
                }
            })
        });
        return () => ctx.revert();
    }, []);



    return (
        <div className={`gradient-background ${isBannerVisible ? 'fade-in' : ''}`}>
            <div className='features-section'>
                <div className='fade_rule'></div>
                <div className='features-section-body'>
                    <div className='features-body'>
                        <div className="with-marketrack box1">
                            With <MarketrackLogo width={(windowWidth > 600) ? "23vw" : "50vw"} />
                        </div>
                        {(windowWidth > 600) && <div className='box-4 features-body-typing' id='typing-trigger'>
                            <span className='pr-20 typewriter'>Leave Complexity Behind with Just One Click</span>
                            <BiSolidPointer className='pointer' />
                        </div>}
                        {(windowWidth <= 600) && <TypingAnimMobile />}
                        <div className='vertical-line'></div>
                        <div className='get-a-feel-container box2'>
                            <div className='flex-row'>
                                <p className='feel-text'>Get a Feel. Try it Out Now</p>
                                <ArrowSvg />
                            </div>
                            <div className='marketrack-button'>
                                <MarketrackLogo width={(windowWidth > 600) ? "20vw" : "46vw"} />
                                <ToggleButton />
                            </div>
                        </div>
                    </div>
                    <div className="box3">
                        {(windowWidth <= 600) && <FeaturesSectionMobile />}
                        {(windowWidth > 600) && <FeaturesSection />}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Features;
