import React from 'react';
import { IoMdAdd } from "react-icons/io";

const EmptyField = ({ setEditing }) => {
  return (
    <div className='field-btn-empty' onClick={() => setEditing(true)}>
      <span>Add Ratio</span>
      <IoMdAdd />
    </div>
  )
}

export default EmptyField;