import React, { useLayoutEffect, useState } from 'react';
import './Forensic.css';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/all';
import { SiBookstack } from "react-icons/si";
import { IoSearch } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";
import { useStateContext } from '../../../context/StateContext';


gsap.registerPlugin(ScrollTrigger);
const tl = gsap.timeline();

const Forensic = () => {
    const { isChecked } = useStateContext();
    const [activeItem, setActiveItem] = useState(0);
    const handleToggle = (index) => {
        const element = document.querySelectorAll('.checks-title input');
        setActiveItem((prev) => (prev === index ? 0 : index));

        element.forEach((item, i) => {
            if (i === index - 1) {
                item.checked = true;
            } else {
                item.checked = false;
            }
        });
    };

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            tl.to('.lens', {
                opacity: 1,
            })
            .to('.reports', {
                opacity: 1,
            })
            .to('.coffee', {
                opacity: 1,
            })
            .to('.calculator', {
                opacity: 1,
            })
            .to('.timer', {
                opacity: 1,
            })
            .to('.starter', {
                opacity: 1,
            })
            .to('.do-it-yourself', {
                opacity: 1,
            });

            ScrollTrigger.create({
                trigger: '.forensic',
                animation: tl,
                start: 'top 50%',
                end: 'top 50%',
                scrub: true,
                markers: false,
                invalidateOnRefresh: true,
                duration: 3,
            });
        });
        return () => ctx.revert();
    }, []);

    return (
        <>
            <div className="forensic hide-lottie">
                <div className="forensic-heading">
                    <span>Forensic Scrutiny</span>
                </div>
                {!isChecked && <>
                    <div className="lens">
                        <IoSearch size={'2.65vw'} style={{ marginBottom: '0.7vw' }} />
                        <p>Lens</p>
                        <span>for closer inspection</span>
                    </div>
                    <div className="reports">
                        <SiBookstack size={'3.15vw'} />
                        <p style={{ marginTop: '1.04vw' }}>Bunch of Annual &</p>
                        <p>Quarterly Reports</p>
                    </div>
                    <div className="coffee">
                        <img src="/assets/images/forensic/Coffee.png" alt="" />
                        <p>Expresso</p>
                        <span>to stay awake</span>
                    </div>
                    <div className="starter">
                        <p>Starter Pack</p>
                    </div>
                    <div className="calculator">
                        <img src="/assets/images/forensic/calculator.png" alt="" />
                        <p>Calculator</p>
                        <span>for serious number</span>
                        <span>crunching</span>
                    </div>
                    <div className="timer">
                        <img src="/assets/images/forensic/timer.png" alt="" />
                        <p>Timer</p>
                        <span>to set aside hours</span>
                    </div>
                    <div className='do-it-yourself'>
                        <p>The <span className='do-it-yourself-text'>Do it Yourself</span> Way</p>
                    </div>
                </>}
                {isChecked && <div className="forensic-checks">
                    <div className="points">
                        <span className='points-text'>5 Points</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="2.14vw" height="2.14vw" viewBox="0 0 41.09 41">
                            <g id="Radar" transform="translate(-2177.91 -3032.5)">
                                <path id="Radar-2" data-name="Radar" d="M51.235,13.981,49.253,12,30.627,30.627a1.4,1.4,0,1,0,1.982,1.982l5.857-5.857a8.4,8.4,0,1,1-6.848-3.541v-2.8a11.225,11.225,0,1,0,8.85,4.341l3.989-3.989A16.815,16.815,0,1,1,31.617,14.8V12A19.617,19.617,0,1,0,51.235,31.617a19.417,19.417,0,0,0-4.8-12.841Z" transform="translate(2165.91 3020.5)" fill="#45b057" />
                                <circle id="_5" data-name="5" cx="3.5" cy="3.5" r="3.5" transform="translate(2184 3033.5)" fill="#ffa2a2" />
                                <circle id="_4" data-name="4" cx="3.5" cy="3.5" r="3.5" transform="translate(2194 3057.5)" fill="#ffa2a2" />
                                <circle id="_3" data-name="3" cx="3.5" cy="3.5" r="3.5" transform="translate(2185 3044.5)" fill="#ffa2a2" />
                                <circle id="_2" data-name="2" cx="3.5" cy="3.5" r="3.5" transform="translate(2194 3066.5)" fill="#ffa2a2" />
                                <circle id="_1" data-name="1" cx="3.5" cy="3.5" r="3.5" transform="translate(2212 3047.5)" fill="#ffa2a2" />
                            </g>
                        </svg>
                    </div>
                    <div className="checks-header">
                        <div className="vert-line"></div>
                        <div>
                            <h1 className="checks-heading">Skeptic&apos;s Radar</h1>
                            <div className="checks-desc">
                                <span className="checks-count">Total Checks Run : 25</span>
                                <div className='checks-desc-line'></div>
                                <span className="show-details">Show Details <IoIosArrowForward /></span>
                            </div>
                        </div>
                    </div>
                    <div className="forensic-check" key={1}>
                        <div className="checks-title">
                            <input type="checkbox" id='1' onChange={() => { handleToggle(1) }} />
                            <label htmlFor="1">1.  Frequent Auditor Changes</label>
                        </div>
                        <div className={`fc-desc ${activeItem === 1 ? 'fc-active' : 'fc-inactive'}`}>
                            <div className="fc-desc-line"></div>
                            <div className="fc-desc-box">
                                <span className='fc-desc-content'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod massa mauris, nec interdum risus tempor at. Nulla facilisi    . Sed pulvinar, sem at aliquet ultrices, ex metus luctus sem, vitae molestie lorem neque et tortor    . Donec id turpis vitae ipsum volutpat pretium. Sed in nunc non erat posuere facilisis. (60 Words)</span>
                                <div className='fc-desc-sources'>
                                    <span>View Sources</span>
                                    <div className='fc-desc-sources-line'></div>
                                    <span>What does this mean?</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="forensic-check" key={2}>
                        <div className="checks-title">
                            <input type="checkbox" id='2' onChange={() => { handleToggle(2) }} />
                            <label htmlFor="2">2.  Changes in Accounting Policy</label>
                        </div>
                        <div className={`fc-desc ${activeItem === 2 ? 'fc-active' : 'fc-inactive'}`}>
                            <div className="fc-desc-line"></div>
                            <div className="fc-desc-box">
                                <span className='fc-desc-content'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod massa mauris, nec interdum risus tempor at. Nulla facilisi    . Sed pulvinar, sem at aliquet ultrices, ex metus luctus sem, vitae molestie lorem neque et tortor    . Donec id turpis vitae ipsum volutpat pretium. Sed in nunc non erat posuere facilisis. (60 Words)</span>
                                <div className='fc-desc-sources'>
                                    <span>View Sources</span>
                                    <div className='fc-desc-sources-line'></div>
                                    <span>What does this mean?</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="forensic-check" key={3}>
                        <div className="checks-title">
                            <input type="checkbox" id='3' onChange={() => { handleToggle(3) }} />
                            <label htmlFor="3">3.  Unusual Revenue Recognition</label>
                        </div>
                        <div className={`fc-desc ${activeItem === 3 ? 'fc-active' : 'fc-inactive'}`}>
                            <div className="fc-desc-line"></div>
                            <div className="fc-desc-box">
                                <span className='fc-desc-content'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod massa mauris, nec interdum risus tempor at. Nulla facilisi    . Sed pulvinar, sem at aliquet ultrices, ex metus luctus sem, vitae molestie lorem neque et tortor    . Donec id turpis vitae ipsum volutpat pretium. Sed in nunc non erat posuere facilisis. (60 Words)</span>
                                <div className='fc-desc-sources'>
                                    <span>View Sources</span>
                                    <div className='fc-desc-sources-line'></div>
                                    <span>What does this mean?</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="forensic-check" key={4}>
                        <div className="checks-title">
                            <input type="checkbox" id='4' onChange={() => { handleToggle(4) }} />
                            <label htmlFor="4">4.  High Debt to Equity Ratio</label>
                        </div>
                        <div className={`fc-desc ${activeItem === 4 ? 'fc-active' : 'fc-inactive'}`}>
                            <div className="fc-desc-line"></div>
                            <div className="fc-desc-box">
                                <span className='fc-desc-content'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod massa mauris, nec interdum risus tempor at. Nulla facilisi    . Sed pulvinar, sem at aliquet ultrices, ex metus luctus sem, vitae molestie lorem neque et tortor    . Donec id turpis vitae ipsum volutpat pretium. Sed in nunc non erat posuere facilisis. (60 Words)</span>
                                <div className='fc-desc-sources'>
                                    <span>View Sources</span>
                                    <div className='fc-desc-sources-line'></div>
                                    <span>What does this mean?</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="forensic-check" key={5}>
                        <div className="checks-title">
                            <input type="checkbox" id='5' onChange={() => { handleToggle(5) }} />
                            <label htmlFor="5">5.  Delayed Financial Reporting</label>
                        </div>
                        <div className={`fc-desc ${activeItem === 5 ? 'fc-active' : 'fc-inactive'}`}>
                            <div className="fc-desc-line"></div>
                            <div className="fc-desc-box">
                                <span className='fc-desc-content'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod massa mauris, nec interdum risus tempor at. Nulla facilisi    . Sed pulvinar, sem at aliquet ultrices, ex metus luctus sem, vitae molestie lorem neque et tortor    . Donec id turpis vitae ipsum volutpat pretium. Sed in nunc non erat posuere facilisis. (60 Words)</span>
                                <div className='fc-desc-sources'>
                                    <span>View Sources</span>
                                    <div className='fc-desc-sources-line'></div>
                                    <span>What does this mean?</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
            <div className="skeleton-text hide-everything">
                <div className="flex-col alignItems-center">
                    <span>Forensic</span>
                    <span>Scrutiny</span>
                </div>
            </div>
        </>
    )
}

export default Forensic;