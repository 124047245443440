import React from 'react';
import './Footer.css';
import { Link, useLocation } from 'react-router-dom';
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { IoLogoDiscord } from "react-icons/io5";
import { PiTrademarkRegistered } from "react-icons/pi";
import { Tooltip } from 'react-tooltip';

const Footer = () => {
    const windowWidth = window.innerWidth;
    const location = useLocation();
    const path = location.pathname;
    return (<>
        {(windowWidth > 744) && <section className='footer-section' style={{ position: (path === '/') ? 'absolute' : 'relative' }}>
            <div className="footer-container">
                <div className="footer-about">
                    <img src="/assets/svgs/MarketrackHomeLogo.svg" alt="" />
                    <p className="footer-about-para">Marketrack aims to develop an investment copilot with cutting-edge technologies to address the challenges of limited time, tools and financial complexity for retail investors, thus democratizing & leveling the playing field with institutions.</p>
                    <p className="footer-trademark">Marketrack<sup><PiTrademarkRegistered /></sup> is a registered trademark of Dellarex Technologies Pvt Ltd in India. All Rights Reserved.</p>
                </div>
                <div className="quicklinks">
                    <h3>Quick Links</h3>
                    <div className='quicklinks-links'>
                        <Link to={'/about'} className="footer-link">About</Link>
                        <Link to={'/about'} className="footer-link">Blog</Link>
                        <Link to={'/team'} className="footer-link">Team</Link>
                        <Link to={'#'} className="footer-link">Careers</Link>
                        <Link to={'#'} className="footer-link">Contact</Link>
                    </div>
                </div>
                <div className="quicklinks">
                    <h3>Policies</h3>
                    <div className='quicklinks-links'>
                        <Link to={'#'} className="footer-link">Privacy Policy</Link>
                        <Link to={'#'} className="footer-link">Terms of Use</Link>
                        <Link to={'#'} className="footer-link">Cookie Policy</Link>
                    </div>
                </div>
                <div className="social-links-box">
                    <div className='social-links-heading'>
                        <div className="horz-line" />
                        <h2>Stay in touch</h2>
                        <div className="horz-line" />
                    </div>
                    <div className="social-links">
                        <a style={{ color: '#CCCCCC' }} href={'https://twitter.com/Marketrack_2047'} target='_blank' rel='noreferrer noopener' data-tooltip-id='social-link-tooltip' data-tooltip-content='X' data-tooltip-place="bottom"><FaTwitter /></a>
                        <a style={{ color: '#CCCCCC' }} href={'https://www.instagram.com/marketrack.in/'} target='_blank' rel='noreferrer noopener' data-tooltip-id='social-link-tooltip' data-tooltip-content='Instagram' data-tooltip-place="bottom"><FaInstagram /></a>
                        <a style={{ color: '#CCCCCC' }} href={'https://www.facebook.com/profile.php?id=61555364248436'} target='_blank' rel='noreferrer noopener' data-tooltip-id='social-link-tooltip' data-tooltip-content='Facebook' data-tooltip-place="bottom"><FaFacebookF /></a>
                        <a style={{ color: '#CCCCCC' }} href={'https://www.youtube.com/channel/UCba3FbtgUHv_diqqJ--sZHA'} target='_blank' rel='noreferrer noopener' data-tooltip-id='social-link-tooltip' data-tooltip-content='Youtube' data-tooltip-place="bottom"><FaYoutube /></a>
                        <a style={{ color: '#CCCCCC' }} href={'/'} target='_blank' rel='noreferrer noopener' data-tooltip-id='social-link-tooltip' data-tooltip-content='LinkedIn' data-tooltip-place="bottom"><FaLinkedin /></a>
                        <a style={{ color: '#CCCCCC' }} href={'http://Discordapp.com/users/1199561766837948476'} target='_blank' rel='noreferrer noopener' data-tooltip-id='social-link-tooltip' data-tooltip-content='Discord' data-tooltip-place="bottom"><IoLogoDiscord /></a>
                        <Tooltip id="social-link-tooltip" />
                    </div>
                </div>
            </div>
        </section>}
        {(windowWidth <= 744) && <section className='footer-section'>
            <div className="footer-container">
                <div className="footer-about">
                    <img src="/assets/svgs/MarketrackHomeLogo.svg" alt="" />
                    <p className="footer-about-para">Marketrack aims to develop an investment copilot with cutting-edge technologies to address the challenges of limited time, tools and financial complexity for retail investors, thus democratizing & leveling the playing field with institutions.</p>
                    <p className="footer-trademark">Marketrack<sup><PiTrademarkRegistered /></sup> is a registered trademark of Dellarex Technologies Pvt Ltd in India. All Rights Reserved.</p>
                </div>
                <div className="social-links-box">
                    <div className='social-links-heading'>
                        <div className="horz-line" />
                        <h2>Stay in touch</h2>
                        <div className="horz-line" />
                    </div>
                    <div className="social-links">
                        <a style={{ color: '#CCCCCC' }} href={'https://twitter.com/Marketrack_2047'} target='_blank' rel='noreferrer noopener' data-tooltip-id='social-link-tooltip' data-tooltip-content='X' data-tooltip-place="bottom"><FaTwitter /></a>
                        <a style={{ color: '#CCCCCC' }} href={'https://www.instagram.com/marketrack.in/'} target='_blank' rel='noreferrer noopener' data-tooltip-id='social-link-tooltip' data-tooltip-content='Instagram' data-tooltip-place="bottom"><FaInstagram /></a>
                        <a style={{ color: '#CCCCCC' }} href={'https://www.facebook.com/profile.php?id=61555364248436'} target='_blank' rel='noreferrer noopener' data-tooltip-id='social-link-tooltip' data-tooltip-content='Facebook' data-tooltip-place="bottom"><FaFacebookF /></a>
                        <a style={{ color: '#CCCCCC' }} href={'https://www.youtube.com/channel/UCba3FbtgUHv_diqqJ--sZHA'} target='_blank' rel='noreferrer noopener' data-tooltip-id='social-link-tooltip' data-tooltip-content='Youtube' data-tooltip-place="bottom"><FaYoutube /></a>
                        <a style={{ color: '#CCCCCC' }} href={'/'} target='_blank' rel='noreferrer noopener' data-tooltip-id='social-link-tooltip' data-tooltip-content='LinkedIn' data-tooltip-place="bottom"><FaLinkedin /></a>
                        <a style={{ color: '#CCCCCC' }} href={'http://Discordapp.com/users/1199561766837948476'} target='_blank' rel='noreferrer noopener' data-tooltip-id='social-link-tooltip' data-tooltip-content='Discord' data-tooltip-place="bottom"><IoLogoDiscord /></a>
                        <Tooltip id="social-link-tooltip" />
                    </div>
                </div>
                <div className="quicklinks">
                    <div className='social-links-heading'>
                        <div className="horz-line" />
                        <h2>Quick Links</h2>
                        <div className="horz-line" />
                    </div>
                    <div className='quicklinks-links'>
                        <Link to={'/'} className="footer-link">Product</Link>
                        <Link to={'/about'} className="footer-link">About</Link>
                        <Link to={'/about'} className="footer-link">Blog</Link>
                        <Link to={'#'} className="footer-link">Pricing</Link>
                    </div>
                </div>
                <div className="quicklinks">
                    <div className='social-links-heading'>
                        <div className="horz-line" />
                        <h2>Reach Out</h2>
                        <div className="horz-line" />
                    </div>
                    <div className='quicklinks-links'>
                        <Link to={'#'} className="footer-link">Careers</Link>
                        <Link to={'/team'} className="footer-link">Team</Link>
                        <Link to={'#'} className="footer-link">Contact</Link>
                    </div>
                </div>
                <div className="quicklinks">
                    <div className='social-links-heading'>
                        <div className="horz-line" />
                        <h2>Policies</h2>
                        <div className="horz-line" />
                    </div>
                    <div className='quicklinks-links'>
                        <Link to={'#'} className="footer-link">Privacy Policy</Link>
                        <Link to={'#'} className="footer-link">Terms of Use</Link>
                        <Link to={'#'} className="footer-link">Cookie Policy</Link>
                    </div>
                </div>
            </div>
        </section>}
    </>
    )
}

export default Footer;