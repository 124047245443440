import React, { useState } from 'react';
import './CurrentShareholding.css';
import ShareholdingCard from './ShareholdingCard';
// import { Checkbox, FormControlLabel } from '@mui/material';

const CurrentShareholding = ({ qtrId, data, prevQtrId }) => {
    const [expanded, setExpanded] = useState('');
    const [showChanges, setshowChanges] = useState(false);
    const handleExpand = (item) => {
        if (expanded === item) setExpanded('');
        else setExpanded(item);
    };

    const percent = (data, item) => {
        if (data && data[item] && Object.keys(data[item]).length !== 0 && data[item].sum && data[item].sum[0] && data[item].sum[0][qtrId]) {
            return data[item]?.sum[0]?.[qtrId]?.[1];
        } else {
            return 0;
        }
    };

    const changePercent = (data, item) => {
        if (data && Object.keys(data[item]).length !== 0 && data[item]?.sum[0][qtrId] && data[item]?.sum[1][prevQtrId]) {
            return (data[item]?.sum[0][qtrId][1] - data[item]?.sum[1][prevQtrId][1]);
        }
        else
            return 0;
    };

    return (
        <section className='curr-shareholding-container'>
            <h1>For the Quarter Ended on <span style={{ fontFamily: "Inter-Medium" }}>{qtrId}</span></h1>
            <div className="curr-shareholding-list">
                <ul>
                    <li>
                        <ShareholdingCard
                            title={'Promoter'}
                            expanded={expanded}
                            handleExpand={handleExpand}
                            percent={percent(data, "promoters")}
                            changePercent={changePercent(data, "promoters")}
                            showChanges={showChanges}
                        />
                    </li>
                    <li>
                        <ShareholdingCard
                            title={'Foreign Institutions'}
                            expanded={expanded}
                            handleExpand={handleExpand}
                            percent={percent(data, "fii")}
                            changePercent={changePercent(data, "fii")}
                            showChanges={showChanges}
                        />
                    </li>
                    <li>
                        <ShareholdingCard
                            title={'Domestic Institutions'}
                            expanded={expanded}
                            handleExpand={handleExpand}
                            percent={percent(data, "dii")}
                            changePercent={changePercent(data, "dii")}
                            showChanges={showChanges}
                        />
                    </li>
                    <li>
                        <ShareholdingCard
                            title={'Retail Individuals'}
                            expanded={expanded}
                            handleExpand={handleExpand}
                            percent={percent(data, "retail")}
                            changePercent={changePercent(data, "retail")}
                            showChanges={showChanges}
                        />
                    </li>
                    <li>
                        <ShareholdingCard
                            title={'Others'}
                            expanded={expanded}
                            handleExpand={handleExpand}
                            percent={percent(data, "others")}
                            changePercent={changePercent(data, "others")}
                            showChanges={showChanges}
                        />
                    </li>
                    <li>
                        <ShareholdingCard
                            title={'Government'}
                            expanded={expanded}
                            handleExpand={handleExpand}
                            percent={percent(data, "government")}
                            changePercent={changePercent(data, "government")}
                            showChanges={showChanges}
                        />
                    </li>
                </ul>
            </div>

            <div className='custom-check' style={{ '--color': '#29B6F6' }}>
                <input id='show-changes' className="checkbox-custom" name="checkbox-1" type="checkbox" onChange={() => setshowChanges(prev => !prev)} value={showChanges} />
                <label htmlFor='show-changes' className="checkbox-custom-label" style={{ color: "#29B6F6", fontFamily: "Inter-Regular" }}>Changes in Last Quarter</label>
            </div>
        </section>
    );
}

export default CurrentShareholding;