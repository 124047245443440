import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { FcGoogle } from "react-icons/fc";
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../redux/features/auth/authSlice';
import { userLogin } from '../redux/features/auth/authService';
import { toast } from 'react-toastify';

const Login = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    const width = window.innerWidth;

    const [formData, setFormData] = useState({
        email: "",
        password: '',
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const lastLocation = useSelector(state => state.auth.lastLocation);
    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            dispatch(authActions.setLoading());
            dispatch(userLogin(codeResponse.access_token));
            navigate(lastLocation);
            toast.success('Logged in successfully');
        },
        onError: (error) => {
            dispatch(authActions.setError(error));
            toast.error("Login Failed");
        }
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.id]: e.target.value });
    }
    const handleFormSubmit = () => {

    }

    return (
        <div className='register-page'>
            <div className='register-container'>
                <p className='register-heading'>Welcome Back!</p>
                <form onSubmit={handleFormSubmit} className="register-form">
                    <div className="mobile-input">
                        <label htmlFor='email'>Email</label>
                        <input id='email' type="email" autoComplete="true" required onChange={handleChange} value={formData.email} />
                    </div>
                    <div className="mobile-input">
                        <label htmlFor='password'>Password</label>
                        <input id='password' type="password" required onChange={handleChange} value={formData.password} />
                    </div>
                    <button type="submit" className='generate-otp-button'>Login</button>
                </form>
                <div className='or-login-with'>
                    <hr />
                    <span>Or</span>
                    <hr />
                </div>
                <div className='auth-buttons-group'>
                    <button className='continue-google-button social-auth' onClick={login}>
                        <FcGoogle size={'20px'} />Continue with Google
                    </button>
                </div>
                <div className='have-account' style={width > 600 ? { fontSize: '17.5px' } : {}}>
                    <span style={{ opacity: '70%' }}>New user?</span>
                    <Link to={'/register'} style={{ textDecoration: 'none', fontFamily: 'Montserrat-Medium', color: '#29B6F6' }}>
                        <span>Register Here</span>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Login;
