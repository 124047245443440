import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(({ hovered, expanded, ...other }) => (
    <LinearProgress {...other} />
))(({ theme, hovered, expanded }) => ({
    height: "10px",
    width: "100%",
    borderRadius: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: "#333333",
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 10,
        backgroundColor: (hovered && !expanded) ? '#29B6F6' : "#999999",
    },
}));


const ShareholdingCard = ({ title, expanded, handleExpand, percent, changePercent, showChanges }) => {
    const [hovered, setHovered] = useState(false);

    return (
        <div className={`shareholding-card ${expanded === title ? 'expanded' : ''}`} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
            <input type="checkbox" name="" id="" onChange={() => handleExpand(title)} />
            <h2 style={hovered && !expanded ? { color: '#29B6F6' } : {}}>{title}</h2>
            <div className='progress-container'>
                <span style={hovered && !expanded ? { color: '#29B6F6' } : {}}>{percent?.toFixed(2)}%</span>
                {showChanges && <>
                    <hr />
                    <span className='last-qtr-changes' style={{ color: (changePercent > 0) ? "#6BFF4F" : (changePercent === 0) ? "" : "#FF4F4F" }}>{changePercent > 0 && "+"}{changePercent?.toFixed(2)}%</span>
                </>}
            </div>
            <BorderLinearProgress variant="determinate" value={percent} expanded={expanded} hovered={hovered} />
            <div className={`share ${expanded === title ? "share-expand" : ""}`} >
                <div className='card'>
                    <img src='/assets/images/concall-images/SalilParekh.png' alt="" />
                    <div style={{ display: "flex", flexDirection: 'column', alignItems: "flex-start" }}>
                        <span style={{ fontFamily: "InterTight-Medium" }}>Name</span>
                        <span style={{ fontFamily: "InterTight-Regular" }}>2.02%</span>
                    </div>
                </div>
                <div className='card'>
                    <img src='/assets/images/concall-images/SalilParekh.png' alt="" />
                    <div style={{ display: "flex", flexDirection: 'column', alignItems: "flex-start" }}>
                        <span style={{ fontFamily: "InterTight-Medium" }}>Name</span>
                        <span style={{ fontFamily: "InterTight-Regular" }}>2.02%</span>
                    </div>
                </div>
                <div className='card'>
                    <img src='/assets/images/concall-images/SalilParekh.png' alt="" />
                    <div style={{ display: "flex", flexDirection: 'column', alignItems: "flex-start" }}>
                        <span style={{ fontFamily: "InterTight-Medium" }}>Name</span>
                        <span style={{ fontFamily: "InterTight-Regular" }}>2.02%</span>
                    </div>
                </div>
                <div className='card'>
                    <img src='/assets/images/concall-images/SalilParekh.png' alt="" />
                    <div style={{ display: "flex", flexDirection: 'column', alignItems: "flex-start" }}>
                        <span style={{ fontFamily: "InterTight-Medium" }}>Name</span>
                        <span style={{ fontFamily: "InterTight-Regular" }}>2.02%</span>
                    </div>
                </div>
                <div className='card'>
                    <img src='/assets/images/concall-images/SalilParekh.png' alt="" />
                    <div style={{ display: "flex", flexDirection: 'column', alignItems: "flex-start" }}>
                        <span style={{ fontFamily: "InterTight-Medium" }}>Name</span>
                        <span style={{ fontFamily: "InterTight-Regular" }}>2.02%</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShareholdingCard;