import { authActions } from "./authSlice";
import axios from "axios";


export const userLogin = (accessToken) => {
    return async (dispatch) => {
        axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json'
            }
        })
            .then((res) => {
                dispatch(authActions.setUser({ user: res.data, token: accessToken }));
            })
            .catch((err) => {
                dispatch(authActions.setError(err.message));
            });
    }
};