import React from 'react';


const Field = ({ value }) => {
    return (
        <div className='field-btn'>
            <span>{value.name}</span>
            <span>&#8377; {value.data}</span>
        </div>
    );
};

export default Field;