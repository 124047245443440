import React, { useState, useRef, useEffect } from 'react';
import { IoIosArrowDown } from "react-icons/io";
import { toast } from 'react-toastify';
import { GoDotFill } from "react-icons/go";

const QuarterSelector = ({ setStartQtr, setEndQtr }) => {
    const currMonth = new Date().getMonth();
    const currYear = new Date().getFullYear();
    const [showCalendar, setShowCalendar] = useState(false);
    const [startQuarter, setStartQuarter] = useState({ month: 'Jun', year: 2012 });
    const [endQuarter, setEndQuarter] = useState({ month: 'Mar', year: currYear });

    const years = Array.from({ length: 13 }, (_, index) => 2012 + index);
    const months = ["Mar", "Jun", "Sep", "Dec"];

    const r = {
        "Mar": 3,
        "Jun": 6,
        "Sep": 9,
        "Dec": 12
    };

    const rep = {
        "Mar": "March",
        "Jun": "June",
        "Sep": "September",
        "Dec": "December"
    };

    const startYearRef = useRef(null);
    const endYearRef = useRef(null);

    const [isStartAtTop, setIsStartAtTop] = useState(true);
    const [isStartAtBottom, setIsStartAtBottom] = useState(false);
    const [isEndAtTop, setIsEndAtTop] = useState(true);
    const [isEndAtBottom, setIsEndAtBottom] = useState(false);

    const scrollYear = (direction, ref, setIsAtTop, setIsAtBottom) => {
        if (ref.current) {
            const currentScroll = ref.current.scrollTop;
            const height = ref.current.clientHeight;
            const scrollAmount = direction === 'up' ? -height : height;
            ref.current.scrollTo({
                top: currentScroll + scrollAmount,
                behavior: 'smooth'
            });

            // Update scroll position state after the scroll is done
            setTimeout(() => {
                const newScroll = ref.current.scrollTop;
                setIsAtTop(newScroll === 0);
                setIsAtBottom(newScroll + height + 1 >= ref.current.scrollHeight);
            }, 100);
        }
    };

    useEffect(() => {
        setIsStartAtTop(true);
        setIsEndAtTop(true);

        const handleScroll = () => {
            if (startYearRef.current) {
                const height = startYearRef.current.clientHeight;
                setIsStartAtTop(startYearRef.current.scrollTop === 0);
                setIsStartAtBottom(startYearRef.current.scrollTop + height + 1 >= startYearRef.current.scrollHeight);
            }
            if (endYearRef.current) {
                const height = endYearRef.current.clientHeight;
                setIsEndAtTop(endYearRef.current.scrollTop === 0);
                setIsEndAtBottom(endYearRef.current.scrollTop + height + 1 >= endYearRef.current.scrollHeight);
            }
        }

        const startRef = startYearRef.current;
        const endRef = endYearRef.current;

        startRef?.addEventListener("scroll", handleScroll);
        endRef?.addEventListener("scroll", handleScroll);

        return () => {
            startRef?.removeEventListener("scroll", handleScroll);
            endRef?.removeEventListener("scroll", handleScroll);
        };
    }, [showCalendar]);

    const handleApply = () => {
        if (startQuarter.year > endQuarter.year) {
            toast.error("Invalid selection");
        }
        else if (startQuarter.year === endQuarter.year && r[startQuarter.month] >= r[endQuarter.month]) {
            toast.error("Invalid selection");
        }
        else {
            setShowCalendar(false);
            setStartQtr(`${rep[startQuarter.month]} ${startQuarter.year}`);
            setEndQtr(`${rep[endQuarter.month]} ${endQuarter.year}`);
        }
    };

    return (
        <div style={{ position: 'relative' }}>
            <div className='calendar-button' onClick={() => setShowCalendar(true)}>
                <p>{`${startQuarter.month}, ${startQuarter.year}`}</p>
                <svg viewBox="0 0 42 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M41.3628 5.44194C41.6069 5.19786 41.6069 4.80214 41.3628 4.55806L37.3854 0.580583C37.1413 0.336505 36.7456 0.336505 36.5015 0.580583C36.2574 0.82466 36.2574 1.22039 36.5015 1.46447L40.037 5L36.5015 8.53553C36.2574 8.77961 36.2574 9.17534 36.5015 9.41942C36.7456 9.6635 37.1413 9.6635 37.3854 9.41942L41.3628 5.44194ZM0.920898 5.625H40.9209V4.375H0.920898V5.625Z" fill="#AAAAAA" />
                </svg>
                <p>{`${endQuarter.month}, ${endQuarter.year}`}</p>
                <IoIosArrowDown color='#29B6F6' style={{ scale: '1.8' }} />
            </div>
            {showCalendar && (
                <div className="calendar-select">
                    <div className="calendar-selected">
                        <div className='selected-quarter'>
                            <p>Quarter Ended</p>
                            <p>{`${startQuarter.month}, ${startQuarter.year}`}</p>
                        </div>
                        <svg viewBox="0 0 42 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M41.3628 5.44194C41.6069 5.19786 41.6069 4.80214 41.3628 4.55806L37.3854 0.580583C37.1413 0.336505 36.7456 0.336505 36.5015 0.580583C36.2574 0.82466 36.2574 1.22039 36.5015 1.46447L40.037 5L36.5015 8.53553C36.2574 8.77961 36.2574 9.17534 36.5015 9.41942C36.7456 9.6635 37.1413 9.6635 37.3854 9.41942L41.3628 5.44194ZM0.920898 5.625H40.9209V4.375H0.920898V5.625Z" fill="#AAAAAA" />
                        </svg>
                        <div className='selected-quarter'>
                            <p>Quarter Ended</p>
                            <p>{`${endQuarter.month}, ${endQuarter.year}`}</p>
                        </div>
                    </div>
                    <div className="quarter-select-container">
                        {['Start', 'End'].map((label, index) => (
                            <div className="quarter-selector" key={index}>
                                <div className="quarter-month">
                                    {months.map((month, i) => {
                                        const isStart = label === 'Start';
                                        const isEnd = label === 'End';
                                        const isCurrentMonth = (startQuarter.month === month && isStart) || (endQuarter.month === month && isEnd);
                                        const canRenderEndMonth = isEnd && (endQuarter.year < currYear || (endQuarter.year === currYear && r[month] < currMonth));
                                        const canRenderStartMonth = isStart && (startQuarter.year < currYear || (startQuarter.year === currYear && r[month] < currMonth));

                                        if (canRenderStartMonth || canRenderEndMonth) {
                                            return (
                                                <span
                                                    key={i}
                                                    style={{ color: isCurrentMonth ? '#29B6F6' : '' }}
                                                    onClick={() => {
                                                        if (isStart) {
                                                            setStartQuarter(prevState => ({ ...prevState, month }));
                                                        } else {
                                                            setEndQuarter(prevState => ({ ...prevState, month }));
                                                        }
                                                    }}
                                                >
                                                    {month}
                                                    {(isCurrentMonth) && <GoDotFill color='#29B6F6' />}
                                                </span>
                                            );
                                        }
                                        return null;
                                    })}
                                </div>
                                <div className="year-box">
                                    <IoIosArrowDown
                                        color='#29B6F6'
                                        style={{ scale: '1.3', transform: 'rotate(180deg)', cursor: 'pointer', opacity: (!isStartAtTop && label === 'Start') ? 1 : 0, display: label === 'Start' ? "block" : "none" }}
                                        onClick={() => scrollYear('up', startYearRef, setIsStartAtTop, setIsStartAtBottom)}
                                    />
                                    <IoIosArrowDown
                                        color='#29B6F6'
                                        style={{ scale: '1.3', transform: 'rotate(180deg)', cursor: 'pointer', opacity: (!isEndAtTop && label === 'End') ? 1 : 0, display: label === 'End' ? "block" : "none" }}
                                        onClick={() => scrollYear('up', endYearRef, setIsEndAtTop, setIsEndAtBottom)}
                                    />
                                    <div className="year-range" ref={label === 'Start' ? startYearRef : endYearRef}>
                                        {years.map((year, i) => (
                                            <span
                                                style={{ color: (startQuarter.year === year && label === 'Start') || (endQuarter.year === year && label === 'End') ? '#29B6F6' : '', cursor: 'pointer' }}
                                                key={i}
                                                onClick={() => {
                                                    if (label === 'Start') {
                                                        setStartQuarter(prevState => ({ ...prevState, year }));
                                                    } else {
                                                        setEndQuarter(prevState => ({ ...prevState, year }));
                                                    }
                                                }}
                                            >
                                                {year}
                                            </span>
                                        ))}
                                    </div>
                                    <IoIosArrowDown
                                        color='#29B6F6'
                                        style={{ scale: '1.3', cursor: 'pointer', opacity: (!isStartAtBottom && label === 'Start') ? 1 : 0, display: label === 'Start' ? "block" : "none" }}
                                        onClick={() => scrollYear('down', startYearRef, setIsStartAtTop, setIsStartAtBottom)}
                                    />
                                    <IoIosArrowDown
                                        color='#29B6F6'
                                        style={{ scale: '1.3', cursor: 'pointer', opacity: (!isEndAtBottom && label === 'End') ? 1 : 0, display: label === 'End' ? "block" : "none" }}
                                        onClick={() => scrollYear('down', endYearRef, setIsEndAtTop, setIsEndAtBottom)}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                    <button className='apply-button' onClick={handleApply}>Apply</button>
                </div>
            )}
        </div>
    );
};

export default QuarterSelector;