import React from 'react';
import './IncomeGraph.css';
import { IoIosArrowForward } from "react-icons/io";

const IncomeGraph = () => {
    return (
        <div className='income-statement-item'>
            <div className="income-statement-item-header">
                <h1>Financials <IoIosArrowForward /></h1>
                <button>Simplify View</button>
            </div>
            <div className="income-chart-grp">
                <div className="income-chart">
                    <div className="income-chart-header">
                        <h2>Total Income</h2>
                        <p>(in Crores.)</p>
                    </div>
                    <div className="income-bar-chart">
                        <div className="bar">
                            <span>39.6k</span>
                            <div></div>
                            <span>Sep 23</span>
                        </div>
                        <div className="bar">
                            <span>39.6k</span>
                            <div></div>
                            <span>Sep 23</span>
                        </div>
                        <div className="bar">
                            <span>39.6k</span>
                            <div></div>
                            <span>Sep 23</span>
                        </div>
                    </div>
                </div>
                <div className='separator' />
                <div className="income-chart">
                    <div className="income-chart-header">
                        <h2>Total Income</h2>
                        <p>(in Crores.)</p>
                    </div>
                    <div className="income-bar-chart">
                        <div className="bar">
                            <span>39.6k</span>
                            <div></div>
                            <span>Sep 23</span>
                        </div>
                        <div className="bar">
                            <span>39.6k</span>
                            <div></div>
                            <span>Sep 23</span>
                        </div>
                        <div className="bar">
                            <span>39.6k</span>
                            <div></div>
                            <span>Sep 23</span>
                        </div>
                    </div>
                </div>
                <div className='separator' />
                <div className="income-chart">
                    <div className="income-chart-header">
                        <h2>Total Income</h2>
                        <p>(in Crores.)</p>
                    </div>
                    <div className="income-bar-chart">
                        <div className="bar">
                            <span>39.6k</span>
                            <div></div>
                            <span>Sep 23</span>
                        </div>
                        <div className="bar">
                            <span>39.6k</span>
                            <div></div>
                            <span>Sep 23</span>
                        </div>
                        <div className="bar">
                            <span>39.6k</span>
                            <div></div>
                            <span>Sep 23</span>
                        </div>
                    </div>
                </div>
                <div className='separator' />
                <div className="income-chart">
                    <div className="income-chart-header">
                        <h2>Total Income</h2>
                        <p>(in Crores.)</p>
                    </div>
                    <div className="income-bar-chart">
                        <div className="bar">
                            <span>39.6k</span>
                            <div></div>
                            <span>Sep 23</span>
                        </div>
                        <div className="bar">
                            <span>39.6k</span>
                            <div></div>
                            <span>Sep 23</span>
                        </div>
                        <div className="bar">
                            <span>39.6k</span>
                            <div></div>
                            <span>Sep 23</span>
                        </div>
                    </div>
                </div>
                <div className='separator' />
                <div className="income-chart">
                    <div className="income-chart-header">
                        <h2>Total Income</h2>
                        <p>(in Crores.)</p>
                    </div>
                    <div className="income-bar-chart">
                        <div className="bar">
                            <span>39.6k</span>
                            <div></div>
                            <span>Sep 23</span>
                        </div>
                        <div className="bar">
                            <span>39.6k</span>
                            <div></div>
                            <span>Sep 23</span>
                        </div>
                        <div className="bar">
                            <span>39.6k</span>
                            <div></div>
                            <span>Sep 23</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IncomeGraph;