function makeSmallGrids(n, m, x, y) {
    const smallGrids = [];
    for (let i = 0; i < n; i += x) {
        for (let j = 0; j < m; j += y) {
            const smallGrid = [];
            for (let a = i; a < i + x; a++) {
                const row = [];
                for (let b = j; b < j + y; b++) {
                    row.push([a, b]);
                }
                smallGrid.push(row);
            }
            smallGrids.push(smallGrid);
        }
    }
    return smallGrids;
}

function generateRandNumber(x, deviation) {
    return Math.max(1, Math.round(x + (Math.random() - 0.5) * 2 * deviation));
}

function shuffleArray(array) {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
}

export default function offsetGen(gRows, gCols, lRow, lCol, avgCellOn, deviation, probDead) {
    if (gRows % lRow !== 0 || gCols % lCol !== 0) {
        return -1; // error
    }
    const smallGrids = makeSmallGrids(gRows, gCols, lRow, lCol);
    const offsets = Array.from({ length: gRows }, () => Array(gCols).fill(-2));

    for (const smallGrid of smallGrids) {
        const sample = [];
        for (let i = 0; i < lCol * lRow; i++) {
            for (let j = 0; j < generateRandNumber(avgCellOn, deviation); j++) {
                sample.push(i);
            }
        }
        const shuffled = shuffleArray(sample);
        const slicedArray = shuffled.slice(0, lCol * lRow);
        let index = 0;
        for (const row of smallGrid) {
            for (const coordinates of row) {
                const [x, y] = coordinates;
                offsets[x][y] = slicedArray[index];
                index++;
            }
        }
    }
    for (let i = 0; i < gRows; i++) {
        for (let j = 0; j < gCols; j++) {
            if ((i + j) % 2 === 0 && offsets[i][j] % 2 !== 0) {
                if (Math.random() < 0.5) {
                    offsets[i][j]++;
                }
                else offsets[i][j]--;
            }
            else if ((i + j) % 2 !== 0 && offsets[i][j] % 2 === 0) {
                if (Math.random() < 0.5 || offsets[i][j] === 0) {
                    offsets[i][j]++;
                }
                else offsets[i][j]--;
            }
            if (offsets[i][j] === (lRow * lCol)) offsets[i][j] -= 2;
        }
    }

    for (let i = 0; i < gRows; i++) {
        for (let j = 0; j < gCols; j++) {
            if (Math.random() < probDead) {
                offsets[i][j] = -1;
            }
        }
    }
    for (let i = 0; i < gRows - 1; i++) {
        for (let j = 0; j < gCols; j++) {
            if (offsets[i][j] === -1) continue;
            if (j === 0 && (offsets[i][j] === offsets[i + 1][j + 1])) {
                offsets[i + 1][j + 1] = -1;
            }
            else if (j === (gCols - 1) && (offsets[i][j] === offsets[i + 1][j - 1])) {
                offsets[i + 1][j - 1] = -1;
            }
            else {
                if ((offsets[i][j] === offsets[i + 1][j + 1]) && (offsets[i][j] === offsets[i + 1][j - 1])) {
                    offsets[i][j] = -1;
                }
                else if (offsets[i][j] === offsets[i + 1][j + 1]) {
                    offsets[i + 1][j + 1] = -1;
                }
                else if (offsets[i][j] === offsets[i + 1][j - 1]) {
                    offsets[i + 1][j - 1] = -1;
                }
            }
        }
    }
    return offsets;
}