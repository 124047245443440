import React, { useEffect, useState } from 'react';
import Field from './Field';
import { useSelector } from 'react-redux';
import EmptyField from './EmptyField';

const OverviewSection = ({ rows, setEditing }) => {
    const savedFields = useSelector(state => state.stock.savedFields);
    // const totalCount = rows * 3; // 1 row has 3 fields
    // const rem = totalCount - savedFields?.length - 4;

    const [rem, setRem] = useState(0);
    useEffect(() => {
        let r = (savedFields.length + 4) % 3;
        if (r) setRem(3 - r);
        else setRem(0);
    }, [savedFields]);

    // Create an array with rem elements
    const emptyFieldsArray = new Array(rem).fill(null);

    return (
        <div className="parameters-section" style={{ '--r': rows }}>
            <div className='field-btn'>
                <span>Market Cap</span>
                <span>&#8377; 668,036 Cr.</span>
            </div>
            <div className='field-btn'>
                <span>Sector</span>
                <span>IT Services</span>
            </div>
            <div className='field-btn'>
                <span>Market Cap</span>
                <span>&#8377; 668,036 Cr.</span>
            </div>
            <div className='field-btn'>
                <span>MD & CEO</span>
                <span>Salil Parekh</span>
            </div>
            {savedFields?.map((item, index) => {
                return (
                    <Field
                        value={item}
                        index={index}
                        key={index}
                    />
                );
            })}
            {/* Render EmptyField components */}
            {emptyFieldsArray?.map((_, index) => (
                <EmptyField key={index} setEditing={setEditing} />
            ))}
        </div>
    );
};

export default OverviewSection;