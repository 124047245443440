import React, { useState } from 'react'
import CheckboxComponent from '../../ui/CheckboxComponent';
import { patterns } from '../../../data/data';
import IOSToggleButton from '../../ui/IOSToggleButton';
import ShareholdingChart from './ShareholdingChart';

const HistoricalGraph = ({ qtrs, tableData }) => {
    const [dataLabel, setDataLabel] = useState(false);
    const handleToggleDataLabel = () => {
        setDataLabel(prevValue => !prevValue);
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', gap: '2vw' }}>
            <div className="chart-options">
                <div className='chart-option-selectors'>
                    {patterns.map((pattern) => {
                        return <CheckboxComponent key={pattern.name} pattern={pattern} />
                    })}
                </div>
                <div style={{ alignSelf: 'flex-end' }}>
                    <div className="data-labels" >
                        <p>Data Labels</p>
                        <IOSToggleButton handleToggleDataLabel={handleToggleDataLabel} />
                    </div>
                </div>
            </div>
            <div className="line-chart-container">
                <div className='line-chart'>
                    <ShareholdingChart dataLabel={dataLabel} qtrs={qtrs} data={tableData} />
                </div>
            </div>
        </div>
    )
}

export default HistoricalGraph;