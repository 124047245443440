import React from 'react'
import BlogCard from './BlogCard';

const AboutContainer = () => {
    return (
        <div className='blog-group-conatiner'>
            <h1>Blog</h1>
            <div className="blog-card-grps">
                <div className="announcement-grp">
                    <p>Announcement</p>
                    <BlogCard index={1} />
                    <button className="view-all">View all</button>
                </div>
            </div>
        </div>
    )
}

export default AboutContainer;