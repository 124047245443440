import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { watchlistActions } from '../../../redux/features/watchlist/watchlistSlice';
import './SideBar.css';
import StockBar from './StockBar';
import { IoSettingsOutline } from "react-icons/io5";
import { MdIosShare } from "react-icons/md";
import { MdPlaylistAdd } from "react-icons/md";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { HiOutlineViewGrid } from "react-icons/hi";
import { MdShowChart } from "react-icons/md";
import { MdCurrencyRupee } from "react-icons/md";
import { IoSearch } from "react-icons/io5";
import { IoDocumentOutline } from "react-icons/io5";
import { FaPercent } from "react-icons/fa6";
import { FaRegNewspaper } from "react-icons/fa";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import { IoIosArrowDown } from "react-icons/io";
import RemoveWatchlist from '../../ui/RemoveWatchlist';
import { Link } from 'react-router-dom';


const SideBar = () => {
    const device = (window.innerWidth > 1194) ? "pc" : (window.innerWidth < 744) ? 'mobile' : 'tablet';

    const dispatch = useDispatch();
    const stock = useSelector(state => state.stock.stockName);
    const watchlist = useSelector(state => state.watchlist.stocks);
    const [addToWatchlist, setAddToWatchlist] = useState(true);
    const [financial, setFinancial] = useState(false);
    const [shareholding, setShareholding] = useState(false);

    const [selected, setSelected] = useState("overview");

    const handleAddToWatchlist = () => {
        setAddToWatchlist((prevValue) => !prevValue);
        if (addToWatchlist) {
            dispatch(watchlistActions.addToWatchlist({ nseCode: stock }));
            toast.dismiss();
            toast.success(`${stock} added to watchlist`);
        }
        else {
            dispatch(watchlistActions.removeFromWatchlist({ nseCode: stock }));
            toast.dismiss();
            toast.error(`${stock} removed to watchlist`);
        }
    };

    useEffect(() => {
        if (watchlist[stock])
            setAddToWatchlist(true);
    }, []);

    return (
        <>
            <div className="sidebar-bg-mask"></div>
            <div className="side-navbar">
                <div className="sidenav-header">
                    <StockBar />
                    <div className="header-options">
                        <button>
                            <IoSettingsOutline />
                            <span>Settings</span>
                        </button>
                        {/* <button>
                            <MdOutlineNotificationsActive />
                            <span>Notify</span>
                        </button>
                        <button>
                            <MdIosShare />
                            <span>Share</span>
                        </button> */}
                        <button onClick={handleAddToWatchlist}>
                            {addToWatchlist ? <MdPlaylistAdd style={{ scale: '1.2' }} /> : <RemoveWatchlist />}
                            <span>Watchlist</span>
                        </button>
                    </div>
                </div>
                <hr />
                <div className="sidenav-list">
                    <Link to="#overview" className={`sidenav-item ${selected === "overview" ? "active" : ""}`} onClick={() => setSelected("overview")}>
                        <div className="vert-line"></div>
                        <HiOutlineViewGrid />
                        <span>Overview</span>
                    </Link>
                    <Link to="#priceChart" className={`sidenav-item ${selected === "chart" ? "active" : ""}`} onClick={() => setSelected("chart")}>
                        <MdShowChart />
                        <span>{device === "tablet" ? "Chart" : "Price Chart"}</span>
                    </Link>
                    <Link to="#financial" className={`sidenav-item ${selected === "financial" ? "active" : ""}`} onClick={() => { setFinancial(prev => !prev); setSelected("financial"); }}>
                        <MdCurrencyRupee />
                        <span>{device === "tablet" ? "Finances" : "Financial Statements"}</span>
                        <IoIosArrowDown style={{ transition: 'all 0.2s ease', transform: financial ? 'rotate(180deg)' : 'rotate(0deg)', display: (device === "pc") ? 'block' : 'none' }} />

                    </Link>
                    <div className={`sidenav-item-expand ${financial ? "expand" : ""}`}>
                        <ul>
                            <li>Income Statement</li>
                            <li>Balance Sheet</li>
                            <li>Cash Flow Statement</li>
                        </ul>
                    </div>
                    <Link to="#shareholding" className={`sidenav-item ${selected === "shareholding" ? "active" : ""}`} onClick={() => { setShareholding(prev => !prev); setSelected("shareholding"); }}>
                        <FaPercent />
                        <span>{device === "tablet" ? "Shares" : "Shareholding"}</span>
                        <IoIosArrowDown style={{ transition: 'all 0.2s ease', transform: shareholding ? 'rotate(180deg)' : 'rotate(0deg)', display: (device === "pc") ? 'block' : 'none' }} />
                    </Link>
                    <div className={`sidenav-item-expand ${shareholding ? "expand" : ""}`}>
                        <ul>
                            <li>Last Quarter</li>
                            <li>Historical Pattern</li>
                        </ul>
                    </div>
                    <Link to={"#skeptic"} className={`sidenav-item ${selected === "forensic" ? "active" : ""}`} onClick={() => setSelected("forensic")}>
                        <IoSearch />
                        <span>{device === "tablet" ? "Forensics" : "Skeptic's Radar"}</span>
                        <div className='most-used'>
                            <p>Most Used</p>
                        </div>
                    </Link>
                    <Link to={"#documents"} className={`sidenav-item ${selected === "document" ? "active" : ""}`} onClick={() => setSelected("document")}>
                        <IoDocumentOutline />
                        <span>{device === "tablet" ? "Docs" : "Documents"}</span>
                    </Link>
                    <Link className={`sidenav-item ${selected === "news" ? "active" : ""}`} onClick={() => setSelected("news")}>
                        <FaRegNewspaper />
                        <span>News</span>
                    </Link>
                    <Link className={`sidenav-item ${selected === "about" ? "active" : ""}`} onClick={() => setSelected("about")}>
                        <HiOutlineBuildingOffice />
                        <span>{device === "tablet" ? "About" : "About Company"}</span>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default SideBar;